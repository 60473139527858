import React, { Component } from 'react';
import {BrowserRouter,Route,Switch} from 'react-router-dom';
import Enterprise from './pages/EnterpriseLogin';
import UserLogin from './pages/UserLogin';
import Splash from'./pages/SplashScreen';
import AreaAllocation from './pages/AreaAllocation';
import UnitAssign from './pages/UnitAssign';
import FetchSteps from './pages/FetchSteps';
import Logout from './pages/Logout'
import DashBoard from './pages/DashBoard';
import MyAccount from './pages/MyAccount';
import AboutUs from './pages/AboutUs';
import Privacy from './pages/Privacy';
import ChangePassword from './pages/ChangePassword';
import ForGotPassword from './pages/ForGotPassword';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import Notification from './pages/Notification';
import AssessorDashBoard from './pages/AssessorDashboard';
import ApproverDashBoard from './pages/ApproverDashboard';
import ActiveTab from './pages/ActiveTab';
import InProgressTab from './pages/InProgressTab';
import AchivedTab from './pages/AchivedTab';
import Achived from './pages/Achived';
import UpcomingTab from './pages/UpcomingTab';
import InprogressResponse from './pages/InprogressResponse';
import AssessorFetchSteps from './pages/AssessorFetchSteps';
import ApproverFetchSteps from './pages/ApproverFetchSteps';
import UpcomingResponse from './pages/UpcomingResponse';
import EHSDashBoard from './pages/EHSDashboard';
import EHSFetchsteps from './pages/EHSFetchsteps';
import FormFetchSteps from './pages/FormFetchSteps';
import RejectTab from './pages/RejectTab';
import RejectFetchSteps from './pages/RejectFetchSteps';
import RejectFetchamsDRI from './pages/RejectFetchamsDRI';
import SecurityDashboard from './pages/SecurityDashboard';
import LivePermit from './pages/LivePermit';
import DraftTab from './pages/DraftTab';
import DraftFetchSteps from './pages/DraftFetchSteps';
import MainDashboard from './pages/MainDashboard';
import PDFViewer from './pages/PDFViewers';
import Clone from './pages/Clone';
import ReturnToAMS from './pages/ReturnToAMS';
import CreateAccount from './pages/CreateAccount';
import DRIRegister from './pages/DRIRegister';
class App extends Component {
    render() {
        return ( 
         <BrowserRouter>
          <div className="App">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Switch>
          <Route path="/viewer" component={PDFViewer}/>
          <Route path="/" exact component={Enterprise} />
          <Route path="/desktop" component={MainDashboard}/>
          <Route path="/login"  component={UserLogin}/>
          <Route path="/create" component={CreateAccount}/>
          <Route path="/dri_manager_register" component={DRIRegister}/>
          <Route path="/splash"  component={Splash}/>
          <Route path="/permit"  component={AreaAllocation}/>
          <Route path="/returntoams/:id" component={ReturnToAMS}/>
          <Route path="/seclive"  component={LivePermit}/>
          <Route path="/reject"  component={RejectTab}/>
          <Route path="/clone/:id"  component={Clone}/>
          <Route path="/rejectfetch/:id"  component={RejectFetchSteps}/>
          <Route path="/draftfetch/:id"  component={DraftFetchSteps}/>
          <Route path="/rejectamsfetch/:id"  component={RejectFetchamsDRI}/>
          <Route path="/unit"  component={UnitAssign}/>
          <Route path="/units/:id"  component={FetchSteps}/>
          <Route path="/logout"  component={Logout}/>
          <Route path="/dashboard"  component={DashBoard}/>
          <Route path="/draft"  component={DraftTab}/>
          <Route path="/security"  component={SecurityDashboard}/>
          <Route path="/assessor"  component={AssessorDashBoard}/>
          <Route path="/ehs"  component={EHSDashBoard}/>
          <Route path="/approver"  component={ApproverDashBoard}/>
          <Route path="/myaccount"  component={MyAccount}/>
          <Route path="/about"  component={AboutUs}/>
          <Route path="/privacy"  component={Privacy}/>
          <Route path="/changepassword"  component={ChangePassword}/>
          <Route path="/forgotpassword"  component={ForGotPassword}/>
          <Route path="/live" component={ActiveTab}/>
          <Route path="/inprogress" component={InProgressTab}/>
          <Route path="/achived" component={AchivedTab}/>
          <Route path="/upcoming" component={UpcomingTab}/>
          <Route path="/achivedsession/:id" component={Achived}/>
          <Route path="/notification"  component={Notification}/>
          <Route path="/inprogressresponse/:id" component={InprogressResponse}/>
          <Route path="/upcomingresponse/:id" component={UpcomingResponse}/>
          <Route path="/assessorfetch/:id" component ={AssessorFetchSteps}/>
          <Route path="/approverfetch/:id" component ={ApproverFetchSteps}/>
          <Route path="/ehsfetch/:id" component ={EHSFetchsteps}/>
          <Route path="/form/:id" component ={FormFetchSteps}/>
          </Switch>
          </MuiPickersUtilsProvider>
          </div>
          </BrowserRouter>
        );
    }
}

export default App;