import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from "react-sidebar";
import '../css/App.css';


class Sidenav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            style: "menu",
            menuStatus: "open",
            status: false,
            sidebarOpen: false

        };
        this.handleClick = this.handleClick.bind(this);
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    }
    onSetSidebarOpen(open) {
        this.setState({ sidebarOpen: open });
    }
    handleClick() {
        switch (this.state.menuStatus) {
            case "open":
                this.setState({
                    menuStatus: "close",
                    style: "menu active",
                    status: true
                });
                break;
            case "close":
                this.setState({
                    menuStatus: "open",
                    style: "menu",
                    status: false
                });
                break;
            default:
                break;
        }
    }
    render() {
        console.log(this.props)
        const navLinks = [
            // { url: '/myaccount', name: 'My Account' },
            { url: '/permit', name: 'High Risk Activity Permit Form' },
            { url: '/changepassword', name: 'Change Password' },
            // { url: '/privacy', name: 'Privacy Policy' },
            // { url: '/about', name: 'About Us' },
            { url: '/logout', name: 'Logout' },

        ];
        if (this.props.type === "main") {
            return (
                <div>
                    <Sidebar
                        sidebar={<ul>
                            <li><Link to={'/changepassword'}>{'Change Password'}</Link></li>
                           {localStorage.getItem('type') === '3' ?
                           <>
                                <li>
                                    <Link to={'/permit'}>{'High Risk Activity Permit Form'}</Link>
                                </li>
                                <li>
                                    <a href={require('../images/ams OSRAM ELECTRONIC PERMIT TO WORK SYSTEM - User Guide for Contractor DRI.pdf')} download>User Guide</a>
                                </li>
                                </>
                            :''}
                            {this.props.push.location.pathname==='/approver' ?
                         <li>
                         <a href={require('../images/ams OSRAM ELECTRONIC PERMIT TO WORK SYSTEM - User Guide for DRI Manager.pdf')} download>User Guide</a>
                     </li>:''    
                        }
                          {this.props.push.location.pathname==='/assessor' ?
                         <li>
                         <a href={require('../images/ams OSRAM ELECTRONIC PERMIT TO WORK SYSTEM - User Guide for ams DRI.pdf')} download>User Guide</a>
                     </li>:''    
                        }
                        {this.props.push.location.pathname==='/ehs' ?
                         <li>
                         <a href={require('../images/ams OSRAM ELECTRONIC PERMIT TO WORK SYSTEM - User Guide for EHS.pdf')} download>User Guide</a>
                     </li>:''    
                        }
                                <li>
                                    <Link to={'/logout'}>{'Logout'}</Link>
                                </li>
                            
                        </ul>}
                        open={this.state.sidebarOpen}
                        onSetOpen={this.onSetSidebarOpen}
                        sidebarClassName={'menu'}
                        styles={{ sidebar: { background: "#000" } }}
                    />


                    <nav className="navbar navbar-top navbar-expand navbar-dark  border-bottom">
                        <div className="container pad_z">
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">


                                <div className="col-3 pad_z ">



                                    <i className="material-icons" onClick={() => this.onSetSidebarOpen(true)} >menu</i>

                                </div>
                                <div className="col-6 text-center pad_z"> <img src={require('../images/logo_new.png')} alt="az_logo" className="responsive-img" style={{ height: 50,maxWidth:'100%' }} />
                                <p style={{fontSize:12,margin:0}}>Electronic Permit To Work System</p>
                                </div>
                                <div className="col-3 text-right  vertical-align justify-content-end pad_z"><i onClick={() => this.props.push.push('/notification')} class="material-icons">notifications_none</i></div>

                            </div>
                        </div>
                    </nav>
                </div>

            );
        }
        else if (this.props.type === "second") {
            return (
                <nav className="navbar navbar-top navbar-expand navbar-dark  border-bottom">
                    <div className="container pad_z">
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <div className="col-3 pad_z ">
                              {this.props.url === 'back' ? <i onClick={() => this.props.Push.goBack()} className="material-icons back-btn grey-text">arrow_back</i> : <Link to={this.props.url}><i className="material-icons back-btn grey-text">arrow_back</i></Link>}
                               
                            </div>
                            <div className="col-6 text-center"> <img src={require('../images/logo_new.png')} alt="az_logo" className="responsive-img" style={{ height: 50 }} /></div>
                            <div className="col-3 text-right  vertical-align justify-content-end pad_z"></div>

                        </div>
                    </div>
                </nav>
            );
        }
    }
}

export default Sidenav;