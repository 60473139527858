import React, { Component } from 'react';
import '../css/App.css';
import FormInput from '../component/FormInput';
import axios from 'axios';
import API from '../config_api'

import Button from '../component/Button';

import Loader from '../component/Loader';
class CreateAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loader:false
        };
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    componentWillMount() {
        this.setState({
            loader:true
        })
        let formData = new FormData();
        formData.append('company_id', '388')
        axios({
            method: 'post',
            url: 'https://eptw.acuizen.com/api/ptw_getcontractor',
            data: formData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            mode: 'no-cors',
        })
            .then(res => {
                // console.log(res.data.data)

                this.setState({
                    data:res.data.data,
                    loader:false
                })
               
            });

        // let formData = new FormData();
        // formData.append('company_id', 'ams')

        // axios({
        //     method: 'post',
        //     url: API.Enterprise,
        //     data: formData,
        //     headers: {
        //         'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        //     },
        //     mode: 'no-cors',
        // })
        //     .then(res => {
        //         console.log(res)
        //         if (res.data.status === 'success') {

        //             localStorage.setItem('logo', res.data.data.logo);
        //             localStorage.setItem('company_id', 'ams');

        //         }
        //         else {
        //             alert(res.data.message)
        //         }
        //     });
    }
    getRandomInt =(max)=> {
        return Math.floor(Math.random() * max);
      }
    handleSubmit(event) {
        const company = event.target[0].value;
        const first = event.target[1].value;
        const last = event.target[2].value;
       
        const email = event.target[3].value;
        const username = this.getRandomInt(100000);
         console.log(company)
        event.preventDefault();
        if (company === '') {
            alert('Please enter Contractor Name')
        } else if(email == '') { 
            alert('Please enter Email ')
        } else if(first ===''){
            alert('Please enter First Name')
        }else if(last === ''){
            alert('Please enter Last Name')
        }else if(username ===''){
            alert('Please enter User Name')
        }else{
        let formData = new FormData();
        formData.append('company_id', '388')
        formData.append('email', email)
        formData.append('contractor', company)
        formData.append('firstname', first)
        formData.append('lastname', last)
        formData.append('username', username)


        axios({
            method: 'post',
            url: 'https://eptw.acuizen.com/api/ptw_addcontractor',
            data: formData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            mode: 'no-cors',
        })
            .then(res => {
               if(res.data.status === 'success'){
               alert("Your submission is received. We will notify you via email when your account is created");
               this.props.history.push('/')
               }else{
                alert(res.data.message);
               }
               
            });
        }
    }
    // getBack() {
    //     localStorage.removeItem("company_id");
    //     window.location.reload();

    // }
    // getForget() {
    //     this.props.history.push('/forgotpassword')
    // }
    render() {
        console.log(this.props)
        return (
            <div className="main-content h-h align-item-center">

                <div className="container ">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-md-7">
                            <div className="card border-0 mb-0 pad_z">

                                <div className="card-body px-lg-3 py-lg-3">
                                    <div className="text-center">
                                        <img src={require('../images/logo_new.png')} alt="az_logo" className="img-responsive " style={{ height: 40 }} />
                                    </div>
                                        <h4 className='text-center'>Create Contractor</h4>
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-group mb-3">
                                        <FormInput name={"companyname"} placeholder={"Company Name"} type={"text"} />
                                        </div>
                                        <div className="form-group mb-3">
                                            <FormInput name={"firstname"} placeholder={"First Name"} type={"text"} />
                                        </div>
                                        <div className="form-group mb-3">
                                            <FormInput name={"lastname"} placeholder={"Last Name"} type={"text"} />
                                        </div>
                                        {/* <div className="form-group">
                                            <FormInput name={"username"} placeholder={"Employee Number"} type={"text"} />
                                        </div> */}
                                        <div className="form-group">
                                            <FormInput name={"email"} placeholder={"Email"} type={"text"} />
                                        </div>
                                        {/* <div className="form-group">
                                            
                                            <input className='form-control' name='password' placeholder='Password' type='password' min={'8'} />
                                        </div> */}
                                        <div className="text-center">
                                            <Button color={"btn-primary az-color"} type={"submit"} variant={"my-2"} size={"large"}>Sign Up</Button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 text-center" >
                                    <small onClick={() => this.props.history.push('/')}>Back to Login screen</small>
                                </div>
                                {/* <div className="col-6 text-right">
                                    <small onClick={() => this.props.history.push('/create')}>Create Account</small>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.loader && <Loader />}
            </div>



        );
    }
}

export default CreateAccount;