import React, { Component } from 'react';

import Sidenav from './Sidenav';
import '../css/App.css';



class MyAccount extends Component {
   

    render() {
        return (<div className="" >
           <Sidenav type={'second'} url={'/dashboard'}/>
            <div className="container">
                <div className="row">
                    <div className="col s12 account">
                    <h2>My Account</h2>
                    <p>Employee ID : {localStorage.getItem('emp_id')}</p>
                    <p>Email ID : {localStorage.getItem('email')}</p>
                    <p>First Name : {localStorage.getItem('firstname')}</p>
                    <p>Last Name : {localStorage.getItem('lastname')}</p>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default MyAccount;