import axios from 'axios';
import API from '../../../src/config_api'
import { FETCH_LOGIN_DATA_REQUEST, FETCH_LOGIN_DATA_SUCCESS, FETCH_LOGIN_DATA_FAILURE } from './LoginTypes'
export const fetchDataRequest = () => {
    return {
        type: FETCH_LOGIN_DATA_REQUEST
    }
}
export const fetchDataSuccess = data => {
    return {
        type: FETCH_LOGIN_DATA_SUCCESS,
        payload: data
    }
}
export const fetchDataFailure = error => {
    return {
        type: FETCH_LOGIN_DATA_FAILURE,
        payload: error
    }
}


export const fetchLoginData = (email, password, props) => {
    return (dispatch) => {
        dispatch(fetchDataRequest());

        let formData = new FormData();
        formData.append('loginemail', email)
        formData.append('loginpassword', password)
        formData.append('company_id', localStorage.getItem('company_id'));
        formData.append('type', '3');
        formData.append('device_type', '1');
        formData.append('device_token', localStorage.getItem('notification-token'));
        axios({
                method: 'post',
                url: API.Login,
                data: formData,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                mode: 'no-cors',
            })
            .then(res => {
                if (res.data.status === 'success') {
                    const data = res.data;
                    console.log(res.data);
                    dispatch(fetchDataSuccess(data))
                    localStorage.setItem('token', res.data.data.id);
                    localStorage.setItem('firstname', res.data.data.firstname);
                    localStorage.setItem('lastname', res.data.data.lastname);
                    localStorage.setItem('email', res.data.data.email);
                    localStorage.setItem('emp_id', res.data.data.employee_id);
                    localStorage.setItem('company_no', res.data.data.company_id);
                    localStorage.setItem('type', res.data.data.type);
                    localStorage.setItem('contractor', res.data.data.contractor_name);
                    if (res.data.data.type.includes(50)) {
                        props.history.push('/security');
                    } else if (res.data.data.type.includes(4)) {
                        props.history.push('/assessor');
                    } else if (res.data.data.type.includes(6)) {
                        props.history.push('/approver');
                    } else if (res.data.data.type.includes(7)) {
                        props.history.push('/ehs');
                    } else if (res.data.data.type.includes(3)) {

                        props.history.push('/dashboard');
                    }
                } else {
                    const error = res.data.message;
                    dispatch(fetchDataFailure(error))
                    alert(res.data.message);
                }
            });

    }
}