import React, { Component }  from 'react'
  
class PDFViewers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numPages: null,
            pageNumber:1
        };
        

    }
    onDocumentLoadSuccess=({ numPages }) =>{
        this.setState({
            numPages
        })
       
      }
    render() {
        console.log(this.props.location.state.file)
        return (
            <div>
           <iframe src={this.props.location.state.file}  width={"100%"} style={{height:'100vh'}}/>
          </div>
        )
    }
}
export default PDFViewers
