import React, { Component } from 'react';
import '../css/App.css';
import IMG from '../images/loader.svg'
class Loader extends Component {

    render() {

        return (
            <div className="loading  h-h">
                <div className="loader">
                    <img src={IMG} alt="loader" style={{width:100}}/>
                </div>
               
            </div>
        );
    }
}

export default Loader;