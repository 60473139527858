import { FETCH_ASSIGN_DATA_REQUEST, FETCH_ASSIGN_DATA_SUCCESS, FETCH_ASSIGN_DATA_FAILURE } from './AssignTypes'
const initialState = {
    loading: false,
    data: [],
    error: ''
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ASSIGN_DATA_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_ASSIGN_DATA_SUCCESS:
            return {
                loading: false,
                data: action.payload.data.lists
            }
        case FETCH_ASSIGN_DATA_FAILURE:
            return {
                loading: false,
                error: action.payload
            }

        default:
            return state;
    }
}
export default reducer;