import React, { Component } from 'react';
import axios from 'axios';
import API from '../config_api';
import '../css/App.css';
import Sidenav from './Sidenav';


class Privacy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: ''
           

        };
      
    }
    componentWillMount() {
        
        axios({
            method: 'post',
            url: API.Privacy,
           
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            mode: 'no-cors',
        })
            .then(res => {
                if (res.data.status === 'success') {
                    this.setState({ data: res.data.data.scalar })
                    
                }
                else {
                    alert(res.data.message)
                }
            });

    }

    render() {
        return ( <div className = "" >
             
             <Sidenav type={'second'} url={'/dashboard'}/>
            <div className="container">
                <div className="row">
                <div className="col s12">
                <div dangerouslySetInnerHTML={{ __html: this.state.data }}  className="about"/>

                </div>
                </div>
            </div>
            
            </div>
        );
    }
}

export default Privacy;