import React, { Component } from 'react'
import '../css/App.css';
class MainDashboard extends Component {
    render() {
        return (
            <div class="container">
                <div class="qr">
                    <img src={require('../images/qr.png')}  style={{width:200}}/>
                    <p style={{fontSize:'10px'}}>Scan this code with your phone to download ams OSRAM ePTW</p>
                </div>
                <div class="description">
                    <img src={require('../images/logo_new.png')} />
                    <h1>ams OSRAM ePTW</h1>
                    {/* <p> AcuiZen Lite is a simplified version of AcuiZen: The SuperApp for Work.</p> */}

                    {/* <p> You can access AcuiZen Lite from right here. If  you have an Enterprise ID go ahead and enter it and you are all set. If you do not have an Enterprise ID and want to get a flavour of the application, enter ACUIZEN as the Enterprise ID.</p> */}

                    <p> ams OSRAM ePTW is best experienced through your mobile device. You can get started by scanning the QR code from your IOS or Android devices. If you need more detailed instructions, refer to this <a href="https://youtu.be/p1PJDH48OTg" target="_blank">link.</a></p>
                    <a class="button" style={{background: '#005284',padding:'10px 60px',borderRadius:'10px',color:'#fff'}} href="https://ams-osram.acuizen.com/login">View Full Screen</a>
                </div>


                <div class="smartphone" >
                    <div class="content">
                        <iframe src="https://ams-osram.acuizen.com/" style={{width:'100%',border:'none',height:'100%'}} />
                    </div>
                </div>


            </div>
        )
    }
}

export default MainDashboard