import React, { Component } from 'react';
import axios from 'axios';
import Sidenav from '../pages/Sidenav';
import API from '../config_api'
import '../css/App.css';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Icon from '@mdi/react'
import { mdiProgressClock, mdiProgressWrench, mdiProgressCheck, mdiProgressClose } from '@mdi/js'

import Loader from '../component/Loader';
import { mdiHomeCity } from '@mdi/js'
import moment from 'moment';

class AssessorDashBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            insights: '',
            percentage: '',
            loading: false,
            pending: '',
            return: []

        };

    }
    componentWillMount() {
        if (!localStorage.getItem('token')) {
            this.props.history.push('/login')
        }
        else {
            let formData = new FormData();
            formData.append('user_id', localStorage.getItem('token'))
            formData.append('user_type', 'assessor')

            axios({
                method: 'post',
                url: API.Dashboard,
                data: formData,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                mode: 'no-cors',
                async: false,
            })
                .then(res => {
                    this.setState({
                        loading: true
                    })
                    if (res.data.status === 'success') {
                        this.setState({ data: res.data.data })
                        console.log(res.data.data);
                    }
                    else {
                        alert(res.data.message)
                    }
                });
            axios({
                method: 'post',
                url: API.OnpermitPending,
                data: formData,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                async: false,
                mode: 'no-cors',
            })
                .then(res => {

                    this.setState({ pending: res.data.data })
                    console.log(res.data.data);

                });
            axios({
                method: 'post',
                url: API.GetRevokedData,
                data: formData,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                async: false,
                mode: 'no-cors',
            })
                .then(res => {

                    this.setState({ return: res.data.data })
                    console.log(res.data.data);

                });
            // axios({
            //     method: 'post',
            //     url: API.onLivePermitLocationData,
            //     data: formData,
            //     headers: {
            //         'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            //     },
            //     async: false,
            //     mode: 'no-cors',
            // })
            //     .then(res => {


            //         console.log(res.data.data);

            //     });


        }
    }
    btnAction(item) {
        this.props.history.push('/assessorfetch/' + item.program_id, { session_id: item.session_id, applicant: item.user_id, name: item.name + ' ' + item.lastname })
    }
    changeDate(date) {
        const dateString = date;
        const dateObject = moment(dateString, 'MM/DD/YYYY');

        const formattedDate = dateObject.format('DD-MMM-YYYY');
        return formattedDate
    }
    render() {

        return (

            <div className=" ">
                <Sidenav type={"main"} push={this.props.history} />
                <ReactCSSTransitionGroup
                    transitionAppear={true}
                    transitionAppearTimeout={600}
                    transitionEnterTimeout={600}
                    transitionLeaveTimeout={200}
                    transitionName={this.props.match.path === '/dashboard' ? 'SlideIn' : 'SlideOut'}
                >
                    <div className="container" style={{ position: 'relative' }}>
                        <div className="row marLeft-5 marRight-5">
                            <div className="col"> <div className="pt-2 pb-2">Hello<br /> <span style={{ fontSize: 20, fontWeight: 600 }}>{localStorage.getItem('firstname')}  {localStorage.getItem('lastname')} !</span></div></div>
                        </div>
                        <div className="row marLeft-5 marRight-5">
                            <div className="col"> <div className="pt-2 pb-2 text-center"><span style={{ fontSize: 20, fontWeight: 600 }}>ams OSRAM DRI</span>
                                {localStorage.getItem('type').indexOf('4') !== -1 || localStorage.getItem('type').indexOf('6') !== -1 || localStorage.getItem('type').indexOf('50') !== -1 ?
                                    <div className="dropdown">
                                        <a href="true" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                                            <i class="fa fa-chevron-circle-down fa-2x" aria-hidden="true"></i>
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                            {/* {localStorage.getItem('type').indexOf('3') !== -1 ?
                                                <button className="dropdown-item" onClick={() => this.props.history.push('/dashboard')}>Contractor DRI</button>
                                                : null} */}
                                            {localStorage.getItem('type').indexOf('7') !== -1 ?
                                                <button className="dropdown-item" onClick={() => this.props.history.push('/ehs')}>EHS</button>
                                                : null}
                                            {localStorage.getItem('type').indexOf('6') !== -1 ?
                                                <button className="dropdown-item" onClick={() => this.props.history.push('/approver')}>DRI Manager</button>
                                                : null}
                                            {localStorage.getItem('type').indexOf('50') !== -1 ?
                                                <button className="dropdown-item" onClick={() => this.props.history.push('/security')}>Viewer</button>
                                                : null}

                                        </div>
                                    </div>
                                    : null
                                }
                            </div></div>
                        </div>
                        <div className="row marLeft-5 marRight-5">
                            <div className="col-12  pad_z">
                                <div className="flex">
                                    <div className={`card flex justify-content-center mb-0 `} onClick={() => this.props.history.push('/live', { user: 'assessor' })}>
                                        <div className=" flex justify-content-center align-item-center">
                                            <Icon path={mdiProgressClock}
                                                title="User Profile"
                                                size={2}
                                                color={'#10705d'}
                                            />

                                        </div>
                                        <div className=" flex justify-content-center align-item-center">
                                            <h5 className="m-0 mt-1">{this.state.data.active}</h5>

                                        </div>
                                        <div className=" flex justify-content-center align-item-center">
                                            <h5 className="m-0 mt-1">Ongoing project</h5>

                                        </div>

                                    </div>
                                    <div className={`card flex justify-content-center mb-0 `} onClick={() => this.props.history.push('/upcoming', { user: 'assessor' })} >
                                        <div className=" flex justify-content-center align-item-center">
                                            <Icon path={mdiProgressCheck}
                                                title="User Profile"
                                                size={2}
                                                color={'#d9b007'}
                                            />

                                        </div>
                                        <div className=" flex justify-content-center align-item-center">
                                            <h5 className="m-0 mt-1">{this.state.data.upcoming}</h5>

                                        </div>
                                        <div className=" flex justify-content-center align-item-center">
                                            <h5 className="m-0 mt-1">Upcoming project</h5>

                                        </div>

                                    </div>
                                 
                                    <div className={`card flex justify-content-center mb-0 `} onClick={() => this.props.history.push('/inprogress', { user: 'assessor' })} >
                                        <div className=" flex justify-content-center align-item-center">
                                            <Icon path={mdiProgressWrench}
                                                title="User Profile"
                                                size={2}
                                                color={'#ffa500'}
                                            />

                                        </div>
                                        <div className=" flex justify-content-center align-item-center">
                                            <h5 className="m-0 mt-1">{this.state.data.in_process}</h5>

                                        </div>
                                        <div className=" flex justify-content-center align-item-center">
                                            <h5 className="m-0 mt-1">under verification by EHS</h5>

                                        </div>

                                    </div>
                                    <div className={`card flex justify-content-center mb-0 `} onClick={() => this.props.history.push('/inprogress', { user: 'assessor' })} >
                                        <div className=" flex justify-content-center align-item-center">
                                            <Icon path={mdiProgressWrench}
                                                title="User Profile"
                                                size={2}
                                                color={'#ffa500'}
                                            />

                                        </div>
                                        <div className=" flex justify-content-center align-item-center">
                                            <h5 className="m-0 mt-1">{this.state.data.in_process}</h5>

                                        </div>
                                        <div className=" flex justify-content-center align-item-center">
                                            <h5 className="m-0 mt-1 text-center">pending approval by DRI Manager</h5>

                                        </div>

                                    </div>
                                    <div className={`card flex justify-content-center mb-0 `} onClick={() => this.props.history.push('/reject', { user: 'assessor' })} >
                                        <div className=" flex justify-content-center align-item-center">
                                            <Icon path={mdiProgressClose}
                                                title="User Profile"
                                                size={2}
                                                color={'red'}
                                            />

                                        </div>
                                        <div className=" flex justify-content-center align-item-center">
                                            <h5 className="m-0 mt-1">{this.state.data.reject}</h5>

                                        </div>
                                        <div className=" flex justify-content-center align-item-center">
                                            <h5 className="m-0 mt-1 " style={{ textAlign: 'center' }}>Return by EHS/DRI Manager</h5>

                                        </div>

                                    </div>

                                </div>
                            </div>


                        </div>

                        <div className="col-12 mt-2 mb-2 text-center">Pending Action</div>
                        <div className="row marLeft-5 marRight-5" style={{ height: 300, overflowX: 'auto', position: 'absolute', left: 0, right: 0 }}>

                            {this.state.pending.length === 0 ? <p>No pending Action</p> :
                                this.state.pending.map((item, i) => {
                                    return (
                                        <div className="card flex row list-group-horizontal align-items-center p-0" style={{ margin: 10, borderLeft: '5px solid #efdf9b' }} key={i} onClick={() => this.btnAction(item)}>
                                            <div className="col-sm-11 col-xs-11 mb-2">
                                                <p className="mb-2">{item.title}</p>
                                                <div className="flex flex-column mb-2">
                                                    <span>#{item.permit_id}</span>
                                                    <span>{this.changeDate(item.created_at)} </span>
                                                    <span>Contractor DRI : {item.name} {item.lastname}</span>
                                                    <span>Contractor Name : {item.contractor_name}</span>
                                                    <span>Start Date : {this.changeDate(item.start_time)} </span>
                                                    <span>End Date : {this.changeDate(item.end_time)}</span>
                                                    <span style={{ padding: '5px 0', fontWeight: 'bold' }}>{item.description}</span>
                                                    {item.description1 &&
                                                        <span
                                                            style={{ padding: "5px 0", fontWeight: "bold" }}
                                                        >
                                                            {item.description1}
                                                        </span>
                                                    }
                                                    {item.status &&
                                                        item.status === '1' ? <span style={{ padding: '5px 0', color: 'green', fontWeight: 'bold' }}>Status : Contractor Submitted to ams DRI</span>
                                                        : item.status === '3' ? <span style={{ padding: '5px 0', color: 'green', fontWeight: 'bold' }}>Status : Approved by DRI Manager</span> :
                                                            item.status === '4' ? <span style={{ padding: '5px 0', color: 'green', fontWeight: 'bold' }}>Status : EHS Submitted to DRI Manager</span>
                                                                : item.status === '9' ? <span style={{ padding: '5px 0', color: 'green', fontWeight: 'bold' }}>Status : ams DRI Submitted to EHS</span>
                                                                    : item.status === '2' ? <span style={{ padding: '5px 0', color: 'green', fontWeight: 'bold' }}>Status : Permit Rejected by DRI Manager</span>
                                                                        : ''}
                                                </div>
                                                <div className="flex mb-1">
                                                    <div className="col-6 p-0">
                                                        <span style={{ paddingRight: 14 }}>
                                                            <Icon path={mdiHomeCity}
                                                                title="User Profile"
                                                                size={0.8}
                                                                color={'#be1f24'}
                                                            />
                                                        </span>
                                                        <span>{item.project}</span>
                                                    </div>
                                                    {/* <div className="col-6 p-0">
                    <span style={{ paddingRight: 14 }}>
                        <IosPin size={30} color={'#be1f24'} />
                    </span>
                    <span>{item.location}</span>
                </div> */}

                                                </div>
                                                {/* <div className="flex">
                <div className="col-6 p-0">
                    <span style={{ paddingRight: 14 }}>
                        
                    </span>
                    <span>{item.permit_date}</span>
                </div>
                <div className="col-6 p-0">
                    <span style={{ paddingRight: 14 }}>
                        
                    </span>
                    <span>{item.start_time} {item.end_time}</span>
                </div>


            </div> */}
                                            </div>

                                            <div className="col-sm-1 col-xs-1 pad_z text-right"><i className="material-icons">
                                                keyboard_arrow_right
                                            </i></div>
                                            <div className="flex justify-content-center" style={{ background: '#be1f24', color: '#fff', borderBottomLeftRadius: 5, borderBottomRightRadius: 5 }}>{item.remarks}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>





                        {!this.state.loading && <Loader />}

                    </div>
                </ReactCSSTransitionGroup>
            </div>
        );
    }
}

export default AssessorDashBoard;