import React, { Component } from 'react';
import '../css/App.css';
import { fetchAssessorData, dataUnmount } from '../redux/microData/MicroAction'
import { connect } from 'react-redux'
import axios from 'axios'
import API from '../config_api'
import FancyVideo from 'react-videojs-fancybox';

import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import SignatureCanvas from 'react-signature-canvas'
import { Modal } from 'react-bootstrap';
import { Plugins, CameraResultType, CameraSource } from "@capacitor/core";
import '@ionic/pwa-elements';
import Loader from '../component/Loader';
import { ReactMic } from 'react-mic';
import BigPicture from "react-bigpicture";
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
import { DatePicker, TimePicker, } from "@material-ui/pickers";
import Icon from '@mdi/react'
import { mdiHomeCity } from '@mdi/js';
import UNCHECKED from '../images/unchecked_48dp.png'
import CHECKED from '../images/checked_48dp.png'
import RADIOUNCHECKED from '../images/radio_unchecked_48dp.png'
import RADIOCHECKED from '../images/radio_checked_48dp.png'

const { Camera, Browser, Network, Geolocation } = Plugins;


class RejectFetchSteps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            currentStep: 0,
            style: "menu",
            menuStatus: "open",
            status: false,
            isRecording: false,
            blobURL: '',
            loading: false,
            isBlocked: false,
            requiredCheck: true,
            preview: '',
            visible: false,
            mapvisible: false,
            audioVisible: false,
            videoVisible: false,
            index: '',
            record: false,
            recordedBlob: '',
            lat: 0,
            lng: 0,
            mediaDevice: [],
            videoRecord: false,
            lightboxIsOpen: false,
            projectList: [],
            locationList: [],
            locationData: [],
            approverList: [],
            assessorList: [],
            projectList: [],
            textVisible: false,
            YesText: '',
            files: [],
            box: false,
            setAssessor: '',
            project: '',
            date: new Date(),
            sTime: '',
            eTime: '',
            center: {

                lat: -3.745,
                lng: -38.523

            },
            seconds: 0,
            file: [],
            permitID: ''
        };
        this.fileObj = [];
        this.fileArray = [];
    }

    componentDidMount() {
        setInterval(() => {

            this.setState(({ seconds }) => ({
                seconds: seconds + 1
            }))


        }, 1000)
        // navigator.getUserMedia({ audio: true },
        //     () => {
        //         console.log('Permission Granted');
        //         this.setState({ isBlocked: false });
        //     },
        //     () => {
        //         console.log('Permission Denied');
        //         this.setState({ isBlocked: true })
        //     },
        // );


    }
    async componentWillMount() {
        if (!localStorage.getItem('token')) {
            this.props.history.push('/login')
        }

        this.props.getMictoData(this.props.match.params.id, this.props.location.state.session_id);
        let formData = new FormData();
        formData.append('company_id', localStorage.getItem('company_no'));

        axios({
            method: 'post',
            url: API.GetIntermediate,
            data: formData,
            headers: {
                'content-type': 'multipart/form-data'

            },
            mode: 'no-cors',
        })
            .then(res => {
                console.log(res)
                this.setState({ approverList: res.data.data })

            })
        // let status = await Network.getStatus();
        // console.log(status);
        // const coordinates = await Geolocation.getCurrentPosition();
        // console.log('Current', coordinates);
        // this.setState({
        //     lonn: coordinates.coords.longitude,
        //     latt: coordinates.coords.latitude
        // })
        let formData1 = new FormData();
        formData1.append('company_id', localStorage.getItem('company_no'));
        axios({
            method: 'post',
            url: API.getLocationAction,
            data: formData1,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            mode: 'no-cors',
        })
            .then(res => {

                let projects = []
                let locations = {}
                res.data.data.forEach((data, index) => {

                    var id = data.id
                    var obj = {
                        value: id,
                        label: data.project_name,
                        coords: data.coords
                    }

                    locations[id] = data.location
                    projects.push(obj)
                })
                // var obj = {
                //     value: "0",
                //     label: "Other"
                // }

                // projects.push(obj)
                this.setState({ projectList: projects, locationData: locations })
            })

        axios({
            method: 'post',
            url: API.GetAssessorAction,
            data: formData,
            headers: {
                'content-type': 'multipart/form-data'

            },
            mode: 'no-cors',
        })
            .then(res => {
                console.log(res)
                this.setState({ assessorList: res.data.data })

            })

    }
    componentWillUnmount() {
        this.props.getMictoDataUnmount();
    }

    async takePicture(item, index) {
        const image = await Camera.getPhoto({
            resultType: CameraResultType.DataUrl,
            quality: 90,
            allowEditing: true,
            source: CameraSource.Camera
        });
        console.log(image);

        this.props.data[this.state.currentStep].microstep_data[index].value = image.dataUrl;
        this.forceUpdate();
    }
    removePicture(item, index) {

        this.props.data[this.state.currentStep].microstep_data[index].value = '';
        this.forceUpdate();
    }
    async launchScrom(url) {
        await Browser.open({ url: url });
    }
    async takeVideo(item, index) {
        this.setState({
            videoVisible: true,
            index: index
        })
        this.gotDevices();
    }
    gotDevices = async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        this.setState({
            mediaDevice: videoDevices
        })
        const videoConstraints = {};

        videoConstraints.facingMode = 'environment';
        const constraints = {
            video: videoConstraints,
            audio: false
        };
        this.capture(constraints)
    };
    async capture(constraints) {
        //   var constraints = {audio:true, video: { facingMode: "environment" } };

        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        // show it to user
        this.setState({
            currentStream: stream
        })
        this.video.srcObject = stream;
        this.video.play();
        // init recording

        this.mediaRecorder = new MediaRecorder(stream, {
            mimeType: 'video/webm'
        });
        // init data storage for video chunks
        this.chunks = [];
        // listen for data from media recorder
        this.mediaRecorder.ondataavailable = e => {
            if (e.data && e.data.size > 0) {
                this.chunks.push(e.data);
            }
        };
    }
    takeSign(item, index) {
        this.setState({
            visible: true,
            index: index
        });

    }
    takeAudio(item, index) {
        this.setState({
            audioVisible: true,
            index: index
        })
    }
    startRecording = () => {
        this.setState({
            record: true
        });
    }
    stopMediaTracks(stream) {
        stream.getTracks().forEach(track => {
            track.stop();
        });
    }

    startVideoRecord(e) {

        // wipe old data chunks
        this.chunks = [];
        // start recorder with 10ms buffer
        this.mediaRecorder.start(10);
        // say that we're recording
        this.setState({ videoRecord: true });
    }
    changeCamera(event) {
        if (typeof this.state.currentStream !== 'undefined') {
            this.stopMediaTracks(this.state.currentStream);
        }
        const videoConstraints = {};
        if (event.target.value === '') {
            videoConstraints.facingMode = 'environment';
        } else {
            videoConstraints.deviceId = { exact: event.target.value };
        }
        const constraints = {
            video: videoConstraints,
            audio: false
        };
        this.capture(constraints)
    }
    stopVideoRecord(e) {

        // stop the recorder
        this.mediaRecorder.stop();
        // say that we're not recording
        this.setState({ videoRecord: false });
        // save the video to memory
        this.stopMediaTracks(this.state.currentStream);
        this.saveVideo();
    }
    saveVideo() {

        const blob = new Blob(this.chunks, { type: 'video/mp4' });
        const videoURL = window.URL.createObjectURL(blob);
        this.props.data[this.state.currentStep].microstep_data[this.state.index].value = videoURL;
        this.props.data[this.state.currentStep].microstep_data[this.state.index].blob = blob;
        this.forceUpdate();
        this.setState({
            videoVisible: false,
            index: null
        })

    }
    stopRecording = () => {
        this.setState({
            record: false
        });
    }

    onData(recordedBlob) {
        console.log('chunk of real-time data is: ', recordedBlob);
    }

    onStop = recordedBlob => {
        console.log(recordedBlob)
        this.props.data[this.state.currentStep].microstep_data[this.state.index].value = recordedBlob.blobURL;
        this.props.data[this.state.currentStep].microstep_data[this.state.index].blob = recordedBlob.blob;
        this.forceUpdate();

    }
    closeModal() {
        this.setState({
            visible: false,
            index: null
        })
        this.sigPad.clear()
    }
    closeAudioModal() {

        this.props.data[this.state.currentStep].microstep_data[this.state.index].value = '';
        this.forceUpdate();
        this.setState({
            audioVisible: false,
            index: null
        })

    }
    closeVideoModal() {

        this.props.data[this.state.currentStep].microstep_data[this.state.index].value = '';
        this.forceUpdate();
        this.setState({
            videoVisible: false,
            index: null
        })

    }
    signTrim() {
        if (!this.sigPad.isEmpty()) {
            const sign = this.sigPad.getTrimmedCanvas().toDataURL('image/png');
            this.props.data[this.state.currentStep].microstep_data[this.state.index].value = sign;
            this.props.data[this.state.currentStep].microstep_data[this.state.index].new = 1;
            this.forceUpdate();
            this.setState({
                visible: false,
                index: null
            })
            this.sigPad.clear();
        }
    }
    removeSign(item, index) {
        this.props.data[this.state.currentStep].microstep_data[index].value = '';
        this.forceUpdate();
    }
    saveAudio() {
        if (!this.state.record) {
            this.setState({
                audioVisible: false,
                index: null
            })
        }
    }

    checkBoxAction(data, item, index) {

        if (item.is_multiselect === "1") {

            if (data.checked === 1) {
                data.checked = 0
                this.forceUpdate()
            } else {
                data.checked = 1
                this.forceUpdate()

            }

        } else {


            item.options.forEach((data, ind) => {

                if (ind === index) {
                    data.checked = 1
                    this.forceUpdate()
                } else {
                    data.checked = 0
                    this.forceUpdate()

                }

            })
        }
        if (data.name === 'No') {
            delete item.files
        }
        console.log(item);
        this.setState({
            files: [],
            box: false
        })
        if (item.value.match('Does this work involve working at height?')) {
            if (data.name === 'Yes') {
                console.log(item.files)
                let formData = new FormData();
                formData.append('question', 'working at height')
                axios({
                    method: 'post',
                    url: API.Hint,
                    data: formData,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    },
                    mode: 'no-cors',
                })
                    .then(res => {
                        this.setState({
                            YesText: res.data.data[0].hints,
                            selectFile: 'working at height',
                            box: true
                        })


                    })
                if (item.files) {
                    this.setState({
                        files: item.files,

                    })
                }


            }
        } else if (item.value.match('Does this work involve working in confined spaces?')) {
            if (data.name === 'Yes') {
                let formData = new FormData();
                formData.append('question', 'confined spaces')
                axios({
                    method: 'post',
                    url: API.Hint,
                    data: formData,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    },
                    mode: 'no-cors',
                })
                    .then(res => {
                        this.setState({
                            YesText: res.data.data[0].hints,
                            selectFile: 'confined spaces',
                            box: true
                        })


                    })
                if (item.files) {
                    this.setState({
                        files: item.files,

                    })
                }
            }
        } else if (item.value.match('Does this work require scaffolding?')) {
            if (data.name === 'Yes') {
                let formData = new FormData();
                formData.append('question', 'scaffolding')
                axios({
                    method: 'post',
                    url: API.Hint,
                    data: formData,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    },
                    mode: 'no-cors',
                })
                    .then(res => {
                        this.setState({
                            YesText: res.data.data[0].hints,
                            selectFile: 'scaffolding',
                            box: true
                        })


                    })
                if (item.files) {
                    this.setState({
                        files: item.files,

                    })
                }
            }
        } else if (item.value.match('Any chemicals being brought into the facility for the performance of work?')) {
            if (data.name === 'Yes') {
                this.setState({
                    YesText: 'Please ensure that Safety Data Sheets are available for all chemicals that are being used on site.'
                })
            }
        } else if (item.value.match('Does this work involve lifting operations or use of crane?')) {
            if (data.name === 'Yes') {
                let formData = new FormData();
                formData.append('question', 'use of crane')
                axios({
                    method: 'post',
                    url: API.Hint,
                    data: formData,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    },
                    mode: 'no-cors',
                })
                    .then(res => {
                        this.setState({
                            YesText: res.data.data[0].hints,
                            selectFile: 'use of crane',
                            box: true
                        })


                    })
                if (item.files) {
                    this.setState({
                        files: item.files,

                    })
                }
            }
        } else if (item.value.match('Does this work involve undertaking electrical works?')) {
            if (data.name === 'Yes') {
                let formData = new FormData();
                formData.append('question', 'electrical works')
                axios({
                    method: 'post',
                    url: API.Hint,
                    data: formData,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    },
                    mode: 'no-cors',
                })
                    .then(res => {
                        this.setState({
                            YesText: res.data.data[0].hints,
                            selectFile: 'electrical works',
                            box: true
                        })


                    })
                if (item.files) {
                    this.setState({
                        files: item.files,

                    })
                }
            }
        } else if (item.value.match('Does this work involve hot work?')) {
            if (data.name === 'Yes') {
                console.log(item.files)
                let formData = new FormData();
                formData.append('question', 'hot work')
                axios({
                    method: 'post',
                    url: API.Hint,
                    data: formData,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    },
                    mode: 'no-cors',
                })
                    .then(res => {
                        console.log(res)
                        this.setState({
                            YesText: res.data.data[0].hints,
                            selectFile: 'hot work',
                            box: true
                        })

                    })
                if (item.files) {
                    this.setState({
                        files: item.files,

                    })
                }
            }
        } else if (item.value.match('Does the work involve processes that generate noise / fume / vapors / odor?')) {
            if (data.name === 'Yes') {
                this.setState({
                    YesText: 'Please ensure that this aspect is addressed in the risk assessment that you will be submitting for this work.'
                })
            }
        } else if (item.value.match('Does the work involve fire impairment works?')) {
            if (data.name === 'Yes') {
                this.setState({
                    YesText: 'Please ensure that this aspect is addressed in the risk assessment that you will be submitting for this work.'
                })
            }
        } else if (item.value.match('Does the work involve dismantling of any pipe or equipment that contains steam or substances that are flammable, toxic or corrosive?')) {
            if (data.name === 'Yes') {
                this.setState({
                    YesText: 'Please ensure that this aspect is addressed in the risk assessment that you will be submitting for this work.'
                })
            }
        }
        if (data.name === 'Yes') {
            this.setState({
                textVisible: true
            })
        }
    }
    MCQAction(data, item, index, indexV) {
        console.log(this.state.currentStep)
        if (item.is_multiselect === "1") {
            if (data.imcaChecked === 1) {
                data.imcaChecked = 0
                this.forceUpdate()
            } else {
                data.imcaChecked = 1
                this.forceUpdate()
            }
            let files = item.options.filter(dt => dt.imcaChecked === 1)
            let ft = files.map(ds => {
                let dt = { ans: ds.name }
                return dt
            })
            let files2 = item.options.filter(dt => dt.checked === 1)
            let ans = files2.map(ds => {
                let dt = { ans: ds.name }
                return dt
            })
            this.props.data[this.state.currentStep].microstep_data[indexV].user_ans = ft
            this.props.data[this.state.currentStep].microstep_data[indexV].ans = ans
            this.forceUpdate()
        } else {
            item.options.forEach((data, ind) => {
                if (ind === index) {
                    data.imcaChecked = 1
                    this.forceUpdate()
                } else {
                    data.imcaChecked = 0
                    this.forceUpdate()
                }
            })

            let files = item.options.filter(dt => dt.imcaChecked === 1)
            let ft = files.map(ds => {
                let dt = { ans: ds.name }
                return dt
            })
            let files2 = item.options.filter(dt => dt.checked === 1)
            let ans = files2.map(ds => {
                let dt = { ans: ds.name }
                return dt
            })
            this.props.data[this.state.currentStep].microstep_data[indexV].user_ans = ft
            this.props.data[this.state.currentStep].microstep_data[indexV].ans = ans
            this.forceUpdate()
        }
        console.log(item)
    }
    insightAction(dataV, item, i, index) {
        item.insight_options.forEach((data, ind) => {
            if (ind === i) {
                data.checked = '1'
                this.forceUpdate()
            } else {
                data.checked = '0'
                this.forceUpdate()
            }
        })
        console.log(item)
    }
    checkbox_icon(is_multiselect, checked) {
        if (is_multiselect === "1") {
            if (checked === 0) {
                return (
                    <img src={UNCHECKED} alt="uncheck"

                        style={{ paddingRight: 10, height: 20 }} />
                )
            } else {
                return (<img src={CHECKED} alt="checked"
                    style={{ paddingRight: 10, height: 20 }} />
                )
            }
        } else {
            if (checked === 0) {
                return (<img src={RADIOUNCHECKED} alt="uncheck"
                    style={{ paddingRight: 10, height: 20 }} />
                )
            } else {
                return (<img src={RADIOCHECKED} alt="uncheck"
                    style={{ paddingRight: 10, height: 20 }} />
                )
            }
        }
    }
    mcqValueCheck(item) {
        if (item.some((data) => { return data.imcaChecked === 1 })) {
            // console.log('checked true')
            return true
        } else {
            // console.log('checked false')
            return false
        }
    }
    pollValueCheck(item) {
        if (item.some((data) => { return data.checked === 1 })) {
            return true
        } else {
            return false
        }
    }


    insightValueCheck(item) {
        if (item.some((data) => { return data.checked === 1 })) {
            return true
        } else {
            // this.setState({ requiredCheck: false})
            return false
        }
    }
    textInputInfo(event, index) {
        console.log(event.target.value);
        this.props.data[this.state.currentStep].microstep_data[index].value = event.target.value;
        this.forceUpdate()
    }
    ref = player => {
        this.player = player
    }
    datepicker = () => {
        // this.show('date');
        this.setState({ showDatePicker: true });
    }

    applicantOption(item, index) {
        if (index === 0) {
            return (
                <div className="col-12 pad_z">
                    <div className="card horizontal" style={{ border: this.state.requiredCheck === true ? '1px solid #fff' : this.props.data[0].microstep_data[0].value === '' && this.props.data[0].microstep_data[1].value === '' ? '1px solid red' : '1px solid #fff' }}>
                        <div className="card-stacked">
                            <div className="card-content">
                                <div class="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Select ams OSRAM DRI</label>
                                    <select class="form-control" id="exampleFormControlSelect1" onChange={(e) => this.setAssessor(e)}>
                                        <option value=" " selected="true" disabled>Please Select</option>
                                        {this.state.assessorList.map((item, i) => {
                                            return (
                                                item.id === this.userID() ? '' :
                                                    <option value={item.id} index={i} selected={this.props.assessor === item.id ? 'selected' : ''}>{item.name} {item.lastname}</option>
                                            )
                                        })}
                                    </select>

                                </div>
                                <div class="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Work Location</label>
                                    <select class="form-control" id="exampleFormControlSelect1" onChange={(e) => this.locationSelected(e, item, e.target.selectedIndex)}>
                                        <option value=" " selected disabled>Please Select</option>
                                        {item.map((item2, i) => {
                                            return (
                                                <option value={item2.value} index={i} selected={this.props.data[0].microstep_data[1].value === item2.label ? 'selected' : ''}>{item2.label}</option>
                                            )
                                        })}
                                    </select>

                                </div>

                                {/* <p>Permit ID : {this.state.permitID}</p> */}


                                {/* <div class="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Location</label>
                                    <select class="form-control" id="exampleFormControlSelect1" onChange={(e) => this.projectSelected(e)}>
                                        <option value=" " selected disabled>Please Select</option>
                                        {this.state.locationList.map((item3, i) => {
                                            return (
                                                <option value={item3.id}>{item3.value}</option>
                                            )
                                        })}
                                    </select>

                                </div> */}
                                {/* <div class="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Reporting Category</label>
                                    <select class="form-control" id="exampleFormControlSelect1" onChange={(e) => this.IncidentSelected(e)}>
                                        <option value=" "  disabled>Please Select</option>
                                        <option value="uc">Unsafe Condition</option>
                                        <option value="ua">Unsafe Act</option>
                                    </select>

                                </div> */}
                                {/* <div class="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Sub-Category</label>
                                    <select class="form-control" id="exampleFormControlSelect1" onChange={(e) => this.subSelected(e)}>
                                        <option value=" " selected disabled>Please Select</option>
                                        {this.state.subCatagory.map((item3, i) => {
                                            return (
                                                <option key={i} value={item3.id}>{item3.name}</option>
                                            )
                                        })}
                                    </select>

                                </div> */}
                            </div>
                        </div>
                    </div>
                    {/* <div className="card horizontal" style={{ border: this.state.requiredCheck === true ? '1px solid #fff' : this.props.data[0].microstep_data[5].value === '' ? '1px solid red' : '1px solid #fff' }}>
                        <div className="card-stacked">
                            <div className="card-content text-center">
                                <p className="text-left">Job Site</p>
                                <LoadScript googleMapsApiKey="AIzaSyBGcf9i6vlY07uvfI79hqgz7F7jTdaqA_I">
                                    <GoogleMap

                                        mapContainerStyle={{
                                            width: '100%',
                                            height: '400px'
                                        }}
                                        center={this.state.center}
                                        zoom={10}
                                        options={{
                                            streetViewControl: false,
                                            scaleControl: true,
                                            panControl: true,
                                            rotateControl: true,
                                            mapTypeControl: true,
                                            draggableCursor: 'default',
                                            draggingCursor: 'crosshair',
                                            zoomControl: true,
                                            maxZoom: 12,
                                            minZoom: 2,
                                            fullscreenControl: true
                                        }}
                                        onClick={(e) => this.mapClick(e)}
                                    >
                                        <Marker

                                            position={{
                                                lat: this.state.lat,
                                                lng: this.state.lng
                                            }}
                                        />

                                        <></>
                                    </GoogleMap>
                                </LoadScript> */}
                    {/* {this.props.data[0].microstep_data[5].value === '' ?
                                    <IosPin fontSize="60px" />
                                    :

                                    <GoogleMap

                                        mapContainerStyle={{
                                            width: '100%',
                                            height: '400px'
                                        }}
                                        center={{
                                            lat: this.state.lat,
                                            lng: this.state.lng
                                        }}
                                        zoom={14}
                                        options={{
                                            streetViewControl: false,
                                            scaleControl: false,
                                            panControl: false,
                                            rotateControl: false,
                                            mapTypeControl: false,
                                            draggable: false,
                                            draggableCursor: 'default',
                                            draggingCursor: 'crosshair',
                                            zoomControl: false,
                                            maxZoom: 12,
                                            minZoom: 2,
                                            fullscreenControl: false
                                        }}

                                    >
                                        <Marker

                                            position={{
                                                lat: this.state.lat,
                                                lng: this.state.lng
                                            }}
                                        />

                                        <></>
                                    </GoogleMap>

                                } */}
                    {/* </div>
                        </div>
                    </div> */}
                    {/* <div className="card horizontal" style={{ border: this.state.requiredCheck === true ? '1px solid #fff' : this.props.data[0].microstep_data[8].value === '' ? '1px solid red' : '1px solid #fff' }}>
                        <div className="card-stacked">
                            <div className="card-content text-center">
                                <p className="text-left">Description</p>
                                <div class="form-group">
                                    <textarea rows="6" style={{ width: '100%', borderColor: 'rgb(211, 211, 211)' }} class="form-control" placeholder="Type here.." onChange={(e) => { this.setState({ permitDescription: e.target.value }); this.props.data[0].microstep_data[8].value = e.target.value; }} ></textarea>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="flex">
                        <div className="card horizontal" style={{ width: '100%', marginRight: 15, border: this.state.requiredCheck === true ? '1px solid #fff' : this.props.data[0].microstep_data[6].value === '0' ? '1px solid red' : '1px solid #fff' }} >
                            <div className="card-stacked">
                                <p className="m-0">No. of worker(s)</p><input type="number" class="form-control" defaultValue={this.props.data[0].microstep_data[6].value.toString()} onChange={(e) => { this.setState({ workers: e.target.value }); this.props.data[0].microstep_data[6].value = e.target.value; }} />
                            </div>
                        </div>
                        <div className="card horizontal" style={{ width: '100%', marginLeft: 15 }}>
                            <div className="card-stacked">
                                <p className="m-0">No. of supervisor(s)</p><input type="number" class="form-control" defaultValue={this.props.data[0].microstep_data[7].value.toString()} onChange={(e) => { this.setState({ supervisors: e.target.value }); this.props.data[0].microstep_data[7].value = e.target.value; }} />
                            </div>
                        </div>
                    </div> */}
                    <div className="card horizontal" >
                        <div className="card-stacked">
                            <div className="card-content flex justify-content-between">
                                <p className="m-0">Start date</p>
                                <DatePicker value={this.props.data[0].microstep_data[2].date}
                                    minDate={this.props.data[0].microstep_data[2].date}
                                    format="MM/dd/yyyy"
                                    onChange={date => this.setDate(date)} />

                                <p className="m-0"> End date</p>
                                <DatePicker value={this.props.data[0].microstep_data[3].date}
                                    minDate={this.props.data[0].microstep_data[2].date}
                                    minDateMessage={'End Date should be on or after Start Date'}

                                    format="MM/dd/yyyy"
                                    onChange={date => this.setToDate(date)} />
                            </div>
                        </div>
                    </div>


                    {/* <div className="flex">
                        <div className="card horizontal" style={{ width: '100%', marginRight: 15, border: this.state.requiredCheck === true ? '1px solid #fff' : this.props.data[0].microstep_data[2].value === '' ? '1px solid red' : '1px solid #fff' }}>
                            <div className="card-stacked">
                                <p className="m-0">Start Time</p>

                                <TimePicker value={this.state.sTime} ampm={false} onChange={(e) => this.startTime(e)} />

                            </div>
                        </div>
                        <div className="card horizontal" style={{ width: '100%', marginLeft: 15, border: this.state.requiredCheck === true ? '1px solid #fff' : this.props.data[0].microstep_data[3].value === '' ? '1px solid red' : '1px solid #fff' }}>
                            <div className="card-stacked">
                                <p className="m-0">End Time</p>
                                <TimePicker value={this.state.eTime} ampm={false} disabled={this.state.endStatus} onChange={(e) => this.endTime(e)} />
                            </div>
                        </div>
                        <div className=" floatdoc" onClick={() => this.setState({ docvisible: true })}>
                            <Icon path={mdiTextBoxPlusOutline}
                                title="User Profile"
                                size={1}
                                color={'#ffffff'}
                            />
                        </div>
                    </div> */}
                </div>
            );
        }
    }
    setAssessor(e) {

        this.setState({
            setAssessor: e.target.value,
            selectedAssessor: e.target.options[e.target.selectedIndex].text
        })

    }
    uploadMultipleFiles(e, item, index) {
        // this.fileObj.push(e.target.files)
        if (!item.files) {
            item.files = [];
            this.forceUpdate();
        }

        console.log(index);
        console.log(item)
        // this.setState({ file: [null] })
        // for (let i = 0; i < this.fileObj.length; i++) {
        //     this.fileArray.push(URL.createObjectURL(this.fileObj[i][0]))
        // }
        let randomString = Math.random().toString(36);

        this.setState({
            theInputKey: randomString
        });
        item.files.push(e.target.files)
        // this.setState({ file: this.fileObj })
    }
    setDate(date) {
        // console.log(date)
        // var now = new Date();
        // now.setMinutes(now.getMinutes() + 10); // timestamp

        // this.setState({
        //     date: date,
        //     minDate: date,
        //     startStatus: false
        // })
        this.props.data[0].microstep_data[2].value = moment(date).format('L');
        this.props.data[0].microstep_data[2].date = date;

    }
    setToDate(date) {

        this.props.data[0].microstep_data[3].value = moment(date).format('L');

        this.props.data[0].microstep_data[3].date = date;
    }
    startTime(e) {
        console.log(e)
        // const minTime = moment(moment(new Date()).format('HH:mm a'), "HH:mm a");
        // //const maxTime = moment("12:01", "HH:mm");
        // console.log(minTime)

        // if (minTime.isBefore(e)) {
        this.setState({
            sTime: e
        })
        this.props.data[0].microstep_data[2].value = e
        // } else {

        // }

    }
    endTime(e) {
        console.log(e)
        // const minTime = moment(moment(this.state.sTime).format('HH:mm a'), "HH:mm a");
        // //const maxTime = moment("12:01", "HH:mm");
        // console.log(minTime)

        // if (minTime.isBefore(e)) {
        this.setState({
            eTime: e
        })
        this.props.data[0].microstep_data[3].value = e
        // } else {

        // }

    }
    replaceFun = (filename) => {
        if(filename){
        const ext = filename.substr(filename.lastIndexOf('.') + 1);
        const name = filename.substr(0, filename.lastIndexOf('.'));
        const final = name.replace(/\./g, '_');
        const final1 = final.replace(/-/g, '_');
        const final2 = final1.replace(/'/g, '_');
        const final3 = final2.replace(/&/g, '_');
        const final4 = final3.replace(/\+/g, '_');
        return final4.replace(/ /g, "_") + "." + ext
        }
    }
    removeAttach(url, item) {
        console.log(url)
        const id = item.files.indexOf(url); // 2
        item.files.splice(id, 1);
        //  item.splice(i,1)
    }
    removeAlreadyAttach(item, i) {
        item.allfiles.splice(i, 1);
    }
    closeModalYes = () => {
        let pollData = this.props.data[0].microstep_data.filter(data => data.type === "poll" && data.required === "1")
        pollData.forEach(p => {
            if (p.value.match(this.state.selectFile)) {
                p.options.map((itm) => {
                    itm.checked = 0

                })
            }
        })
        this.setState({
            textVisible: false
        })
    }
    // setDate(e) {
    //     console.log(e)
    //     this.setState({
    //         date: e
    //     })
    //     this.props.data[0].microstep_data[4].value = moment(e).format('L');
    // }
    webview(){
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        // true for mobile device
     return false;
        }else{
        // false for not mobile device
        return true;
        }
    }
    layout(item, index, step) {


        if (item.type === 'scorm') {

            return (
                <div className="card horizontal">

                    <div className="card-stacked">
                        <div className="card-content">
                            <p>Scrom</p>
                            <img onClick={() => this.launchScrom(item.name)} src={require('../images/camera.png')} alt="camera" />
                        </div>

                    </div>
                </div>
            );


        }

        if (item.type === 'youtube') {
            return (
                <div className="card horizontal">

                    <div className="card-stacked">
                        <div className="card-content">
                            <BigPicture
                                type="youtube"
                                src={"https://www.youtube.com/watch/v=" + item.name}
                            >
                                <div className="flex" style={{ position: 'relative' }}>
                                    <img src={"https://img.youtube.com/vi/" + item.name + "/0.jpg"} className="img-responsive" style={{ width: '100%' }} alt="test" />
                                    <div className="overlay"> <img src={require('../images/youtube.png')} style={{ width: '70px' }} alt="test" /></div>

                                </div>
                            </BigPicture>

                        </div>

                    </div>
                </div>

            );
        }
        else if (item.type === 'paragraph') {
            return (
                <div className="card horizontal">

                    <div className="card-stacked">
                        <div className="card-content">
                            <div dangerouslySetInnerHTML={{ __html: item.name }} />
                        </div>

                    </div>
                </div>

            )
        }
        else if (item.type === 'image') {
            return (
                <div className="card horizontal">

                    <div className="card-stacked">
                        <div className="card-content text-center">
                            <BigPicture
                                type="image"
                                src={item.name}
                            >
                                <div className="flex justify-content-center"><img src={item.name} alt="step" className="img-responsive" /></div>
                            </BigPicture>

                        </div>

                    </div>
                </div>

            )
        }
        else if (item.type === 'video') {

            return (
                <div className="card horizontal">

                    <div className="card-stacked">
                        <div className="card-content">

                            {/* <FancyVideo
                                source={item.name}
                                poster={require('../images/white.jpg')}
                                id={Math.random().toString(36).substring(7)} /> */}
                            {/* <video src={item.name} controls style={{ width: '100%' }}>
                                Your browser does not support the video tag.
</video> */}
                            <BigPicture
                                type="video"
                                src={item.name}
                            >
                                <div className="flex" style={{ position: 'relative' }}>
                                    {item.preview ? <img src={item.preview} className="img-responsive" style={{ width: '100%' }} alt="test" /> : <img src={require('../images/black.jpg')} className="img-responsive" style={{ width: '100%', height: 200 }} alt="test" />
                                    }
                                    <div className="overlay"> <img src={require('../images/cinema.png')} style={{ width: '70px' }} alt="test" /></div>


                                </div>
                            </BigPicture>

                        </div>

                    </div>
                </div>

            )
        }
        else if (item.type === 'embed') {
            return (
                <div className="card horizontal">

                    <div className="card-stacked">
                        <div className="card-content">
                            <div className="embed" dangerouslySetInnerHTML={{ __html: item.name }} />
                        </div>

                    </div>
                </div>

            )
        }
        else if (item.type === 'weblink') {
            return (
                <div className="card horizontal">

                    <div className="card-stacked">
                        <div className="card-content text-center">
                            <a href={item.name} target="_blank" rel="noopener noreferrer" style={{
                                padding: '8px 20px', display: 'inline-block', color: '#fff',
                                background: '#005284',
                                borderRadius: 3, fontSize: 15, margin: 10
                            }}>Click Here</a>
                            <p>This will Open on a separate tab</p>
                        </div>

                    </div>
                </div>

            )
        }
        else if (item.type === 'pdfs') {


            return (
                <div className="card horizontal">

                    <div className="card-stacked">
                        <div className="card-content">
                            <BigPicture
                                type="iframe"
                                src={item.name}
                            >
                                <div className="flex justify-content-center" style={{ position: 'relative' }}>
                                    {item.preview ? <img src={item.preview} className="img-responsive" style={{ width: 320, height: 400 }} alt="test" /> : <img src={require('../images/black.jpg')} className="img-responsive" style={{ width: '100%', height: 200 }} alt="test" />
                                    }
                                    <div className="overlay"> <img src={require('../images/pdf.png')} style={{ width: '70px' }} alt="test" /></div>


                                </div>
                            </BigPicture>
                            {/* <iframe src={item.name} width='100%' height="400px" title="red" allowFullScreen/> */}


                        </div>

                    </div>
                </div>

            )
        }
        else if (item.type === 'audio') {
            return (
                <div className="card horizontal">

                    <div className="card-stacked">
                        <div className="card-content">
                            <BigPicture
                                type="audio"
                                src={item.name}
                            >
                                <div className="flex justify-content-center">
                                    <img src={require('../images/1200px-Speaker_Icon.png')} style={{ width: '70px' }} alt="test" />
                                </div>
                            </BigPicture>
                            {/* <audio controls>
                                <source src={item.name}></source>
                            </audio> */}
                        </div>

                    </div>
                </div>

            )
        }
        else if (item.type === 'userimage') {
            return (
                <div className="card horizontal row" >
                    {item.name.match('be downloaded from this') ?
                        <p> {item.name}
                            {item.name.match('Upload a list of workers') ?

                                <a href={require('../images/ContractorListing.xlsx')}>link</a>

                                :
                                <a href={require('../images/MethodStatement.docx')}>link</a>}  </p>
                        :

                        <p>{item.name}</p>

                    }

                    {item.allfiles && item.allfiles.map((item1, i) => {
                        return (
                            <>
                                {item1.filetype === 'application/pdf' ?

                                    <div className='col-md-3'>
                                        <i className="material-icons" onClick={() => this.removeAlreadyAttach(item, i)} >close</i>
                                        <div className="card-stacked">
                                            <div className="card-content">
                                            {this.webview() && window.innerWidth >= 800?
                                               <BigPicture
                                                   type="iframe"
                                                   src={"https://mpower-s3.s3.ap-southeast-1.amazonaws.com/uploads/multimedia/" + this.replaceFun(item1.value)}
                                               >
                                                   <div className="flex justify-content-center" style={{ position: 'relative',flexDirection:'column' }}>

                                                       <img src={require('../images/pdf.png')} style={{ width: '70px' }} alt="test" />
                                                   <p>{item1.value}</p>

                                                   </div>
                                               </BigPicture>
                                               :
                                                 <a href ={"https://mpower-s3.s3.ap-southeast-1.amazonaws.com/uploads/multimedia/" + this.replaceFun(item1.value)} download className="flex justify-content-center" style={{ position: 'relative',flexDirection:'column' }} >
    
                                                    <img src={require('../images/pdf.png')} style={{ width: '70px' }} alt="test" />
                                                <p>{item1.value}</p>

                                                </a>
                                               }


                                            </div></div>
                                    </div>
                                    : item1.filetype.match('image') ?

                                        <div className='col-md-3'>
                                            <i className="material-icons" onClick={() => this.removeAlreadyAttach(item, i)} >close</i>
                                            <div className="card-stacked">
                                                <div className="card-content">
                                                    <BigPicture
                                                        type="image"
                                                        src={"https://mpower-s3.s3.ap-southeast-1.amazonaws.com/uploads/multimedia/" + this.replaceFun(item1.value)}
                                                    >
                                                        <div className="flex justify-content-center" style={{ position: 'relative', flexDirection: 'column' }}><img src={"https://mpower-s3.s3.ap-southeast-1.amazonaws.com/uploads/multimedia/" + item1.value.replace(/-| /g, "_")} alt="step" className="img-responsive" />
                                                            <p style={{ fontSize: 10 }}>{item1.value}</p>
                                                        </div>
                                                    </BigPicture>
                                                </div></div>
                                        </div>
                                        : item1.filetype.match('video') ?

                                            <div className='col-md-3'>
                                                <i className="material-icons" onClick={() => this.removeAlreadyAttach(item, i)} >close</i>
                                                <div className="card-stacked">
                                                    <div className="card-content">

                                                        <BigPicture
                                                            type="video"
                                                            src={"https://mpower-s3.s3.ap-southeast-1.amazonaws.com/uploads/multimedia/" + this.replaceFun(item1.value)}
                                                        >
                                                            <div className="flex justify-content-center" style={{ position: 'relative', flexDirection: 'column' }}>
                                                                <img src={require('../images/video.png')} alt="step" className="img-responsive" />
                                                                <p style={{ fontSize: 10 }}>{item1.value}</p>
                                                            </div>
                                                        </BigPicture>
                                                    </div></div>
                                            </div>
                                            : item1.value.match('xls') ?

                                                <div className='col-md-3'>
                                                    <i className="material-icons" onClick={() => this.removeAlreadyAttach(item, i)} >close</i>
                                                    <div className="card-stacked">
                                                        <div className="card-content">
                                                            <a className="flex align-items-center justify-content-center" href={"https://mpower-s3.s3.ap-southeast-1.amazonaws.com/uploads/multimedia/" + this.replaceFun(item1.value)}>

                                                                <div style={{ textAlign: 'center' }}>
                                                                    <img src={require('../images/excel.png')} style={{ width: 50 }} alt="step" className="img-responsive" />
                                                                    <p style={{ fontSize: 10 }}>{item1.value}</p>
                                                                </div>
                                                            </a>

                                                        </div></div>
                                                </div>
                                                : item1.value.match('.doc') ?

                                                    <div className='col-md-3'>
                                                        <i className="material-icons" onClick={() => this.removeAlreadyAttach(item, i)} >close</i>
                                                        <div className="card-stacked">
                                                            <div className="card-content">
                                                                <a className="flex align-items-center justify-content-center" href={"https://mpower-s3.s3.ap-southeast-1.amazonaws.com/uploads/multimedia/" + this.replaceFun(item1.value)}>

                                                                    <div style={{ textAlign: 'center' }}>
                                                                        <img src={require('../images/word.png')} style={{ width: 50 }} alt="step" className="img-responsive" />
                                                                        <p style={{ fontSize: 10 }}>{item1.value}</p>
                                                                    </div>
                                                                </a>

                                                            </div></div>
                                                    </div>
                                                    : ''}
                            </>
                        )

                    }
                    )}
                    <div className="form-group multi-preview row">
                        {item.files ?
                            item.files.map((url, i) => {
                                if (url[0].type === 'application/pdf') {
                                    return (
                                        <div className="col-3">
                                            <i className="material-icons" onClick={() => this.removeAttach(url, item)} >close</i>
                                            {this.webview() && window.innerWidth >= 800? 
                                                    <BigPicture
                                                        type="iframe"
                                                        src={URL.createObjectURL(url[0])}
                                                    >
                                                        <div className="flex  justify-content-center align-items-center" style={{ position: 'relative', flexDirection: 'column' }}>

                                                            <img src={require('../images/pdf.png')} style={{ width: '70px' }} alt="test" />
                                                            <p style={{ fontSize: 10 }}>{url[0].name}</p>

                                                        </div>
                                                    </BigPicture>
                                                    :
                                                 <a href ={URL.createObjectURL(url[0])} download className="flex justify-content-center" style={{ position: 'relative',flexDirection:'column' }} >
    
                                                    <img src={require('../images/pdf.png')} style={{ width: '70px' }} alt="test" />
                                                <p>{url[0].name}</p>

                                                </a>
                                               }
                                        </div>
                                    )
                                } else if (url[0].type.match('image')) {
                                    return (
                                        <div className="col-3">
                                            <i className="material-icons" onClick={() => this.removeAttach(url, item)} >close</i>
                                            <BigPicture
                                                type="image"
                                                src={URL.createObjectURL(url[0])}
                                            >
                                                <div className="flex align-items-center justify-content-center" style={{ position: 'relative', flexDirection: 'column' }}>
                                                    <img src={URL.createObjectURL(url[0])} alt="step" style={{ width: 50 }} className="img-responsive" />
                                                    <p style={{ fontSize: 10 }}>{url[0].name}</p>
                                                </div>
                                            </BigPicture>
                                        </div>
                                    )
                                } else if (url[0].type.match('video')) {
                                    return (

                                        <div className="col-3">
                                            <i className="material-icons" onClick={() => this.removeAttach(url, item)} >close</i>
                                            <BigPicture
                                                type="video"
                                                src={URL.createObjectURL(url[0])}
                                            >
                                                <div className="flex align-items-center justify-content-center" style={{ position: 'relative', flexDirection: 'column' }}>
                                                    <img src={require('../images/video.png')} style={{ width: 50 }} alt="step" className="img-responsive" />
                                                    <p style={{ fontSize: 10 }}>{url[0].name}</p>
                                                </div>
                                            </BigPicture>
                                        </div>
                                    )
                                } else if (url[0].name.match('xls')) {
                                    return (
                                        <div className="col-3">
                                            <i className="material-icons" onClick={() => this.removeAttach(url, item)} >close</i>
                                            <a className="flex align-items-center justify-content-center" href={URL.createObjectURL(url[0])} download={url[0].name}>

                                                <div style={{ textAlign: 'center' }}>
                                                    <img src={require('../images/excel.png')} style={{ width: 50 }} alt="step" className="img-responsive" />
                                                    <p style={{ fontSize: 10 }}>{url[0].name}</p>
                                                </div>
                                            </a>
                                        </div>
                                    )
                                } else if (url[0].name.match('.doc')) {
                                    return (
                                        <div className="col-3">
                                            <i className="material-icons" onClick={() => this.removeAttach(url, item)} >close</i>
                                            <a className="flex align-items-center justify-content-center" href={URL.createObjectURL(url[0])} download={url[0].name}>

                                                <div style={{ textAlign: 'center' }}>
                                                    <img src={require('../images/word.png')} style={{ width: 50 }} alt="step" className="img-responsive" />
                                                    <p style={{ fontSize: 10 }}>{url[0].name}</p>
                                                </div>
                                            </a>
                                        </div>
                                    )
                                }
                            }) : ''}
                    </div>

                    <div className="form-group" style={{ textAlign: 'center' }}>
                        <label for={'test_' + index} style={{ border: '1px solid #0c0c0c', width: '100%', padding: 10 }}><i style={{ fontSize: 30 }} class="material-icons">
                            add
                        </i></label>
                        <input type="file" style={{ display: 'none' }} className="form-control" id={'test_' + index} key={this.state.theInputKey || ''} onChange={(e) => this.uploadMultipleFiles(e, item, index)} />
                    </div>
                </div>
            )

        }
        else if (item.type === 'useraudio') {
            return (
                <div className="card horizontal" style={{ border: this.state.requiredCheck === true ? '1px solid #fff' : item.required === "1" ? item.value === '' ? '1px solid red' : '1px solid #fff' : '1px solid #fff' }}>

                    <div className="card-stacked">
                        <div className="card-content">
                            <p>{item.name}</p>
                            <div className="flex justify-content-end" style={{ position: 'relative' }}>
                                {item.value === '' ? '' :
                                    <img src={require('../images/clear.png')} onClick={() => this.removeSign(item, index)} className="img-responsive" style={{
                                        width: 12, position: 'absolute',
                                        top: 4,
                                        right: 4
                                    }} alt="demo" />}
                            </div>
                            <div className="flex justify-content-center" style={{ border: '1px solid', padding: 10 }}>
                                {item.value === '' ?
                                    <img onClick={() => this.takeAudio(item, index)} src={require('../images/microphone.png')} alt="camera" />
                                    : <BigPicture
                                        type="audio"
                                        src={item.value}
                                    >
                                        <div className="flex justify-content-center">
                                            <img src={require('../images/1200px-Speaker_Icon.png')} style={{ width: '70px' }} alt="test" />
                                        </div>
                                    </BigPicture>}
                            </div>


                        </div>

                    </div>
                </div>

            )
        }
        else if (item.type === 'uservideo') {

            return (
                <div className="card horizontal" >

                    <div className="card-stacked">
                        <div className="card-content">
                            <p>{item.name}</p>
                            <div className="flex justify-content-end" style={{ position: 'relative' }}>
                                {item.value === '' ? '' :
                                    <img src={require('../images/clear.png')} onClick={() => this.removeSign(item, index)} className="img-responsive" style={{
                                        width: 12, position: 'absolute',
                                        top: 4,
                                        right: 4
                                    }} alt="demo" />}
                            </div>
                            <div className="flex justify-content-center" style={{ border: '1px solid', padding: 10 }}>
                                {item.value === '' ?
                                    <img onClick={() => this.takeVideo(item, index)} src={require('../images/video.png')} alt="camera" />
                                    :
                                    <FancyVideo
                                        source={item.value}
                                        poster={require('../images/white.jpg')}
                                        id={Math.random().toString(36).substring(7)} />
                                    // <video controls controlsList="nodownload" style={{ width: '100%' }}>
                                    //     <source src={item.value}></source>
                                    // </video>
                                }
                            </div>
                        </div>

                    </div>
                </div>

            )
        }
        else if (item.type === 'textbox') {
            return (
                <div className="card horizontal" style={{ border: this.state.requiredCheck === true ? '1px solid #D3D3D3' : item.required === "1" ? item.value === '' ? '1px solid red' : '1px solid #D3D3D3' : '1px solid #D3D3D3' }}>

                    <div className="card-stacked">
                        <div className="card-content">
                            <p>{item.name}</p>
                            <textarea rows="6" style={{ width: "100%", borderColor: this.state.requiredCheck === true ? '#D3D3D3' : item.required === "1" ? item.value === '' ? 'red' : '#D3D3D3' : '#D3D3D3' }} onChange={(event) => this.textInputInfo(event, index)}>{item.value}</textarea>
                        </div>

                    </div>
                </div>

            )
        }
        else if (item.type === 'poll') {
            return (
                <div className="card horizontal" style={{ border: this.state.requiredCheck === true ? '1px solid #fff' : this.pollValueCheck(item.options) === false ? '1px solid red' : '1px solid #fff' }}>

                    <div className="card-stacked">
                        <div className="card-content">
                            <p>{item.value}</p>
                            {item.options.map((data, i) => {
                                return (
                                    <div className="flex align-items-center" onClick={() => this.checkBoxAction(data, item, i)}>
                                        <div style={{ backgroundColor: data.checked ? data.name === 'Yes' ? '#be1f24' : data.name === 'No' ? ' #1bbc9b' : '#ffa500' : '#fff', borderRadius: 20, padding: 5, display: 'flex', alignItems: 'center', marginRight: 10 }}>
                                            {this.checkbox_icon(item.is_multiselect, data.checked)}
                                        </div>
                                        <div >
                                            {data.name}
                                        </div>

                                    </div>

                                )
                            })}

                            <div className="mt-3">
                                <textarea rows="1" placeholder="Any Remarks" value={item.remarks} style={{ width: "100%" }} onChange={(event) => item.remarks = event.target.value}></textarea>
                            </div>

                        </div>

                    </div>
                </div>

            )
        }
        else if (item.type === 'multimedia') {
            if (item.filetype === 'application/pdf') {
                return (
                    <div className="card horizontal" >

                        <div className="card-stacked">
                            <div className="card-content">
                                <BigPicture
                                    type="iframe"
                                    src={"https://mpower-s3.s3.ap-southeast-1.amazonaws.com/uploads/multimedia/" + item.value.replace(/-| /g, "_")}
                                >
                                    <div className="flex justify-content-center" style={{ position: 'relative' }}>

                                        <img src={require('../images/pdf.png')} style={{ width: '70px' }} alt="test" />


                                    </div>
                                </BigPicture>
                            </div></div></div>
                )
            } else if (item.filetype.match('image')) {
                return (
                    <div className="card horizontal" >

                        <div className="card-stacked">
                            <div className="card-content">
                                <BigPicture
                                    type="image"
                                    src={"https://mpower-s3.s3.ap-southeast-1.amazonaws.com/uploads/multimedia/" + item.value.replace(/-| /g, "_")}
                                >
                                    <div className="flex justify-content-center"><img src={"https://mpower-s3.s3.ap-southeast-1.amazonaws.com/uploads/multimedia/" + item.value.replace(/-| /g, "_")} alt="step" className="img-responsive" /></div>
                                </BigPicture>
                            </div></div></div>
                )
            } else if (item.filetype.match('video')) {
                return (
                    <div className="card horizontal" >

                        <div className="card-stacked">
                            <div className="card-content">

                                <BigPicture
                                    type="video"
                                    src={"https://mpower-s3.s3.ap-southeast-1.amazonaws.com/uploads/multimedia/" + item.value.replace(/-| /g, "_")}
                                >
                                    <div className="flex justify-content-center">
                                        <img src={require('../images/video.png')} alt="step" className="img-responsive" /></div>
                                </BigPicture>
                            </div></div></div>
                )
            }
        }
        else if (item.type === 'mcq') {
            return (
                <div className="card horizontal" style={{ border: this.state.requiredCheck === true ? '1px solid #fff' : this.mcqValueCheck(item.options) === false ? '1px solid red' : '1px solid #fff' }}>

                    <div className="card-stacked">
                        <div className="card-content">
                            <p>{item.value}</p>
                            {item.options.map((data, indexMCQ) => {
                                return (
                                    <div className="flex" onClick={() => this.MCQAction(data, item, indexMCQ, index)}>
                                        <div >
                                            {this.checkbox_icon(item.is_multiselect, data.imcaChecked)}
                                        </div>
                                        <div >
                                            {data.name}
                                        </div>

                                    </div>
                                )
                            })}
                            {

                                this.mcqValueCheck(item.options) === false ?
                                    null
                                    : <div className="flex justify-content-center mt-2">
                                        {item.insight_options.map((data, i) => {
                                            return (
                                                <div style={{ borderRadius: 25, backgroundColor: data.checked === '1' ? (data.name === 'guess' ? '#005188' : '#005188') : '#e8e8e8' }} onClick={() => this.insightAction(data, item, i, index)}>
                                                    <div style={{ padding: "2px 10px", fontSize: 14, color: data.checked === '1' ? '#fff' : '#000', fontWeight: data.checked === '1' ? '500' : '300' }}> {data.name === 'sure' ? " I am sure " : " I am not sure "}</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                            }
                        </div>

                    </div>
                </div>

            )
        }
        else if (item.type === 'sign') {
            return (
                <div className="card horizontal" style={{ border: this.state.requiredCheck === true ? '1px solid #fff' : item.required === "1" ? item.value === '' ? '1px solid red' : '1px solid #fff' : '1px solid #fff' }}>

                    <div className="card-stacked">
                        <div className="card-content">
                            <p>{item.name}</p>
                            <div className="flex justify-content-end" style={{ position: 'relative' }}>
                                {item.value === '' ? '' :
                                    <img src={require('../images/clear.png')} onClick={() => this.removeSign(item, index)} className="img-responsive" style={{
                                        width: 12, position: 'absolute',
                                        top: 4,
                                        right: 4
                                    }} alt="demo" />}
                            </div>
                            <div className="flex justify-content-center" style={{ border: '1px solid', padding: 10 }}>
                                {item.value === '' ?

                                    <img onClick={() => this.takeSign(item, index)} src={require('../images/signature.png')} className="img-responsive" style={{ width: 70 }} alt="camera" />
                                    :
                                    item.new === 1 ? <img src={item.value} alt="camera" className="img-responsive" /> : <img src={'https://mpower-s3.s3.amazonaws.com/uploads/input_sign/' + item.value} alt="camera" className="img-responsive" />
                                }
                            </div>


                        </div>
                    </div></div>
            )
        }

    }

    _back(i) {
        this.setState({ currentStep: i - 1 });
        window.scrollTo({
            top: 0
        })
    }
    _next(i, total) {
        this.setState({
            requiredCheck: true
        })
        this.dynamicCheckFunction(this.props.data[this.state.currentStep].microstep_data)
        this.props.data[1].microstep_data[12].value = '';
        this.props.data[1].microstep_data[9].value = '';
        this.props.data[1].microstep_data[10].value = '';
        this.props.data[1].microstep_data[11].allfiles = [];
        setTimeout(() => {
            if (this.state.requiredCheck === true) {
                if (i !== total - 1) {
                    this.setState({ currentStep: i + 1 });
                }
                if (i === total - 1) {

                    // this.alertAction()
                    this.genaratePermitID();
                }

            } else {
                alert('Please fill all the required fields')
            }
        }, 100)
        window.scrollTo({
            top: 0
        })

    }
    async dynamicCheckFunction(tt) {
        // console.log("Dynamic check func", tt)
        //  let textboxData = tt.filter(data => data.type === "textbox" && data.required === "1")
        //   if (textboxData.length === 0) {
        //     this.setState({
        //       requiredCheck: true
        //     })
        //   } else {
        //     console.log(textboxData)
        //     if (textboxData.some(data => data.value === "")) {
        //       this.setState({
        //         requiredCheck: false
        //       })
        //     } else {
        //       this.setState({
        //         requiredCheck: true
        //       })
        //     }
        //   }

        //   let pollData = tt.filter(data => data.type === "poll" && data.required === "1")
        //   if (pollData.length === 0) {
        //     this.setState({
        //       requiredCheck: true
        //     })
        //   } else {
        //     console.log(pollData)
        //     if (pollData.some(data => data.user_ans === "")) {
        //       this.setState({
        //         requiredCheck: false
        //       })
        //     } else {
        //       this.setState({
        //         requiredCheck: true
        //       })
        //     }
        //   }

        //   let imageData = tt.filter(data => data.type === "userimage" && data.required === "1")
        //   if (imageData.length === 0) {
        //     this.setState({
        //       requiredCheck: true
        //     })
        //   } else {
        //     // console.log(imageData)
        //     if (imageData.some(data => data.value === "")) {
        //       this.setState({
        //         requiredCheck: false
        //       })
        //     } else {
        //       this.setState({
        //         requiredCheck: true
        //       })
        //     }
        //   }

        //   let signData = tt.filter(data => data.type === "sign" && data.required === "1")
        //   if (signData.length === 0) {
        //     this.setState({
        //       requiredCheck: true
        //     })
        //   } else {
        //     // console.log(signData)
        //     if (signData.some(data => data.value === "")) {
        //       this.setState({
        //         requiredCheck: false
        //       })
        //     } else {
        //       this.setState({
        //         requiredCheck: true
        //       })
        //     }
        //   }
        // //   let location = tt.filter(data => data.type === "location")
        // //   if (location.some(data => data.value === "")) {
        // //     this.setState({
        // //       requiredCheck: false
        // //     })
        // //   } else {
        // //     this.setState({
        // //       requiredCheck: true
        // //     })
        // //   }
        //   let project = tt.filter(data => data.type === "project")
        //   if (project.some(data => data.value === "")) {
        //     this.setState({
        //       requiredCheck: false
        //     })
        //   } else {
        //     this.setState({
        //       requiredCheck: true
        //     })
        //   }

        await tt.forEach((item, index) => {
            if (item.type === 'textbox') {
                if (item.required === "1") {
                    if (item.value === '') {
                        this.setState({
                            requiredCheck: false
                        })
                    } else {
                        // this.setState({
                        //     requiredCheck: true
                        // })

                    }
                }
            }
            else if (item.type === 'sign') {
                if (item.required === "1") {
                    if (item.value === '') {
                        this.setState({
                            requiredCheck: false
                        })
                    } else {
                        // console.log("5")
                        // this.setState({
                        //     requiredCheck: true
                        // })
                    }
                }
            } else if (item.type === 'start_time') {
                if (item.required === "1") {
                    if (item.value === '') {
                        this.setState({
                            requiredCheck: false
                        })
                    } else {
                        // console.log("5")
                        // this.setState({
                        //     requiredCheck: true
                        // })
                    }
                }
            }
            else if (item.type === 'end_time') {
                if (item.required === "1") {
                    if (item.value === '') {
                        this.setState({
                            requiredCheck: false
                        })
                    } else {
                        // console.log("5")
                        // this.setState({
                        //     requiredCheck: true
                        // })
                    }
                }
            }
            else if (item.type === 'location') {
                if (item.required === "1") {
                    if (item.value === '') {
                        this.setState({
                            requiredCheck: false
                        })
                    } else {
                        // console.log("5")
                        // this.setState({
                        //     requiredCheck: true
                        // })
                    }
                }
            }
            else if (item.type === 'userimage') {

                if (item.required === "1") {
                    if (item.allfiles.length === 0) {
                        if (!item.files) {
                            this.setState({
                                requiredCheck: false
                            })
                        }
                    } else {

                    }

                }
            }
            else if (item.type === 'poll') {
                if (item.required === "1") {
                    if (item.options.some((data) => data.checked === 1)) {
                        // this.setState({
                        //     pollBox: { borderColor: null, borderWidth: 0 },
                        //     requiredCheck:true
                        // })
                    } else {
                        this.setState({
                            requiredCheck: false
                        })
                    }
                } else {
                    // this.setState({
                    //     requiredCheck:true
                    // })

                }


            }
            else if (item.type === 'checkpoint') {
                if (item.required === "1") {
                    if (item.options.some((data) => data.checked === 1)) {
                        // this.setState({
                        //     requiredCheck:true
                        // })
                    } else {
                        this.setState({
                            requiredCheck: false
                        })
                    }
                } else {
                    // this.setState({
                    //     requiredCheck:true
                    // })
                }
            }
            else if (item.type === 'mcq') {
                // if (item.required == "1") {        
                if (item.options.some((data) => { return data.imcaChecked === 1 })) {
                    // this.setState({
                    //     requiredCheck: true
                    // })
                } else {
                    this.setState({
                        requiredCheck: false
                    })
                }

            }
            else if (item.type === 'uservideo') {
                if (item.required === "1") {
                    if (item.value === '') {
                        this.setState({

                            requiredCheck: false
                        })

                    } else {

                    }

                }
            }
            else if (item.type === 'useraudio') {
                if (item.required === "1") {
                    if (item.value === '') {
                        this.setState({
                            requiredCheck: false
                        })

                    } else {

                    }

                }
            }

        })
        //   let date = tt.filter(data => data.type === "date")
        //   if (date.some(data => data.value === "")) {
        //     this.setState({
        //       requiredCheck: false
        //     })
        //   } else {
        //     this.setState({
        //       requiredCheck: true
        //     })
        //   }
        //   let start_time = tt.filter(data => data.type === "start_time")
        //   if (start_time.some(data => data.value === "")) {
        //     this.setState({
        //       requiredCheck: false
        //     })
        //   } else {
        //     this.setState({
        //       requiredCheck: true
        //     })
        //   }
        //   let end_time = tt.filter(data => data.type === "end_time")
        //   if (end_time.some(data => data.value === "")) {
        //     this.setState({
        //       requiredCheck: false
        //     })
        //   } else {
        //     this.setState({
        //       requiredCheck: true
        //     })
        //   }
        //   let job_site = tt.filter(data => data.type === "job_site")
        //   if (job_site.some(data => data.value === "")) {
        //     this.setState({
        //       requiredCheck: false
        //     })
        //   } else {
        //     this.setState({
        //       requiredCheck: true
        //     })
        //   }
        //   let workers = tt.filter(data => data.type === "worker")
        //   if (workers.some(data => data.value === "0")) {
        //     this.setState({
        //       requiredCheck: false
        //     })
        //   } else {
        //     this.setState({
        //       requiredCheck: true
        //     })
        //   }
        //   let description = tt.filter(data => data.type === "description")
        //   if (description.some(data => data.value === "")) {
        //     this.setState({
        //       requiredCheck: false
        //     })
        //   } else {
        //     this.setState({
        //       requiredCheck: true
        //     })
        //   }


    }
    genaratePermitID() {
        let formData2 = new FormData();
        formData2.append('contractor_id', this.props.location.state.contractorid)
        axios({
            method: 'post',
            url: API.getContractorCount,
            data: formData2,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            mode: 'no-cors',
        })
            .then(res => {
                console.log(res)
                if (this.state.project === '') {
                    this.setState({ permitID: moment(new Date()).format('YYMMDD') + '-' + this.props.data[0].microstep_data[1].value.substr(this.props.data[0].microstep_data[1].value.length - 4) + '-' + this.props.location.state.slug + '-' + (res.data.count++) })
                } else {
                    this.setState({ permitID: moment(new Date()).format('YYMMDD') + '-' + this.state.project.substr(this.state.project.length - 4) + '-' + this.props.location.state.slug + '-' + (res.data.count++) })
                }


                this.alertAction();

            })

    }
    acuizenSubmitAction() {


        this.setState({
            loading: true,
            submitStatus: 3
        })
        let formData = new FormData();
        if (this.state.setAssessor === '') {
            formData.append('ass_id', this.props.assessor)
        } else {
            formData.append('ass_id', this.state.setAssessor)
        }
        formData.append('session_id', this.props.session_id)
        formData.append('contractor_name', this.props.history.location.state.contractor_name)
        formData.append('permit_id', this.props.permit)
        formData.append('contractor_dri', localStorage.getItem('firstname'))


        axios({
            method: 'post',
            url: API.SetAssessorAction,
            data: formData,
            headers: {
                'content-type': 'multipart/form-data'

            },
            mode: 'no-cors',
        })
            .then(res => {
                this.submitButtonApi(this.props.data, this.props.data)

            })

        //this.submitButtonApi(this.props.data, this.props.data)
    }

    getFilename = (dataUrl) => {
        let filename = Math.random().toString(36).replace('0.', 'image_' || '');
        const arr = dataUrl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];

        return filename + '.' + mime.split('/').pop();
    }
    dataURItoFile = (dataURI, filename) => {
        var byteString = atob(dataURI.split(',')[1]);
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
        var dw = new DataView(ab);
        for (var i = 0; i < byteString.length; i++) {
            dw.setUint8(i, byteString.charCodeAt(i));
        }

        // write the ArrayBuffer to a blob, and you're done
        return new File([ab], filename, { type: mimeString });
    }
    submitButtonApi(response, element) {
        var req = []
        var req1 = []
        var req2 = []
        var multi = []
        var userImageIndex = 0
        var userSignIndex = 0
        var userAudioIndex = 0
        var userVideoIndex = 0
        // if (this.state.file.length > 0) {
        //     this.state.file.forEach((next, i) => {
        //         let obj2 = {
        //             [`step_id[${element[1].microstep_id}][multimedia][${i}]`]: next

        //         }
        //         multi.push(obj2)

        //         element[1].microstep_data.push({ 'type': 'multimedia', 'value': next[0].name, 'filetype': next[0].type })
        //     })
        // }
        element[0].microstep_data.forEach((ele, ind) => {

            if (ele.type === 'userimage') {
                if (!ele.allfiles) {
                    ele.allfiles = [];
                }
                if (ele.files) {

                    if (ele.files.length > 0) {
                        ele.files.forEach((next, i) => {
                            let obj2 = {
                                [`step_id[${element[0].microstep_id}][multimedia][${userImageIndex}]`]: next

                            }
                            multi.push(obj2)

                            ele.allfiles.push({ 'type': 'multimedia', 'value': next[0].name, 'filetype': next[0].type })
                            userImageIndex = userImageIndex + 1;
                        })
                    }
                    delete ele.files
                }

            } else if (ele.type === 'sign') {

                if (ele.value === '') {

                } else {
                    if (ele.value.match('data:image/png;base64')) {
                        let filename = this.getFilename(ele.value)
                        let obj = {
                            [`step_id[${element[0].microstep_id}][sign][${userSignIndex}]`]: ele.value,
                            filename: filename
                        }
                        ele.value = filename
                        req.push(obj)
                        userSignIndex = userSignIndex + 1
                    }
                }
                delete ele.new

            } else if (ele.type === 'useraudio') {

                if (ele.value === '') {

                } else {
                    let filename = new Date() + '.mp3';
                    let obj1 = {
                        [`step_id[${element[0].microstep_id}][input_audio][${userAudioIndex}]`]: ele.blob,
                        filename: filename
                    }

                    console.log(filename);
                    ele.value = filename
                    req1.push(obj1)
                    userAudioIndex = userAudioIndex + 1
                    delete ele.blob
                }
            }
            // else if (ele.type === 'uservideo') {

            //     if (ele.value === '') {

            //     } else {
            //         let filename = new Date('dd-mm-yyyyhh:mm') + '.mp4';
            //         let obj2 = {
            //             [`step_id[${element[0].microstep_id}][input_video][${userVideoIndex}]`]: ele.blob,
            //             filename: filename
            //         }
            //         console.log(filename);
            //         ele.value = filename
            //         req2.push(obj2)
            //         userVideoIndex = userVideoIndex + 1
            //         delete ele.blob
            //     }
            // }

        })
        userImageIndex = 0
        userSignIndex = 0
        userAudioIndex = 0
        userVideoIndex = 0

        console.log(req)
        setTimeout(() => {
            this.uploadApiCallAction(req, req1, req2, multi, response, element)
        }, 1000)
    }
    async uploadApiCallAction(req, req1, req2, multi, data, page) {
        // console.log(page);
        // console.log(page[0].microstep_data[1].value)
        let formData = new FormData();
        if (multi.length >= 0) {
            multi.forEach((element, index) => {
                console.log(element)
                var key = Object.keys(element)[0]
                console.log(key)
                formData.append(`${key}`, element[key][0])
            })
        }
        if (req.length >= 0) {
            req.forEach((element, index) => {
                console.log(element)
                var key = Object.keys(element)[0]
                var key1 = Object.keys(element)[1]
                var filename = element[key1]
                var value = this.dataURItoFile(element[key], filename);
                console.log(value)
                formData.append(`${key}`, value)
            })
        }
        if (req1.length >= 0) {
            req1.forEach((element, index) => {
                console.log(element)
                var key = Object.keys(element)[0]
                var key1 = Object.keys(element)[1]
                var filename = element[key1]
                var value = new File([element[key]], filename, { type: 'audio/mp3', lastModified: Date.now() });
                console.log(value)
                formData.append(`${key}`, value)
            })
        }
        if (req2.length >= 0) {
            req2.forEach((element, index) => {
                console.log(element)
                var key = Object.keys(element)[0]
                var key1 = Object.keys(element)[1]
                var filename = element[key1]
                var value = new File([element[key]], filename, { type: 'video/mp4', lastModified: Date.now() });
                console.log(value)
                formData.append(`${key}`, value)
            })
        }

        formData.append(
            'user_id',
            localStorage.getItem('token')
        )

        formData.append('input_json', JSON.stringify(data))
        formData.append(
            'session_id',
            this.props.session_id
        )
        // formData.append('lat', latitude)
        // formData.append('lon', longitude)
        formData.append(
            'status', "1"
        )
        formData.append('token_id', localStorage.getItem('token')
        )
        formData.append('step_id', page[0].microstep_id)
        formData.append('duration', this.state.seconds)
        if (this.state.project === '') {
            formData.append('project', page[0].microstep_data[1].value)
        } else {
            formData.append('project', this.state.project)
        }
        formData.append('location', '')
        formData.append('permit_date', moment(new Date()).format('L'))
        formData.append('start_time', page[0].microstep_data[2].value)
        formData.append('end_time', page[0].microstep_data[3].value)
        // var lat = page[0].microstep_data[5].value.latitude
        // var lon = page[0].microstep_data[5].value.longitude
        // var latLon = lat+","+lon
        formData.append('new_permit_id', this.props.permit)
        formData.append('job_site', page[0].microstep_data[5].value)
        // formData.append('worker', page[0].microstep_data[6].value)
        // formData.append('supervisor', page[0].microstep_data[7].value) 
        formData.append('description', '')
        axios({
            method: 'post',
            url: API.SubmitUnit,
            data: formData,
            headers: {
                'content-type': 'multipart/form-data'

            },
            mode: 'no-cors',
        })
            .then(res => {
                this.setState({ loading: false })
                this.callAssignedApi()

            })

    }
    userID() {
        const userID = localStorage.getItem('token');
        const ID = userID.split('@');
        console.log(ID[0])
        return ID[0];
    }
    setApprover(e, item) {
        this.setState({
            setApprover: e.target.value,
            selectedApprover: item.name
        })
    }
    alertAction() {
        console.log(this.state.assessorList)
        // this.genaratePermitID();
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='row'>
                        <div className="col-12" style={{ width: 300, background: '#fff', padding: 20, boxShadow: '0px 0px 12px 3px #dbdbdb' }}>
                            <p>Permit ID : {this.state.permitID}</p>
                            <p className="mb-2">Do you want to submit ?</p>
                            {/* <div className="form-group">
                                {this.state.assessorList.map((item, i) => {
                                    return (
                                        item.id === this.userID() ? '' :
                                            <div className="flex align-items-center">

                                                <input type="radio" name="assessor" value={item.id} onChange={(e) => this.setAssessor(e, item)} style={{ marginRight: 14 }} />{item.name}

                                            </div>
                                    )
                                })}
                            </div> */}
                            <div className="flex justify-content-between">
                                <span style={{ color: 'red', fontSize: 12 }} onClick={onClose}>CANCEL</span>
                                <span style={{ color: '#005284', fontSize: 14 }} onClick={() => { this.acuizenSubmitAction(); onClose(); }} > SUBMIT </span>
                            </div>
                        </div>
                    </div>
                );
            }
        });

    }
    callAssignedApi() {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='row'>
                        <div className="col-12" style={{ width: 300, background: '#fff', padding: 20, boxShadow: '0px 0px 12px 3px #dbdbdb' }}>
                            <h3 className="">AcuiZen</h3>
                            <p>PTW #{this.props.permitID} has been sent to ams OSRAM DRI for actions.</p>
                            <div className="flex justify-content-end">
                                <span style={{ color: '#005284', fontSize: 14 }} onClick={() => { this.props.history.goBack(); this.props.getMictoDataUnmount(); onClose(); }} > OK </span>
                            </div>
                        </div>
                    </div>
                );
            }
        });
    }
    callRerurnApi() {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='row'>
                        <div className="col-12" style={{ width: 300, background: '#fff', padding: 20, boxShadow: '0px 0px 12px 3px #dbdbdb' }}>
                            <h3 className="">AcuiZen</h3>
                            <p>Are you sure you want to return this Permit PTW #{this.props.permit} to Contractor DRI: {this.props.location.state.name}?`</p>
                            <div className="flex justify-content-between">
                                <span style={{ color: 'red', fontSize: 12 }} onClick={onClose}>CANCEL</span>
                                <span style={{ color: '#005284', fontSize: 14 }} onClick={() => { this.returnToApplicant(); onClose(); }} > SUBMIT </span>
                            </div>
                        </div>
                    </div>
                );
            }
        });
    }
    projectSelected(text) {
        if (text !== ' ') {
            if (text === "Other") {
                this.setState({ locationDropValue: text, location: '' })
            } else {
                this.setState({ locationDropValue: text, location: text })
                this.props.data[0].microstep_data[1].value = text
            }
        }
    }
    locationSelected(e, data, index) {
        console.log(data[index - 1].coords)

        if (e.target.value !== ' ') {
            let projectName = data[index - 1].label
            console.log(projectName)



            this.setState({ project: projectName })
            this.props.data[0].microstep_data[0].value = projectName


        }
    }
    returnToApplicant() {
        let approveformData = new FormData()
        approveformData.append('session_id', this.props.session_id)
        approveformData.append('remarks', 'Returned By Assessor')
        approveformData.append('user_type', 'assessor')
        axios({
            method: 'post',
            url: API.ApproverRevoke,
            data: approveformData,
            headers: {
                'content-type': 'multipart/form-data'

            },
            mode: 'no-cors',
        })
            .then(res => {
                this.setState({ loading: false })
                this.callRejectedAlert()

            })


    }
    closeTextModal() {
        this.setState({
            textVisible: false
        })
    }
    uploadFiles(e) {
        console.log(e.target.files)
        let pollData = this.props.data[0].microstep_data.filter(data => data.type === "poll" && data.required === "1")
        let formData = new FormData()
        formData.append('input_file', e.target.files[0])
        axios({
            method: 'post',
            url: API.UploadFile,
            data: formData,
            headers: {
                'content-type': 'multipart/form-data'

            },
            mode: 'no-cors',
        })
            .then(res => {
                console.log(res)
                pollData.forEach(p => {
                    if (p.value.match(this.state.selectFile)) {
                        if (!p.files) {
                            p.files = []
                        }
                        p.files.push("https://mpower-s3.s3.ap-southeast-1.amazonaws.com/" + res.data)
                        this.setState({ files: p.files })
                    }
                    // else if(p.value.match('confined spaces')){
                    //     if(!p.files){
                    //         p.files =[]
                    //     }
                    //     p.files.push("https://mpower-s3.s3.ap-southeast-1.amazonaws.com/"+res.data)
                    //     this.setState({files:p.files})
                    // }else if(p.value.match('working at height')){
                    //     if(!p.files){
                    //         p.files =[]
                    //     }
                    //     p.files.push("https://mpower-s3.s3.ap-southeast-1.amazonaws.com/"+res.data)
                    //     this.setState({files:p.files})
                    // }
                    // else if(p.value.match('scaffolding')){
                    //     if(!p.files){
                    //         p.files =[]
                    //     }
                    //     p.files.push("https://mpower-s3.s3.ap-southeast-1.amazonaws.com/"+res.data)
                    //     this.setState({files:p.files})
                    // }
                    // else if(p.value.match('electrical works')){
                    //     if(!p.files){
                    //         p.files =[]
                    //     }
                    //     p.files.push("https://mpower-s3.s3.ap-southeast-1.amazonaws.com/"+res.data)
                    //     this.setState({files:p.files})
                    // } else if(p.value.match('use of crane')){
                    //     if(!p.files){
                    //         p.files =[]
                    //     }
                    //     p.files.push("https://mpower-s3.s3.ap-southeast-1.amazonaws.com/"+res.data)
                    //     this.setState({files:p.files})
                    // }



                });

            })




    }
    callRejectedAlert() {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='row'>
                        <div className="col-12" style={{ width: 300, background: '#fff', padding: 20, boxShadow: '0px 0px 12px 3px #dbdbdb' }}>
                            <h3 className="">AcuiZen</h3>
                            <p>#{this.props.permit} has been returned to Contractor DRI: {this.props.location.state.name}.</p>
                            <div className="flex justify-content-end">
                                <span style={{ color: '#005284', fontSize: 14 }} onClick={() => { this.props.history.goBack(); this.props.getMictoDataUnmount(); onClose(); }} > OK </span>
                            </div>
                        </div>
                    </div>
                );
            }
        });
    }
    mapClick(e) {

        this.setState({
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
        })

    }
    removeURL(url, i) {
        let pollData = this.props.data[0].microstep_data.filter(data => data.type === "poll" && data.required === "1")
        pollData.forEach(p => {
            if (p.value.match(this.state.selectFile)) {

                p.files.pop(url)
                this.setState({ files: p.files })
            }
        })
    }
    doneMap() {
        const obj = {
            latitude: this.state.lat,
            longitude: this.state.lng
        }
        this.props.data[0].microstep_data[5].value = obj;
        this.setState({ mapvisible: false })
    }
    // uploadMultipleFiles(e) {
    //     this.fileObj.push(e.target.files)
    //     console.log(this.fileObj);
    //     // this.setState({ file: [null] })
    //     // for (let i = 0; i < this.fileObj.length; i++) {
    //     //     this.fileArray.push(URL.createObjectURL(this.fileObj[i][0]))
    //     // }
    //     let randomString = Math.random().toString(36);

    //     this.setState({
    //         theInputKey: randomString
    //     });
    //     this.setState({ file: this.fileObj })
    // }
    render() {

        console.log(this.props)
        console.log(this.state.setAssessor)
        let length = 0;
        let datalength = 0;
        let arr = [0];
        if (this.props.location.state.type === 'assessor') {
            length = 3;
            datalength = (100 / 3);
            arr = [1, 1, 2]
        } else {
            length = 1;
            datalength = (100 / 1);
            arr = [1]
        }
        return (
            <div className="">

                <nav class="navbar navbar-top navbar-expand navbar-dark  border-bottom" style={{
                    position: 'fixed',
                    left: 0,
                    right: 0,
                    top: 0,
                    zIndex: 1
                }}>
                    <div class="container pad_z">
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <div className="col-sm-3 pad_z ">
                                {/* <i className="material-icons back-btn grey-text" onClick={() => this.props.history.goBack()}>arrow_back</i> */}
                            </div>
                            <div className="col-sm-6 text-center pad_z"> <img src={require('../images/logo_new.png')} alt="az_logo" className="responsive-img" style={{ height: 50 }} /></div>
                            <div className="col-sm-3 text-right  pad_z vertical-align justify-content-end">
                                <i class="material-icons" onClick={() => { this.props.getMictoDataUnmount(); this.props.history.goBack(); }}>
                                    close
                                </i></div>

                        </div>
                    </div>
                </nav>
                <div style={{ height: 82 }}></div>

                <div className="container">
                    <div className="row">
                        <div className="col-12 mb-5" >
                            <h4 className="col-12 pad_z mt-1" id="unit_name"> {this.props.title}</h4>
                            <p className="col-12 pad_z mt-1">#{this.props.permit}</p>
                            <div className="col-12 steps pad_z" >
                                {arr.map((item, i) => {

                                    if (this.state.currentStep >= i) {
                                        return (
                                            <span key={i} className={this.state.currentStep === i ? 'active step' : 'done step'} style={{ width: datalength + '%' }}></span>
                                        )
                                    }
                                    else {
                                        return (
                                            <span key={i} className={this.state.currentStep === i ? 'active step' : 'step'} style={{ width: datalength + '%' }}></span>
                                        )
                                    }

                                })}
                            </div>
                            <div className="col-12 pad_z">
                                {this.props.data.map((item, i) => {

                                    return (
                                        <div key={i} className="microstep col-12 pad_z" id={'#' + i} style={{ display: this.state.currentStep === i ? 'block' : 'none' }}>
                                            <h4>{item.microstep_title}</h4>

                                            {this.applicantOption(this.state.projectList, i)}

                                            {item.microstep_data.map((item1, j) => {
                                                return (
                                                    <div key={j} className="col-12 pad_z">
                                                        {this.layout(item1, j, i)}
                                                    </div>
                                                )

                                            }
                                            )}
                                            {/* {i===1 ?
                                             this.props.location.state.type ==='assessor' ? "":
                                              <div className="card horizontal" style={{ border: this.state.requiredCheck === true ? '1px solid #fff' : item.required === "1" ? item.value === '' ? '1px solid red' : '1px solid #fff' : '1px solid #fff' }}>

<div className="card-stacked">
    <div className="card-content">
    <p>Click + to add multiple media files.</p>
    <div className="form-group multi-preview">
                    {this.state.file.map((url,i) => {
                        if(url[0].type ==='application/pdf'){
                    return(
                        <BigPicture
                        type="iframe"
                        src={URL.createObjectURL(url[0])}
                    >
                        <div className="flex justify-content-center" style={{ position: 'relative' }}>
                            
                             <img src={require('../images/pdf.png')} style={{ width: '70px' }} alt="test" />


                        </div>
                    </BigPicture>
                    )
                    }else if(url[0].type.match('image')){
                        return(
                            <BigPicture
                            type="image"
                            src={URL.createObjectURL(url[0])}
                        >
                            <div className="flex justify-content-center"><img src={URL.createObjectURL(url[0])} alt="step" className="img-responsive" /></div>
                        </BigPicture>
                        )
                    }else if(url[0].type.match('video')){
                        return(
                            
                            
                                        <BigPicture
                                        type="video"
                                        src={URL.createObjectURL(url[0])}
                                    >
                                        <div className="flex justify-content-center">
                                            <img src={require('../images/video.png')} alt="step" className="img-responsive" /></div>
                                    </BigPicture>
                                       
                        )
                    }
                     })}
                </div>

                <div className="form-group" style={{textAlign:'center'}}>
                    <label for="test" style={{border:'1px solid #0c0c0c',width:'100%',padding:10}}><i style={{fontSize:30}} class="material-icons">
add
</i></label>
                    <input type="file" style={{display:'none'}} className="form-control" id="test" key={this.state.theInputKey || '' } onChange={(e)=>this.uploadMultipleFiles(e)}  />
                </div>
                
        </div></div></div> : ''} */}
                                            {this.props.location.state.type === 'assessor' ?
                                                <div className="col-12 step-btn ">
                                                    <div className="container flex pad_z">
                                                        {this.state.currentStep === 0 ? '' :
                                                            <button

                                                                type="button" onClick={() => this._back(i)}>
                                                                <i className="material-icons left" style={{ width: 25 }}>arrow_backward</i>
                                                                Back

                                                            </button>}
                                                        {this.state.currentStep === length - 1 ?
                                                            <button
                                                                type="button" className='bg-red' onClick={() => this.callRerurnApi()}>
                                                                Reject & Return to Contractor

                                                            </button>
                                                            : null}

                                                        <button
                                                            type="button" className={this.state.currentStep === length - 1 ? 'bg-green' : ''} onClick={() => this._next(i, length)}>
                                                            {this.state.currentStep === length - 1 ? 'ReSubmit to EHS for Verification' : 'Next'}
                                                            <i className="material-icons right">arrow_forward</i>
                                                        </button>
                                                    </div>
                                                </div>
                                                :
                                                <div className="col-12 step-btn ">
                                                    <div className="container flex pad_z">
                                                        {this.state.currentStep === 0 ? '' :
                                                            <button

                                                                type="button" onClick={() => this._back(i)}>
                                                                <i className="material-icons left" style={{ width: 25 }}>arrow_backward</i>
                                                                Back

                                                            </button>}

                                                        {/* {this.state.currentStep === length - 1 ?
                                               <button
                                                        type="button" className='bg-red'  onClick={() => this.callRerurnApi()}>
                                                         Reject & Return to Contractor
                                                        
                                                    </button>
                                              :null} */}
                                                        <button
                                                            type="button" className={this.state.currentStep === length - 1 ? 'bg-green' : ''} onClick={() => this._next(i, length)}>
                                                            {this.state.currentStep === length - 1 ? 'ReSubmit to ams OSRAM DRI for Verification' : 'Next'}
                                                            <i className="material-icons right">arrow_forward</i>
                                                        </button>
                                                    </div>
                                                </div>
                                            }


                                        </div>
                                    )
                                })}

                            </div>
                        </div>
                    </div>

                </div>
                <Modal
                    size="sm"
                    show={this.state.textVisible}
                    onHide={() => this.closeModalYes()}
                    backdrop='static'
                    centered
                    animation
                    aria-labelledby="example-modal-sizes-title-sm"
                >
                    {this.state.box === true ?
                        <Modal.Header closeButton>

                        </Modal.Header>
                        : ''}
                    <Modal.Body style={{ paddingBottom: 5 }}>
                        <p dangerouslySetInnerHTML={{ __html: this.state.YesText }} />

                        <div className="form-group multi-preview row">
                            {this.state.files.map((url, i) => {
                                return (
                                    <div className="col-3">
                                        <i className="material-icons" onClick={() => this.removeURL(url, i)} >close</i>
                                        <BigPicture
                                            type="iframe"
                                            src={url}
                                        >
                                            <div className="flex  justify-content-center align-items-center" style={{ position: 'relative', flexDirection: 'column' }}>

                                                View File


                                            </div>
                                        </BigPicture>
                                    </div>


                                )
                                // if (url[0].type === 'application/pdf') {
                                //     return (
                                //         <div className="col-3">
                                //             <i className="material-icons" onClick={() => this.removeAttach(url)} >close</i>
                                //             <BigPicture
                                //                 type="iframe"
                                //                 src={URL.createObjectURL(url[0])}
                                //             >
                                //                 <div className="flex  justify-content-center align-items-center" style={{ position: 'relative', flexDirection: 'column' }}>

                                //                     <img src={require('../images/pdf.png')} style={{ width: '70px' }} alt="test" />
                                //                     <p style={{ fontSize: 10 }}>{url[0].name}</p>

                                //                 </div>
                                //             </BigPicture>
                                //         </div>
                                //     )
                                // } else if (url[0].type.match('image')) {
                                //     return (
                                //         <div className="col-3">
                                //              <i className="material-icons" onClick={() => this.removeAttach(url)} >close</i>
                                //             <BigPicture
                                //                 type="image"
                                //                 src={URL.createObjectURL(url[0])}
                                //             >
                                //                 <div className="flex align-items-center justify-content-center" style={{ position: 'relative', flexDirection: 'column' }}>
                                //                     <img src={URL.createObjectURL(url[0])} alt="step" style={{ width: 50 }} className="img-responsive" />
                                //                     <p style={{ fontSize: 10 }}>{url[0].name}</p>
                                //                 </div>
                                //             </BigPicture>
                                //         </div>
                                //     )
                                // } else if (url[0].type.match('video')) {
                                //     return (

                                //         <div className="col-3">
                                //              <i className="material-icons" onClick={() => this.removeAttach(url)} >close</i>
                                //             <BigPicture
                                //                 type="video"
                                //                 src={URL.createObjectURL(url[0])}
                                //             >
                                //                 <div className="flex align-items-center justify-content-center" style={{ position: 'relative', flexDirection: 'column' }}>
                                //                     <img src={require('../images/video.png')} style={{ width: 50 }} alt="step" className="img-responsive" />
                                //                     <p style={{ fontSize: 10 }}>{url[0].name}</p>
                                //                     </div>
                                //             </BigPicture>
                                //         </div>
                                //     )
                                // }else if(url[0].type.match('application/vnd.')){
                                //     return(
                                //     <div className="col-3">
                                //          <i className="material-icons" onClick={() => this.removeAttach(url)} >close</i>
                                //            <a className="flex align-items-center justify-content-center" href={URL.createObjectURL(url[0])} download={url[0].name}>

                                //            <div style={{textAlign:'center'}}>
                                //            <img src={require('../images/excel.png')} style={{ width: 50 }} alt="step" className="img-responsive" />
                                //            <p style={{ fontSize: 10 }}>{url[0].name}</p>
                                //            </div>
                                //      </a> 
                                //         </div>
                                //     )
                                // }else if(url[0].name.match('.doc')){
                                //     return(
                                //     <div className="col-3">
                                //        <i className="material-icons" onClick={() => this.removeAttach(url)} >close</i>
                                //         <a className="flex align-items-center justify-content-center" href={URL.createObjectURL(url[0])} download={url[0].name}>

                                //                 <div style={{textAlign:'center'}}>
                                //                 <img src={require('../images/word.png')} style={{ width: 50 }} alt="step" className="img-responsive" />
                                //                 <p style={{ fontSize: 10 }}>{url[0].name}</p>
                                //                 </div>
                                //           </a>  
                                //         </div>
                                //     )
                                // }

                            })
                            }
                        </div>

                        {this.state.box === true ?
                            <>
                                <div className="form-group" style={{ textAlign: 'center' }}>
                                    <label for={'test_'} style={{ border: '1px solid #0c0c0c', width: '100%', padding: 10 }}><i style={{ fontSize: 30 }} class="material-icons">
                                        add
                                    </i></label>
                                    <input type="file" style={{ display: 'none' }} className="form-control" id={'test_'} key={this.state.theInputKey || ''} onChange={(e) => this.uploadFiles(e)} />
                                </div>
                              {this.state.files.length !== 0 ?
                                <div className="flex justify-content-end">
                                    <span onClick={() => this.closeTextModal()} variant={"p-1"}>Done
                                    </span>

                                </div>
                                 :'' }  
                            </>
                            : ""}

                    </Modal.Body>
                    {/* {this.state.files.length !== 0 ? */}
                    <Modal.Footer style={{ paddingTop: 0 }}>

                    </Modal.Footer>
                    {/* :'' }  */}
                    {this.state.box === false ?
                        <Modal.Footer style={{ paddingTop: 0 }}>
                            <div className="flex justify-content-end">
                                <span onClick={() => this.closeTextModal()} variant={"p-1"}>OK
                                </span>

                            </div>
                        </Modal.Footer>
                        : ''}
                </Modal>
                <Modal
                    size="lg"
                    show={this.state.mapvisible}
                    onHide={() => this.setState({ mapvisible: false })}
                    backdrop='static'
                    centered
                    animation
                    aria-labelledby="example-modal-sizes-title-sm"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            Job Location
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <LoadScript googleMapsApiKey="AIzaSyBGcf9i6vlY07uvfI79hqgz7F7jTdaqA_I">
                            <GoogleMap

                                mapContainerStyle={{
                                    width: '100%',
                                    height: '400px'
                                }}
                                center={this.state.center}
                                zoom={10}
                                options={{
                                    streetViewControl: false,
                                    scaleControl: true,
                                    panControl: true,
                                    rotateControl: true,
                                    mapTypeControl: true,
                                    draggableCursor: 'default',
                                    draggingCursor: 'crosshair',
                                    zoomControl: true,
                                    maxZoom: 12,
                                    minZoom: 2,
                                    fullscreenControl: true
                                }}
                                onClick={(e) => this.mapClick(e)}
                            >
                                <Marker

                                    position={{
                                        lat: this.state.lat,
                                        lng: this.state.lng
                                    }}
                                />
                                { /* Child components, such as markers, info windows, etc. */}
                                <></>
                            </GoogleMap>
                        </LoadScript>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="flex justify-content-between">
                            <span onClick={() => this.setState({ mapvisible: false })} variant={"p-1"}>Cancel
                            </span>
                            <span onClick={() => this.doneMap()}>Done
                            </span>
                        </div>
                    </Modal.Footer>
                </Modal>
                <Modal
                    size="sm"
                    show={this.state.visible}
                    onHide={() => this.closeModal()}
                    backdrop='static'
                    centered
                    animation
                    aria-labelledby="example-modal-sizes-title-sm"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            Signature Panel
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <SignatureCanvas penColor='#005284'
                            canvasProps={{ width: 300, height: 150, className: 'sigCanvas' }} ref={(ref) => { this.sigPad = ref }} />
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="flex justify-content-between">
                            <span onClick={() => this.closeModal()} variant={"p-1"}>Cancel
                            </span>
                            <span onClick={() => this.sigPad.clear()} variant={"p-1"}>Re-Sign
                            </span>
                            <span onClick={() => this.signTrim()}>Done
                            </span>
                        </div>
                    </Modal.Footer>
                </Modal>
                <Modal
                    size="sm"
                    show={this.state.audioVisible}
                    onHide={() => this.closeAudioModal()}
                    backdrop='static'
                    centered
                    animation
                    aria-labelledby="example-modal-sizes-title-sm"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            Audio Recording
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="flex flex-column align-item-center justify-content-between">
                            <ReactMic
                                record={this.state.record}
                                className="sound-wave"
                                onStop={this.onStop}
                                onData={this.onData}
                                strokeColor="#000000"
                                backgroundColor="#adb5bd"
                                mimeType="audio/mp3" />
                            <div className="">

                                {this.state.record ?
                                    <img onClick={() => this.stopRecording()} style={{ width: 60 }} src={require('../images/stop.png')} alt="stop" className="img-responsive" />
                                    :
                                    <img onClick={() => this.startRecording()} style={{ width: 60 }} src={require('../images/recording.png')} alt="start" className="img-responsive" />
                                }

                            </div> </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="flex justify-content-between">

                            <span onClick={() => this.closeAudioModal()}>Cancel
                            </span>
                            {this.state.record ? '' : <span onClick={() => this.saveAudio()}>Done
                            </span>}


                        </div>
                    </Modal.Footer>
                </Modal>
                <Modal
                    size="lg"
                    show={this.state.videoVisible}
                    onHide={() => this.closeVideoModal()}
                    backdrop='static'
                    centered
                    animation
                    aria-labelledby="example-modal-sizes-title-sm"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            Video Recording
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body
                    >
                        <video width="100%" style={{ maxHeight: '450px' }}
                            ref={v => {
                                this.video = v;
                            }}>
                            Video stream not available.
                        </video>
                    </Modal.Body>
                    <Modal.Footer>
                        <div class="flex justify-content-between">
                            <select onChange={e => this.changeCamera(e)}>
                                {this.state.mediaDevice.map((media, i) => (
                                    <option value={media.deviceId}>{`Camera ${i}`}</option>
                                ))}
                            </select>
                            {!this.state.videoRecord && <img style={{ width: 50 }} src={require('../images/media-record.png')} alt="test" onClick={e => this.startVideoRecord(e)} />}
                            {this.state.videoRecord && <img style={{ width: 50 }} src={require('../images/stop.png')} alt="test" onClick={e => this.stopVideoRecord(e)} />}
                        </div>
                    </Modal.Footer>
                </Modal>
                {this.state.loading && <Loader />}
                {this.props.loader && <Loader />}
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        data: state.data.data,
        session_id: state.data.session,
        title: state.data.title,
        permit: state.data.permit,
        loader: state.data.loading,
        assessor: state.data.assessor
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getMictoData: (UnitId, session_id) => dispatch(fetchAssessorData(UnitId, session_id)),
        getMictoDataUnmount: () => dispatch(dataUnmount())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(RejectFetchSteps);