import React, { Component } from 'react';
import '../css/App.css';
import FormInput from '../component/FormInput';
import axios from 'axios';
import API from '../config_api'
// import { confirmAlert } from 'react-confirm-alert'; // Import
// import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '../component/Button';
import { connect } from 'react-redux'
import { fetchLoginData } from '../redux/loginData/LoginAction'
import Loader from '../component/Loader';
class UserLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    componentWillMount() {

        if (localStorage.getItem('token')) {
            if (localStorage.getItem('type').includes(50)) {
                this.props.history.push('/security');
            } else if (localStorage.getItem('type').includes(4)) {
                this.props.history.push('/assessor');
            } else if (localStorage.getItem('type').includes(6)) {
                this.props.history.push('/approver');
            } else if (localStorage.getItem('type').includes(7)) {
                this.props.history.push('/ehs');
            } else if (localStorage.getItem('type').includes(3)) {
                this.props.history.push('/dashboard');
            }
        }
      

        let formData = new FormData();
        formData.append('company_id', 'ams')

        axios({
            method: 'post',
            url: API.Enterprise,
            data: formData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            mode: 'no-cors',
        })
            .then(res => {
                console.log(res)
                if (res.data.status === 'success') {

                    localStorage.setItem('logo', res.data.data.logo);
                    localStorage.setItem('company_id', 'ams');

                }
                else {
                    alert(res.data.message)
                }
            });
    }
   
    handleSubmit(event) {
        const email = event.target[0].value;
        const password = event.target[1].value;
        event.preventDefault();
        if (email === '') {
            alert('Please enter Email/AcuiZen Id ')
        } else if (password === '') {
            alert('Please enter password')
        } else {
            if (email === 'eptwadmin@ams.com' && password ==='Tech@123') {
                axios({
                    method: 'post',
                    url: 'https://auth.acuizen.com/auth/login',
                    data: JSON.stringify({ company_id: 'ams', email: 'eptwadmin@ams.com', password: 'Temp@123' }),
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    },
                    mode: 'no-cors',
                }).then(result => {
                    console.log(result.data.token)
                    document.cookie = "token=" + result.data.token + ";domain=.acuizen.com;path=/";
                    document.cookie = "session_key=" + result.data.session_key + ";domain=.acuizen.com;path=/";
                    window.location.href = 'https://eptw.acuizen.com';
                })


            } else {


                let formData = new FormData();
                formData.append('user_email', email);
                formData.append('user_password', password);
                formData.append('company_id', localStorage.getItem('company_id'));
                formData.append('device_type', '1');
                formData.append('device_token', localStorage.getItem('notification-token'));

                axios({
                    method: 'post',
                    url: API.CheckParallel,
                    data: formData,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    },
                    mode: 'no-cors'
                })
                    .then(response => {
                        if (response.status === 'success') {

                            this.props.loginData(email, password, this.props);
                        } else if (response.status === 'invalid') {

                            setTimeout(() => {
                                alert('Invalid credentials')

                            }, 100)

                        } else {
                            this.props.loginData(email, password, this.props);
                        }
                    });

                this.setState({ value: event.target[0].value });
            }
        }
    }
    getBack() {
        localStorage.removeItem("company_id");
        window.location.reload();

    }
    getForget() {
        this.props.history.push('/forgotpassword')
    }
    render() {
        console.log(this.props)
        return (
            <div className="main-content h-h align-item-center">

                <div className="container ">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-md-7">
                            <div className="card border-0 mb-0 pad_z">

                                <div className="card-body px-lg-5 py-lg-5">
                                    <div className="text-center">
                                        <img src={require('../images/logo_new.png')} alt="az_logo" className="img-responsive mb-40" style={{ height: 100 }} />
                                    </div>
                                    <div className="text-center text-muted mb-4">
                                        <small>Lets get Started</small>
                                    </div>
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-group mb-3">
                                            <div className="input-group input-group-merge ">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><i className="ni ni-email-83"></i></span>
                                                </div>
                                                <FormInput name={"username"} placeholder={"EMail/AcuiZen ID"} type={"text"} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="input-group input-group-merge">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><i className="ni ni-lock-circle-open"></i></span>
                                                </div>
                                                <FormInput name={"password"} placeholder={"Password"} type={"password"} />
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <Button color={"btn-primary az-color"} type={"submit"} variant={"my-4"} size={"large"}>Sign In</Button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="flex mt-1 buttons">
                                <div className="">
                                    <small onClick={() => this.props.history.push('/forgotpassword')}>Forgot password?</small>
                                </div>
                                <div className="">
                                    <small onClick={() => this.props.history.push('/dri_manager_register')}>Create DRI Manager / ams OSRAM DRI Account</small>
                                </div>
                                <div className="">
                                    <small onClick={() => this.props.history.push('/create')}>Create Contractor Account</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.loader && <Loader />}
            </div>



        );
    }
}
const mapStateToProps = state => {
    return {
        data: state.login.data,
        loader: state.login.loading,
        error: state.login.error
    }
}
const mapDispatchToProps = dispatch => {
    return {
        loginData: (email, password, props) => dispatch(fetchLoginData(email, password, props))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserLogin);