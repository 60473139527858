import React, { Component } from 'react';
import '../css/App.css';


class AssignedModulesChild1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }
    componentWillMount() {

    }

    render() {
        return (
            <div className="unit_assign col-sm-12" onClick={() => this.props.starUnit()}>
                <div className="col-sm-11 col-xs-11 pad_z">{this.props.ChildComponent1content}</div>
                <div className="col-sm-1 col-xs-1 pad_z"><i className="material-icons">
                    keyboard_arrow_right
</i></div>

            </div>
            
        );
    }
}

export default AssignedModulesChild1;