import React, { Component } from 'react';
import '../css/App.css';
import { fetchData } from '../redux/assignedData/AssignAction'
import { connect } from 'react-redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Sidenav from './Sidenav';
import Loader from '../component/Loader';

class AreaAllocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
         
        };
       
    }
   
    componentWillMount() {
        if(!localStorage.getItem('token')){
            this.props.history.push('/login')
        }
        
        this.props.getAreaData();
    }
    btnAction(item) {
        console.log(item)
        this.props.history.push('/form/' +item.program_id,{pStatus:1})
      }
    render() {
       console.log(this.props.data)
        return (
            <div className="">
              <Sidenav type={'second'} url={'/dashboard'}/>
                <ReactCSSTransitionGroup
            transitionAppear={true}
            transitionAppearTimeout={600}
            transitionEnterTimeout={600}
            transitionLeaveTimeout={200}
            transitionName={this.props.match.path === '/area' ? 'SlideIn' : 'SlideOut'}
          >
                <div className="container">
                    
                    <div className="row">
                        <div className="col-sm-12 pad_z">

                            {this.props.data.map((item, i) => {
                                return (
                                    <>
                                    {item.program_id ==='5604'? '':
                                    <div className="card area justify-content-center row" style={{ padding: 10,margin:10, height: 55 }} key={i} onClick={()=>this.btnAction(item)}>
                                        <div className="col-sm-2 col-xs-2 pad_z">
                                           
                                        </div>
                                            <div className="col-sm-9 col-xs-9 padZero flex align-space-between"><div>{item.program_title}</div> 
                                            </div>
                                        <div className="col-sm-1 col-xs-1 pad_z text-right"><i className="material-icons">
                                            keyboard_arrow_right
</i></div>
                                    </div>
                            }
                                    </>
                                )
                            })}

                        </div>
                    </div>

                </div></ReactCSSTransitionGroup>
                {this.props.loader && <Loader/>}
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        data: state.assign.data,
        loader: state.assign.loading,
        error:state.assign.error
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getAreaData: () => dispatch(fetchData())
    }
}
export default  connect(mapStateToProps, mapDispatchToProps)(AreaAllocation);