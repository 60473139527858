export default {
    Enterprise: 'https://eptw.acuizen.com/api/company_screens/',
    Login: 'https://eptw.acuizen.com/api/userlogin_beta',
    CheckParallel: 'https://eptw.acuizen.com/api/check_device_token_beta',
    Permit: 'https://eptw.acuizen.com/api/ptw_get_all_permits',
    Fetch: 'https://eptw.acuizen.com/api/fetch_all_microstep_beta',
    Dashboard: 'https://eptw.acuizen.com/api/ptw_getDashboardData',
    MyAccount: 'https://eptw.acuizen.com/api/save_profile',
    AboutUs: 'https://eptw.acuizen.com/api/about_us',
    Privacy: 'https://eptw.acuizen.com/api/privacy_policy',
    ChangePassword: 'https://eptw.acuizen.com/api/change_my_password',
    LogOut: 'https://eptw.acuizen.com/api/logout_user_beta',
    Forgot: 'https://eptw.acuizen.com/api/userforgotpassword',
    onPermitUpcomingApi: 'https://eptw.acuizen.com/api/ptw_get_all_upcoming_data_beta',
    onLivePermitLocationData: 'https://eptw.acuizen.com/api/get_app_live_data',
    onPermitActiveApi: 'https://eptw.acuizen.com/api/ptw_get_all_active_data_beta',
    onPermitInprocessApi: 'https://eptw.acuizen.com/api/ptw_get_all_in_process_data_beta',
    onPermitArchivedApi: 'https://eptw.acuizen.com/api/ptw_get_all_archive_data_beta',
    Notification: 'https://eptw.acuizen.com/api/broadcast_msg_list',
    NotificationUpdate: 'https://eptw.acuizen.com/api/broadcast_msg_update',
    NotificationDelete: 'https://eptw.acuizen.com/api/broadcast_msg_update',
    getLocationAction: 'https://eptw.acuizen.com/api/ptw_get_location_details',
    setLocationAction: 'https://eptw.acuizen.com/api/ptw_set_location',
    GetAssessorAction: 'https://eptw.acuizen.com/api/ptw_get_assessor',
    SetAssessorAction: 'https://eptw.acuizen.com/api/ptw_set_assessor',
    SetApproverAction: 'https://eptw.acuizen.com/api/ptw_submit_admin',
    SetIntermediateAction: 'https://eptw.acuizen.com/api/ptw_submit_intermediate',
    SubmitUnit: 'https://eptw.acuizen.com/Webservice/save_time_spent_multi_beta',
    OnpermitPending: 'https://eptw.acuizen.com/api/ptw_get_all_pending_data_beta',
    OnAchivedSession: 'https://eptw.acuizen.com/api/ptw_get_session_response',
    ApproverRevoke: 'https://eptw.acuizen.com/api/ptw_approver_revoke',
    GetApprover: 'https://eptw.acuizen.com/api/ptw_get_admin',
    GetIntermediate: 'https://eptw.acuizen.com/api/ptw_get_intermediate',
    approverApprove: 'https://eptw.acuizen.com/api/ptw_approver_approve',
    AssessorRevoke: 'https://eptw.acuizen.com/api/ptw_assessor_revoke',
    getDocuments: 'https://eptw.acuizen.com/api/get_documents',
    getLocationImages: 'https://eptw.acuizen.com/api/ptw_get_location_image',
    getIncidentType: 'https://eptw.acuizen.com/Webservice/dropdown_items',
    GetContractorName: 'https://eptw.acuizen.com/api/get_contractor_name',
    GetRevokedData: 'https://eptw.acuizen.com/Webservice/ptw_get_revoked_data',
    GetRevokeContractorData: 'https://eptw.acuizen.com/Webservice/ptw_get_revoked_data_contractor',
    getContractorCount: 'https://eptw.acuizen.com/api/get_contractor_permit_count',
    getRemarks: 'https://eptw.acuizen.com/api/ptw_get_remarks_activity',
    getSearch: 'https://eptw.acuizen.com/api/ptw_get_search_permits',
    getFormPdf: 'https://eptw.acuizen.com/steps/populate_elements_risk_pdf',
    onPermitLiveApi: 'https://eptw.acuizen.com/api/ptw_get_live_permits',
    GetLocationName: 'https://eptw.acuizen.com/api/get_location_name',
    SetDraft: 'https://eptw.acuizen.com/api/ptw_set_draft',
    GetDraft: 'https://eptw.acuizen.com/api/ptw_get_all_pending_data_beta',
    Hint: 'https://eptw.acuizen.com/api/get_question_hint',
    UploadFile: 'https://eptw.acuizen.com/webservice/upload_file',
    UploadMultiFile: 'https://eptw.acuizen.com/webservice/upload_multi_file',
    ForeClose: 'https://eptw.acuizen.com/api/ptw_set_final',
    ExtraFile: 'https://eptw.acuizen.com/api/ptw_store_extra_file',
    HotWork:'https://eptw.acuizen.com/api/ptw_get_all_highrisk_data_beta'

}