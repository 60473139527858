import React, { Component } from 'react';
import '../css/App.css';
import API from '../config_api'
import axios from 'axios'
import FormInput from '../component/FormInput';
import Button from '../component/Button';
import Sidenav from './Sidenav';
class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentWillMount() {

    }
    handleSubmit(event) {
        event.preventDefault();
        let formData = new FormData();
        formData.append('uid', localStorage.getItem('token'));
        formData.append('cur_pwd', event.target[0].value);
        formData.append('new_pwd', event.target[1].value);
        formData.append('cnf_pwd', event.target[2].value);

        axios({
            method: 'post',
            url: API.ChangePassword,
            data: formData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            mode: 'no-cors',
        })
            .then(res => {
                if (res.data.status === 'success') {
                    alert(res.data.message);
                    this.props.history.goBack();
                } else {
                    alert(res.data.message);
                }
            });

    }

    render() {
        return (<div className="" >
           <Sidenav type={'second'} url={'/dashboard'}/>
            <div className="container" >
                <div className="row mt-3" >
                    <form className="col-12 text-center"
                        onSubmit={this.handleSubmit} >
                        <div className="form-group">
                            <div className="input-group input-group-merge input-group-alternative">
                                <FormInput name={"old"} placeholder={"Current Password"} type={"text"} />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="input-group input-group-merge input-group-alternative">
                                <FormInput name={"new"} placeholder={"New Password"} type={"text"} />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="input-group input-group-merge input-group-alternative">

                                <FormInput name={"confirm"} placeholder={"Confirm Password"} type={"text"} />
                            </div>
                        </div>
                        <Button color={"az-color text-white"} type={"submit"} variant={"waves-effect"} size={"large"}>Submit</Button> </form>
                </div> </div> </div>
        );
    }
}

export default ChangePassword;