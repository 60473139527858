import React, { Component } from 'react';
import '../css/App.css';


class SplashScreen extends Component {
    componentDidMount() {
        setTimeout(() => this.props.history.push('/login'), 2000)
    }

    render() {
        return (
            <div className="main-content align-items-center h-h" style={{ display: 'flex' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <img src={localStorage.getItem('logo')} alt="az_logo" className="company_logo" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SplashScreen;