import axios from 'axios';
import API from '../../../src/config_api'
import { FETCH_MICRO_DATA_REQUEST, FETCH_MICRO_DATA_SUCCESS, FETCH_MICRO_DATA_FAILURE, FETCH_MICRO_DATA_UNMOUNT } from './MicroTypes'
export const fetchDataRequest = () => {
    return {
        type: FETCH_MICRO_DATA_REQUEST
    }
}
export const fetchDataSuccess = data => {
    return {
        type: FETCH_MICRO_DATA_SUCCESS,
        payload: data
    }
}
export const fetchDataFailure = error => {
    return {
        type: FETCH_MICRO_DATA_FAILURE,
        payload: error
    }
}

export const fetchUnmount = () => {
    return {
        type: FETCH_MICRO_DATA_UNMOUNT,
        payload: ''
    }
}
export const dataUnmount = () => {
    return (dispatch) => {
        dispatch(fetchUnmount())
    }
}
export const fetchData = (UnitId) => {
    return (dispatch) => {
        dispatch(fetchDataRequest());
        let formData = new FormData();
        formData.append('user_id', localStorage.getItem('token'))
        formData.append('program_id', UnitId)
        axios({
                method: 'post',
                url: API.Fetch,
                data: formData,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                mode: 'no-cors',
            })
            .then(res => {
                if (res.data.status === 'success') {
                    const data = res.data;
                    console.log(res.data);
                    dispatch(fetchDataSuccess(data))

                } else {
                    const error = res.data.message;
                    dispatch(fetchDataFailure(error))
                }
            });
    }
}
export const fetchAssessorData = (UnitId, session_id) => {
    return (dispatch) => {
        dispatch(fetchDataRequest());
        let formData = new FormData();
        formData.append('session_id', session_id)
        formData.append('program_id', UnitId)
        axios({
                method: 'post',
                url: API.OnAchivedSession,
                data: formData,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                mode: 'no-cors',
            })
            .then(res => {
                if (res.data.status === 'success') {
                    const data = res.data;
                    console.log(res.data);
                    dispatch(fetchDataSuccess(data))

                } else {
                    const error = res.data.message;
                    dispatch(fetchDataFailure(error))
                }
            });
    }
}