import React, { Component } from "react";
import "../css/App.css";
import { fetchAssessorData, dataUnmount } from "../redux/microData/MicroAction";
import { connect } from "react-redux";
import axios from "axios";
import API from "../config_api";
import FancyVideo from "react-videojs-fancybox";

import moment from "moment";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SignatureCanvas from "react-signature-canvas";
import { Modal } from "react-bootstrap";
import { Plugins, CameraResultType, CameraSource } from "@capacitor/core";
import "@ionic/pwa-elements";
import Loader from "../component/Loader";
import { ReactMic } from "react-mic";
import BigPicture from "react-bigpicture";
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";
import Icon from "@mdi/react";
import { mdiHomeCity } from "@mdi/js";
import UNCHECKED from '../images/unchecked_48dp.png'
import CHECKED from '../images/checked_48dp.png'
import RADIOUNCHECKED from '../images/radio_unchecked_48dp.png'
import RADIOCHECKED from '../images/radio_checked_48dp.png'

// import cryptoRandomString from "crypto-random-string";
const { Camera, Browser, Network, Geolocation } = Plugins;

class AssessorFetchSteps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unique_id:
        new Date().valueOf() + "_" + Math.floor(Math.random() * 100000),
      data: [],
      currentStep: 0,
      style: "menu",
      menuStatus: "open",
      status: false,
      isRecording: false,
      blobURL: "",
      loading: false,
      isBlocked: false,
      requiredCheck: true,
      preview: "",
      visible: false,
      mapvisible: false,
      audioVisible: false,
      videoVisible: false,
      index: "",
      record: false,
      recordedBlob: "",
      lat: 0,
      lng: 0,
      mediaDevice: [],
      videoRecord: false,
      lightboxIsOpen: false,
      projectList: [],
      locationList: [],
      locationData: [],
      approverList: [],
      date: new Date(),
      sTime: "",
      eTime: "",
      center: {
        lat: -3.745,
        lng: -38.523,
      },
      remarks: "",
      seconds: 0,
      file: [],
    };
    this.fileObj = [];
    this.fileArray = [];
  }

  componentDidMount() {
    setInterval(() => {
      this.setState(({ seconds }) => ({
        seconds: seconds + 1,
      }));
    }, 1000);
    // navigator.getUserMedia({ audio: true },
    //     () => {
    //         console.log('Permission Granted');
    //         this.setState({ isBlocked: false });
    //     },
    //     () => {
    //         console.log('Permission Denied');
    //         this.setState({ isBlocked: true })
    //     },
    // );
  }
  async componentWillMount() {
    if (!localStorage.getItem("token")) {
      this.props.history.push("/login");
    }

    this.props.getMictoData(
      this.props.match.params.id,
      this.props.location.state.session_id
    );
    let formData = new FormData();
    formData.append("company_id", localStorage.getItem("company_no"));

    axios({
      method: "post",
      url: API.GetIntermediate,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
      mode: "no-cors",
    }).then((res) => {
      console.log(res);
      this.setState({ approverList: res.data.data });
    });
    // let status = await Network.getStatus();
    // console.log(status);
    // const coordinates = await Geolocation.getCurrentPosition();
    // console.log('Current', coordinates);
    // this.setState({
    //     lonn: coordinates.coords.longitude,
    //     latt: coordinates.coords.latitude
    // })
  }

  componentWillUnmount() {
    this.props.getMictoDataUnmount();
  }
  async takePicture(item, index) {
    const image = await Camera.getPhoto({
      resultType: CameraResultType.DataUrl,
      quality: 90,
      allowEditing: true,
      source: CameraSource.Camera,
    });
    console.log(image);

    this.props.data[this.state.currentStep].microstep_data[index].value =
      image.dataUrl;
    this.forceUpdate();
  }
  removePicture(item, index) {
    this.props.data[this.state.currentStep].microstep_data[index].value = "";
    this.forceUpdate();
  }
  async launchScrom(url) {
    await Browser.open({ url: url });
  }
  async takeVideo(item, index) {
    this.setState({
      videoVisible: true,
      index: index,
    });
    this.gotDevices();
  }
  gotDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(
      (device) => device.kind === "videoinput"
    );
    this.setState({
      mediaDevice: videoDevices,
    });
    const videoConstraints = {};

    videoConstraints.facingMode = "environment";
    const constraints = {
      video: videoConstraints,
      audio: false,
    };
    this.capture(constraints);
  };
  async capture(constraints) {
    //   var constraints = {audio:true, video: { facingMode: "environment" } };

    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    // show it to user
    this.setState({
      currentStream: stream,
    });
    this.video.srcObject = stream;
    this.video.play();
    // init recording

    this.mediaRecorder = new MediaRecorder(stream, {
      mimeType: "video/webm",
    });
    // init data storage for video chunks
    this.chunks = [];
    // listen for data from media recorder
    this.mediaRecorder.ondataavailable = (e) => {
      if (e.data && e.data.size > 0) {
        this.chunks.push(e.data);
      }
    };
  }
  takeSign(item, index) {
    this.setState({
      visible: true,
      index: index,
    });
  }
  takeAudio(item, index) {
    this.setState({
      audioVisible: true,
      index: index,
    });
  }
  startRecording = () => {
    this.setState({
      record: true,
    });
  };
  stopMediaTracks(stream) {
    stream.getTracks().forEach((track) => {
      track.stop();
    });
  }

  startVideoRecord(e) {
    // wipe old data chunks
    this.chunks = [];
    // start recorder with 10ms buffer
    this.mediaRecorder.start(10);
    // say that we're recording
    this.setState({ videoRecord: true });
  }
  changeCamera(event) {
    if (typeof this.state.currentStream !== "undefined") {
      this.stopMediaTracks(this.state.currentStream);
    }
    const videoConstraints = {};
    if (event.target.value === "") {
      videoConstraints.facingMode = "environment";
    } else {
      videoConstraints.deviceId = { exact: event.target.value };
    }
    const constraints = {
      video: videoConstraints,
      audio: false,
    };
    this.capture(constraints);
  }
  stopVideoRecord(e) {
    // stop the recorder
    this.mediaRecorder.stop();
    // say that we're not recording
    this.setState({ videoRecord: false });
    // save the video to memory
    this.stopMediaTracks(this.state.currentStream);
    this.saveVideo();
  }
  saveVideo() {
    const blob = new Blob(this.chunks, { type: "video/mp4" });
    const videoURL = window.URL.createObjectURL(blob);
    this.props.data[this.state.currentStep].microstep_data[
      this.state.index
    ].value = videoURL;
    this.props.data[this.state.currentStep].microstep_data[
      this.state.index
    ].blob = blob;
    this.forceUpdate();
    this.setState({
      videoVisible: false,
      index: null,
    });
  }
  stopRecording = () => {
    this.setState({
      record: false,
    });
  };

  onData(recordedBlob) {
    console.log("chunk of real-time data is: ", recordedBlob);
  }

  onStop = (recordedBlob) => {
    console.log(recordedBlob);
    this.props.data[this.state.currentStep].microstep_data[
      this.state.index
    ].value = recordedBlob.blobURL;
    this.props.data[this.state.currentStep].microstep_data[
      this.state.index
    ].blob = recordedBlob.blob;
    this.forceUpdate();
  };
  closeModal() {
    this.setState({
      visible: false,
      index: null,
    });
    this.sigPad.clear();
  }
  closeAudioModal() {
    this.props.data[this.state.currentStep].microstep_data[
      this.state.index
    ].value = "";
    this.forceUpdate();
    this.setState({
      audioVisible: false,
      index: null,
    });
  }
  closeVideoModal() {
    this.props.data[this.state.currentStep].microstep_data[
      this.state.index
    ].value = "";
    this.forceUpdate();
    this.setState({
      videoVisible: false,
      index: null,
    });
  }
  signTrim() {
    if (!this.sigPad.isEmpty()) {
      const sign = this.sigPad.getTrimmedCanvas().toDataURL("image/png");
      this.props.data[this.state.currentStep].microstep_data[
        this.state.index
      ].value = sign;
      this.forceUpdate();
      this.setState({
        visible: false,
        index: null,
      });
      this.sigPad.clear();
    }
  }
  removeSign(item, index) {
    this.props.data[this.state.currentStep].microstep_data[index].value = "";
    this.forceUpdate();
  }
  saveAudio() {
    if (!this.state.record) {
      this.setState({
        audioVisible: false,
        index: null,
      });
    }
  }

  checkBoxAction(data, item, index, step) {
    if (step !== 0) {
      if (item.is_multiselect === "1") {
        if (data.checked === 1) {
          data.checked = 0;
          this.forceUpdate();
        } else {
          data.checked = 1;
          this.forceUpdate();
        }
      } else {
        item.options.forEach((data, ind) => {
          if (ind === index) {
            data.checked = 1;
            this.forceUpdate();
          } else {
            data.checked = 0;
            this.forceUpdate();
          }
        });
      }
    }
    console.log(item);
  }
  MCQAction(data, item, index, indexV) {
    console.log(this.state.currentStep);
    if (item.is_multiselect === "1") {
      if (data.imcaChecked === 1) {
        data.imcaChecked = 0;
        this.forceUpdate();
      } else {
        data.imcaChecked = 1;
        this.forceUpdate();
      }
      let files = item.options.filter((dt) => dt.imcaChecked === 1);
      let ft = files.map((ds) => {
        let dt = { ans: ds.name };
        return dt;
      });
      let files2 = item.options.filter((dt) => dt.checked === 1);
      let ans = files2.map((ds) => {
        let dt = { ans: ds.name };
        return dt;
      });
      this.props.data[this.state.currentStep].microstep_data[indexV].user_ans =
        ft;
      this.props.data[this.state.currentStep].microstep_data[indexV].ans = ans;
      this.forceUpdate();
    } else {
      item.options.forEach((data, ind) => {
        if (ind === index) {
          data.imcaChecked = 1;
          this.forceUpdate();
        } else {
          data.imcaChecked = 0;
          this.forceUpdate();
        }
      });

      let files = item.options.filter((dt) => dt.imcaChecked === 1);
      let ft = files.map((ds) => {
        let dt = { ans: ds.name };
        return dt;
      });
      let files2 = item.options.filter((dt) => dt.checked === 1);
      let ans = files2.map((ds) => {
        let dt = { ans: ds.name };
        return dt;
      });
      this.props.data[this.state.currentStep].microstep_data[indexV].user_ans =
        ft;
      this.props.data[this.state.currentStep].microstep_data[indexV].ans = ans;
      this.forceUpdate();
    }
    console.log(item);
  }
  insightAction(dataV, item, i, index) {
    item.insight_options.forEach((data, ind) => {
      if (ind === i) {
        data.checked = "1";
        this.forceUpdate();
      } else {
        data.checked = "0";
        this.forceUpdate();
      }
    });
    console.log(item);
  }
  checkbox_icon(is_multiselect, checked) {
    if (is_multiselect === "1") {
        if (checked === 0) {
            return (
                <img src={UNCHECKED} alt="uncheck"

                    style={{ paddingRight: 10, height: 20 }} />
            )
        } else {
            return (<img src={CHECKED} alt="checked"
                style={{ paddingRight: 10, height: 20 }} />
            )
        }
    } else {
        if (checked === 0) {
            return (<img src={RADIOUNCHECKED} alt="uncheck"
                style={{ paddingRight: 10, height: 20 }} />
            )
        } else {
            return (<img src={RADIOCHECKED} alt="uncheck"
                style={{ paddingRight: 10, height: 20 }} />
            )
        }
    }
}
  mcqValueCheck(item) {
    if (
      item.some((data) => {
        return data.imcaChecked === 1;
      })
    ) {
      // console.log('checked true')
      return true;
    } else {
      // console.log('checked false')
      return false;
    }
  }
  pollValueCheck(item) {
    if (
      item.some((data) => {
        return data.checked === 1;
      })
    ) {
      return true;
    } else {
      return false;
    }
  }

  insightValueCheck(item) {
    if (
      item.some((data) => {
        return data.checked === 1;
      })
    ) {
      return true;
    } else {
      // this.setState({ requiredCheck: false})
      return false;
    }
  }
  textInputInfo(event, index) {
    console.log(event.target.value);
    this.props.data[this.state.currentStep].microstep_data[index].value =
      event.target.value;
    this.forceUpdate();
  }
  ref = (player) => {
    this.player = player;
  };
  datepicker = () => {
    // this.show('date');
    this.setState({ showDatePicker: true });
  };
  webview() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // true for mobile device
      return false;
    } else {
      // false for not mobile device
      return true;
    }
  }
  changeDate(date) {
    const dateString = date;
    const dateObject = moment(dateString, 'MM/DD/YYYY');

    const formattedDate = dateObject.format('DD-MMM-YYYY');
    return formattedDate
}
  applicantOption(item, index) {
    if (index === 0) {
      return (
        <div className="col-12 pad_z">
          <div className="card horizontal">
            <div className="card-stacked">
              <div className="card-content ">
                <div className=" mb-1">
                  <div className=" p-0">
                    <span style={{ paddingRight: 14 }}>
                      <Icon
                        path={mdiHomeCity}
                        title="User Profile"
                        size={0.8}
                        color={"#be1f24"}
                      />
                    </span>
                    <span>{item.microstep_data[1].value}</span>
                  </div>
                  {/* <div className=" p-0">
                                    <span style={{ paddingRight: 14 }}>
                                        <IosPin size={30} color={'#be1f24'} />
                                    </span>
                                    <span>{item.microstep_data[0].value}</span>
                                </div> */}
                </div>
                <div>
                  <div className=" p-0">
                    <span style={{ paddingRight: 14 }}>
                      {/* <MdCalendar size={30} color={"#005284"} /> */}
                    </span>
                    <span>{this.changeDate(item.microstep_data[4].value)}</span>
                  </div>
                  {/* <div className=" p-0">
                                    <span style={{ paddingRight: 14 }}>
                                        
                                    </span>
                                    <span>{item.microstep_data[2].value} {item.microstep_data[3].value}</span>
                                </div> */}
                </div>
                {/* <LoadScript googleMapsApiKey="AIzaSyBGcf9i6vlY07uvfI79hqgz7F7jTdaqA_I">
                                <GoogleMap

                                    mapContainerStyle={{
                                        width: '100%',
                                        height: '400px'
                                    }}
                                    center={{
                                        lat: item.microstep_data[5].value.latitude,
                                        lng: item.microstep_data[5].value.longitude
                                    }}
                                    zoom={14}
                                    options={{
                                        streetViewControl: false,
                                        scaleControl: false,
                                        panControl: false,
                                        rotateControl: false,
                                        mapTypeControl: false,
                                        draggable: false,
                                        draggableCursor: 'default',
                                        draggingCursor: 'crosshair',
                                        zoomControl: false,
                                        maxZoom: 12,
                                        minZoom: 2,
                                        fullscreenControl: false
                                    }}

                                >
                                    <Marker

                                        position={{
                                            lat: item.microstep_data[5].value.latitude,
                                            lng: item.microstep_data[5].value.longitude
                                        }}
                                    />

                                    <></>
                                </GoogleMap>
                            </LoadScript> */}
                {/* <div>Description : {item.microstep_data[8].value}</div> */}
                <div>Start Date : {this.changeDate(item.microstep_data[2].value)}</div>
                                <div>End Date : {this.changeDate(item.microstep_data[3].value)}</div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  startTime(e) {
    console.log(e);
    // const minTime = moment(moment(new Date()).format('HH:mm a'), "HH:mm a");
    // //const maxTime = moment("12:01", "HH:mm");
    // console.log(minTime)

    // if (minTime.isBefore(e)) {
    this.setState({
      sTime: e,
    });
    this.props.data[0].microstep_data[2].value = e;
    // } else {

    // }
  }
  endTime(e) {
    console.log(e);
    // const minTime = moment(moment(this.state.sTime).format('HH:mm a'), "HH:mm a");
    // //const maxTime = moment("12:01", "HH:mm");
    // console.log(minTime)

    // if (minTime.isBefore(e)) {
    this.setState({
      eTime: e,
    });
    this.props.data[0].microstep_data[3].value = e;
    // } else {

    // }
  }
  replaceFun = (filename) => {
    if (filename) {
      const ext = filename.substr(filename.lastIndexOf(".") + 1);
      const name = filename.substr(0, filename.lastIndexOf("."));
      const final = name.replace(/\./g, "_");
      const final1 = final.replace(/-/g, "_");
      const final2 = final1.replace(/'/g, "_");
      const final3 = final2.replace(/&/g, "_");
      const final4 = final3.replace(/\+/g, "_");
      return final4.replace(/ /g, "_") + "." + ext;
    }
  };
  setDate(e) {
    console.log(e);
    this.setState({
      date: e,
    });
    this.props.data[0].microstep_data[4].value = moment(e).format("L");
  }
  layout(item, index, step) {
    if (item.type === "scorm") {
      return (
        <div className="card horizontal">
          <div className="card-stacked">
            <div className="card-content">
              <p>Scrom</p>
              <img
                onClick={() => this.launchScrom(item.name)}
                src={require("../images/camera.png")}
                alt="camera"
              />
            </div>
          </div>
        </div>
      );
    }

    if (item.type === "youtube") {
      return (
        <div className="card horizontal">
          <div className="card-stacked">
            <div className="card-content">
              <BigPicture
                type="youtube"
                src={"https://www.youtube.com/watch/v=" + item.name}
              >
                <div className="flex" style={{ position: "relative" }}>
                  <img
                    src={"https://img.youtube.com/vi/" + item.name + "/0.jpg"}
                    className="img-responsive"
                    style={{ width: "100%" }}
                    alt="test"
                  />
                  <div className="overlay">
                    {" "}
                    <img
                      src={require("../images/youtube.png")}
                      style={{ width: "70px" }}
                      alt="test"
                    />
                  </div>
                </div>
              </BigPicture>
            </div>
          </div>
        </div>
      );
    } else if (item.type === "paragraph") {
      return (
        <div className="card horizontal">
          <div className="card-stacked">
            <div className="card-content">
              <div dangerouslySetInnerHTML={{ __html: item.name }} />
            </div>
          </div>
        </div>
      );
    } else if (item.type === "image") {
      return (
        <div className="card horizontal">
          <div className="card-stacked">
            <div className="card-content text-center">
              <BigPicture type="image" src={item.name}>
                <div className="flex justify-content-center">
                  <img src={item.name} alt="step" className="img-responsive" />
                </div>
              </BigPicture>
            </div>
          </div>
        </div>
      );
    } else if (item.type === "video") {
      return (
        <div className="card horizontal">
          <div className="card-stacked">
            <div className="card-content">
              {/* <FancyVideo
                                source={item.name}
                                poster={require('../images/white.jpg')}
                                id={Math.random().toString(36).substring(7)} /> */}
              {/* <video src={item.name} controls style={{ width: '100%' }}>
                                Your browser does not support the video tag.
</video> */}
              <BigPicture type="video" src={item.name}>
                <div className="flex" style={{ position: "relative" }}>
                  {item.preview ? (
                    <img
                      src={item.preview}
                      className="img-responsive"
                      style={{ width: "100%" }}
                      alt="test"
                    />
                  ) : (
                    <img
                      src={require("../images/black.jpg")}
                      className="img-responsive"
                      style={{ width: "100%", height: 200 }}
                      alt="test"
                    />
                  )}
                  <div className="overlay">
                    {" "}
                    <img
                      src={require("../images/cinema.png")}
                      style={{ width: "70px" }}
                      alt="test"
                    />
                  </div>
                </div>
              </BigPicture>
            </div>
          </div>
        </div>
      );
    } else if (item.type === "embed") {
      return (
        <div className="card horizontal">
          <div className="card-stacked">
            <div className="card-content">
              <div
                className="embed"
                dangerouslySetInnerHTML={{ __html: item.name }}
              />
            </div>
          </div>
        </div>
      );
    } else if (item.type === "weblink") {
      return (
        <div className="card horizontal">
          <div className="card-stacked">
            <div className="card-content text-center">
              <a
                href={item.name}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  padding: "8px 20px",
                  display: "inline-block",
                  color: "#fff",
                  background: "#005284",
                  borderRadius: 3,
                  fontSize: 15,
                  margin: 10,
                }}
              >
                Click Here
              </a>
              <p>This will Open on a separate tab</p>
            </div>
          </div>
        </div>
      );
    } else if (item.type === "pdfs") {
      return (
        <div className="card horizontal">
          <div className="card-stacked">
            <div className="card-content">
              <BigPicture type="iframe" src={item.name}>
                <div
                  className="flex justify-content-center"
                  style={{ position: "relative" }}
                >
                  {item.preview ? (
                    <img
                      src={item.preview}
                      className="img-responsive"
                      style={{ width: 320, height: 400 }}
                      alt="test"
                    />
                  ) : (
                    <img
                      src={require("../images/black.jpg")}
                      className="img-responsive"
                      style={{ width: "100%", height: 200 }}
                      alt="test"
                    />
                  )}
                  <div className="overlay">
                    {" "}
                    <img
                      src={require("../images/pdf.png")}
                      style={{ width: "70px" }}
                      alt="test"
                    />
                  </div>
                </div>
              </BigPicture>
              {/* <iframe src={item.name} width='100%' height="400px" title="red" allowFullScreen/> */}
            </div>
          </div>
        </div>
      );
    } else if (item.type === "audio") {
      return (
        <div className="card horizontal">
          <div className="card-stacked">
            <div className="card-content">
              <BigPicture type="audio" src={item.name}>
                <div className="flex justify-content-center">
                  <img
                    src={require("../images/1200px-Speaker_Icon.png")}
                    style={{ width: "70px" }}
                    alt="test"
                  />
                </div>
              </BigPicture>
              {/* <audio controls>
                                <source src={item.name}></source>
                            </audio> */}
            </div>
          </div>
        </div>
      );
    } else if (item.type === "userimage") {
      return (
        <div className="card horizontal row">
          {item.name.match("be downloaded from this") ? (
            <p>
              {" "}
              {item.name}
              {item.name.match("Upload a list of workers") ? (
                <a href={require("../images/ContractorListing.xlsx")}>link</a>
              ) : (
                <a href={require("../images/MethodStatement.docx")}>link</a>
              )}{" "}
            </p>
          ) : (
            <p>{item.name}</p>
          )}
          {item.allfiles &&
            item.allfiles.map((item1, i) => {
              return (
                <>
                  {item1.filetype === "application/pdf" ? (
                    <div className="col-3">
                      <div className="card-stacked">
                        {item1.value && (
                          <div className="card-content">
                            {this.webview() && window.innerWidth >= 800 ? (
                              <BigPicture
                                type="iframe"
                                src={
                                  "https://mpower-s3.s3.ap-southeast-1.amazonaws.com/uploads/multimedia/" +
                                  this.replaceFun(item1.value)
                                }
                              >
                                <div
                                  className="flex justify-content-center"
                                  style={{
                                    position: "relative",
                                    flexDirection: "column",
                                  }}
                                >
                                  <img
                                    src={require("../images/pdf.png")}
                                    style={{ width: "70px" }}
                                    alt="test"
                                  />
                                  <p>{item1.value}</p>
                                </div>
                              </BigPicture>
                            ) : (
                              <a
                                href={
                                  "https://mpower-s3.s3.ap-southeast-1.amazonaws.com/uploads/multimedia/" +
                                  this.replaceFun(item1.value)
                                }
                                download
                                className="flex justify-content-center"
                                style={{
                                  position: "relative",
                                  flexDirection: "column",
                                }}
                              >
                                <img
                                  src={require("../images/pdf.png")}
                                  style={{ width: "70px" }}
                                  alt="test"
                                />
                                <p>{item1.value}</p>
                              </a>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : item1.filetype.match("image") ? (
                    <div className="col-3">
                      <div className="card-stacked">
                        <div className="card-content">
                          <BigPicture
                            type="image"
                            src={
                              "https://mpower-s3.s3.ap-southeast-1.amazonaws.com/uploads/multimedia/" +
                              this.replaceFun(item1.value)
                            }
                          >
                            <div
                              className="flex justify-content-center"
                              style={{
                                position: "relative",
                                flexDirection: "column",
                              }}
                            >
                              <img
                                src={
                                  "https://mpower-s3.s3.ap-southeast-1.amazonaws.com/uploads/multimedia/" +
                                  item1.value.replace(/-| /g, "_")
                                }
                                alt="step"
                                className="img-responsive"
                              />
                              <p style={{ fontSize: 10 }}>{item1.value}</p>
                            </div>
                          </BigPicture>
                        </div>
                      </div>
                    </div>
                  ) : item1.filetype.match("video") ? (
                    <div className="col-3">
                      <div className="card-stacked">
                        <div className="card-content">
                          <BigPicture
                            type="video"
                            src={
                              "https://mpower-s3.s3.ap-southeast-1.amazonaws.com/uploads/multimedia/" +
                              this.replaceFun(item1.value)
                            }
                          >
                            <div
                              className="flex justify-content-center"
                              style={{
                                position: "relative",
                                flexDirection: "column",
                              }}
                            >
                              <img
                                src={require("../images/video.png")}
                                alt="step"
                                className="img-responsive"
                              />
                              <p style={{ fontSize: 10 }}>{item1.value}</p>
                            </div>
                          </BigPicture>
                        </div>
                      </div>
                    </div>
                  ) : item1.value.match("xls") ? (
                    <div className="col-3">
                      <div className="card-stacked">
                        <div className="card-content">
                          <a
                            className="flex align-items-center justify-content-center"
                            href={
                              "https://mpower-s3.s3.ap-southeast-1.amazonaws.com/uploads/multimedia/" +
                              this.replaceFun(item1.value)
                            }
                          >
                            <div style={{ textAlign: "center" }}>
                              <img
                                src={require("../images/excel.png")}
                                style={{ width: 50 }}
                                alt="step"
                                className="img-responsive"
                              />
                              <p style={{ fontSize: 10 }}>{item1.value}</p>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  ) : item1.value.match(".doc") ? (
                    <div className="col-3">
                      <div className="card-stacked">
                        <div className="card-content">
                          <a
                            className="flex align-items-center justify-content-center"
                            href={
                              "https://mpower-s3.s3.ap-southeast-1.amazonaws.com/uploads/multimedia/" +
                              this.replaceFun(item1.value)
                            }
                          >
                            <div style={{ textAlign: "center" }}>
                              <img
                                src={require("../images/word.png")}
                                style={{ width: 50 }}
                                alt="step"
                                className="img-responsive"
                              />
                              <p style={{ fontSize: 10 }}>{item1.value}</p>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              );
            })}
          {step === 1 ? (
            <>
              <div className="form-group multi-preview row">
                {item.files
                  ? item.files.map((url, i) => {
                      if (url[0].type === "application/pdf") {
                        return (
                          <div className="col-3">
                            <i
                              className="material-icons"
                              onClick={() => this.removeAttach(url, item)}
                            >
                              close
                            </i>
                            {this.webview() && window.innerWidth >= 800 ? (
                              <BigPicture
                                type="iframe"
                                src={URL.createObjectURL(url[0])}
                              >
                                <div
                                  className="flex  justify-content-center align-items-center"
                                  style={{
                                    position: "relative",
                                    flexDirection: "column",
                                  }}
                                >
                                  <img
                                    src={require("../images/pdf.png")}
                                    style={{ width: "70px" }}
                                    alt="test"
                                  />
                                  <p style={{ fontSize: 10 }}>{url[0].name}</p>
                                </div>
                              </BigPicture>
                            ) : (
                              <a
                                href={URL.createObjectURL(url[0])}
                                download
                                className="flex justify-content-center"
                                style={{
                                  position: "relative",
                                  flexDirection: "column",
                                }}
                              >
                                <img
                                  src={require("../images/pdf.png")}
                                  style={{ width: "70px" }}
                                  alt="test"
                                />
                                <p>{url[0].name}</p>
                              </a>
                            )}
                          </div>
                        );
                      } else if (url[0].type.match("image")) {
                        return (
                          <div className="col-3">
                            <i
                              className="material-icons"
                              onClick={() => this.removeAttach(url, item)}
                            >
                              close
                            </i>
                            <BigPicture
                              type="image"
                              src={URL.createObjectURL(url[0])}
                            >
                              <div
                                className="flex align-items-center justify-content-center"
                                style={{
                                  position: "relative",
                                  flexDirection: "column",
                                }}
                              >
                                <img
                                  src={URL.createObjectURL(url[0])}
                                  alt="step"
                                  style={{ width: 50 }}
                                  className="img-responsive"
                                />
                                <p style={{ fontSize: 10 }}>{url[0].name}</p>
                              </div>
                            </BigPicture>
                          </div>
                        );
                      } else if (url[0].type.match("video")) {
                        return (
                          <div className="col-3">
                            <i
                              className="material-icons"
                              onClick={() => this.removeAttach(url, item)}
                            >
                              close
                            </i>
                            <BigPicture
                              type="video"
                              src={URL.createObjectURL(url[0])}
                            >
                              <div
                                className="flex align-items-center justify-content-center"
                                style={{
                                  position: "relative",
                                  flexDirection: "column",
                                }}
                              >
                                <img
                                  src={require("../images/video.png")}
                                  style={{ width: 50 }}
                                  alt="step"
                                  className="img-responsive"
                                />
                                <p style={{ fontSize: 10 }}>{url[0].name}</p>
                              </div>
                            </BigPicture>
                          </div>
                        );
                      } else if (url[0].name.match("xls")) {
                        return (
                          <div className="col-3">
                            <i
                              className="material-icons"
                              onClick={() => this.removeAttach(url, item)}
                            >
                              close
                            </i>
                            <a
                              className="flex align-items-center justify-content-center"
                              href={URL.createObjectURL(url[0])}
                              download={url[0].name}
                            >
                              <div style={{ textAlign: "center" }}>
                                <img
                                  src={require("../images/excel.png")}
                                  style={{ width: 50 }}
                                  alt="step"
                                  className="img-responsive"
                                />
                                <p style={{ fontSize: 10 }}>{url[0].name}</p>
                              </div>
                            </a>
                          </div>
                        );
                      } else if (url[0].name.match(".doc")) {
                        return (
                          <div className="col-3">
                            <i
                              className="material-icons"
                              onClick={() => this.removeAttach(url, item)}
                            >
                              close
                            </i>
                            <a
                              className="flex align-items-center justify-content-center"
                              href={URL.createObjectURL(url[0])}
                              download={url[0].name}
                            >
                              <div style={{ textAlign: "center" }}>
                                <img
                                  src={require("../images/word.png")}
                                  style={{ width: 50 }}
                                  alt="step"
                                  className="img-responsive"
                                />
                                <p style={{ fontSize: 10 }}>{url[0].name}</p>
                              </div>
                            </a>
                          </div>
                        );
                      }
                    })
                  : ""}
              </div>

              <div className="form-group" style={{ textAlign: "center" }}>
                <label
                  for={"test_" + index}
                  style={{
                    border: "1px solid #0c0c0c",
                    width: "100%",
                    padding: 10,
                  }}
                >
                  <i style={{ fontSize: 30 }} class="material-icons">
                    add
                  </i>
                </label>
                <input
                  type="file"
                  style={{ display: "none" }}
                  className="form-control"
                  id={"test_" + index}
                  key={this.state.theInputKey || ""}
                  onChange={(e) => this.uploadMultipleFiles(e, item, index)}
                />
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      );
    } else if (item.type === "useraudio") {
      return (
        <div
          className="card horizontal"
          style={{
            border:
              this.state.requiredCheck === true
                ? "1px solid #fff"
                : item.required === "1"
                ? item.value === ""
                  ? "1px solid red"
                  : "1px solid #fff"
                : "1px solid #fff",
          }}
        >
          <div className="card-stacked">
            <div className="card-content">
              <p>{item.name}</p>
              <div
                className="flex justify-content-end"
                style={{ position: "relative" }}
              >
                {item.value === "" ? (
                  ""
                ) : (
                  <img
                    src={require("../images/clear.png")}
                    onClick={() => this.removeSign(item, index)}
                    className="img-responsive"
                    style={{
                      width: 12,
                      position: "absolute",
                      top: 4,
                      right: 4,
                    }}
                    alt="demo"
                  />
                )}
              </div>
              <div
                className="flex justify-content-center"
                style={{ border: "1px solid", padding: 10 }}
              >
                {item.value === "" ? (
                  <img
                    onClick={() => this.takeAudio(item, index)}
                    src={require("../images/microphone.png")}
                    alt="camera"
                  />
                ) : (
                  <BigPicture type="audio" src={item.value}>
                    <div className="flex justify-content-center">
                      <img
                        src={require("../images/1200px-Speaker_Icon.png")}
                        style={{ width: "70px" }}
                        alt="test"
                      />
                    </div>
                  </BigPicture>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else if (item.type === "uservideo") {
      return (
        <div className="card horizontal">
          <div className="card-stacked">
            <div className="card-content">
              <p>{item.name}</p>
              <div
                className="flex justify-content-end"
                style={{ position: "relative" }}
              >
                {item.value === "" ? (
                  ""
                ) : (
                  <img
                    src={require("../images/clear.png")}
                    onClick={() => this.removeSign(item, index)}
                    className="img-responsive"
                    style={{
                      width: 12,
                      position: "absolute",
                      top: 4,
                      right: 4,
                    }}
                    alt="demo"
                  />
                )}
              </div>
              <div
                className="flex justify-content-center"
                style={{ border: "1px solid", padding: 10 }}
              >
                {
                  item.value === "" ? (
                    <img
                      onClick={() => this.takeVideo(item, index)}
                      src={require("../images/video.png")}
                      alt="camera"
                    />
                  ) : (
                    <FancyVideo
                      source={item.value}
                      poster={require("../images/white.jpg")}
                      id={Math.random().toString(36).substring(7)}
                    />
                  )
                  // <video controls controlsList="nodownload" style={{ width: '100%' }}>
                  //     <source src={item.value}></source>
                  // </video>
                }
              </div>
            </div>
          </div>
        </div>
      );
    } else if (item.type === "textbox") {
      return (
        <div
          className="card horizontal"
          style={{
            border:
              this.state.requiredCheck === true
                ? "1px solid #D3D3D3"
                : item.required === "1"
                ? item.value === ""
                  ? "1px solid red"
                  : "1px solid #D3D3D3"
                : "1px solid #D3D3D3",
          }}
        >
          <div className="card-stacked">
            <div className="card-content">
              <p>{item.name}</p>
              {step === 0 ? (
                <textarea
                  rows="6"
                  style={{
                    width: "100%",
                    borderColor:
                      this.state.requiredCheck === true
                        ? "#D3D3D3"
                        : item.required === "1"
                        ? item.value === ""
                          ? "red"
                          : "#D3D3D3"
                        : "#D3D3D3",
                  }}
                >
                  {item.value}
                </textarea>
              ) : (
                <textarea
                  rows="6"
                  style={{
                    width: "100%",
                    borderColor:
                      this.state.requiredCheck === true
                        ? "#D3D3D3"
                        : item.required === "1"
                        ? item.value === ""
                          ? "red"
                          : "#D3D3D3"
                        : "#D3D3D3",
                  }}
                  onChange={(event) => this.textInputInfo(event, index)}
                >
                  {item.value}
                </textarea>
              )}
            </div>
          </div>
        </div>
      );
    } else if (item.type === "poll") {
      return (
        <div
          className="card horizontal"
          style={{
            border:
              this.state.requiredCheck === true
                ? "1px solid #fff"
                : this.pollValueCheck(item.options) === false
                ? "1px solid red"
                : "1px solid #fff",
          }}
        >
          <div className="card-stacked">
            <div className="card-content">
              <p>{item.value}</p>
              {item.options.map((data, i) => {
                return (
                  <div
                    className="flex align-items-center"
                    onClick={() => this.checkBoxAction(data, item, i, step)}
                  >
                    <div
                      style={{
                        backgroundColor: data.checked
                          ? data.name === "Yes"
                            ? "#be1f24"
                            : data.name === "No"
                            ? "#1bbc9b"
                            : "#ffa500"
                          : "#fff",
                        borderRadius: 20,
                        padding: 5,
                        display: "flex",
                        alignItems: "center",
                        marginRight: 10,
                      }}
                    >
                      {this.checkbox_icon(item.is_multiselect, data.checked)}
                    </div>
                    <div>{data.name}</div>
                  </div>
                );
              })}

              <div className="mt-3">
                <textarea
                  rows="1"
                  className="form-control"
                  placeholder="Any Remarks"
                  style={{ width: "100%" }}
                  value={item.remarks}
                ></textarea>
              </div>
              {item.files ? (
                <>
                  <p style={{ marginTop: 10 }}>Certificates</p>
                  <div className="row">
                    {item.files.map((t, i) => {
                      return (
                        <div className="col-3">
                          <BigPicture type="iframe" src={t}>
                            <div
                              className="flex  justify-content-center align-items-center"
                              style={{
                                position: "relative",
                                flexDirection: "column",
                              }}
                            >
                              View File
                            </div>
                          </BigPicture>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      );
    } else if (item.type === "multimedia") {
      if (item.filetype === "application/pdf") {
        return (
          <div className="card horizontal">
            <div className="card-stacked">
              <div className="card-content">
                <BigPicture
                  type="iframe"
                  src={
                    "https://mpower-s3.s3.ap-southeast-1.amazonaws.com/uploads/multimedia/" +
                    item.value.replace(/-| /g, "_")
                  }
                >
                  <div
                    className="flex justify-content-center"
                    style={{ position: "relative" }}
                  >
                    <img
                      src={require("../images/pdf.png")}
                      style={{ width: "70px" }}
                      alt="test"
                    />
                  </div>
                </BigPicture>
              </div>
            </div>
          </div>
        );
      } else if (item.filetype.match("image")) {
        return (
          <div className="card horizontal">
            <div className="card-stacked">
              <div className="card-content">
                <BigPicture
                  type="image"
                  src={
                    "https://mpower-s3.s3.ap-southeast-1.amazonaws.com/uploads/multimedia/" +
                    item.value.replace(/-| /g, "_")
                  }
                >
                  <div className="flex justify-content-center">
                    <img
                      src={
                        "https://mpower-s3.s3.ap-southeast-1.amazonaws.com/uploads/multimedia/" +
                        item.value.replace(/-| /g, "_")
                      }
                      alt="step"
                      className="img-responsive"
                    />
                  </div>
                </BigPicture>
              </div>
            </div>
          </div>
        );
      } else if (item.filetype.match("video")) {
        return (
          <div className="card horizontal">
            <div className="card-stacked">
              <div className="card-content">
                <BigPicture
                  type="video"
                  src={
                    "https://mpower-s3.s3.ap-southeast-1.amazonaws.com/uploads/multimedia/" +
                    item.value.replace(/-| /g, "_")
                  }
                >
                  <div className="flex justify-content-center">
                    <img
                      src={require("../images/video.png")}
                      alt="step"
                      className="img-responsive"
                    />
                  </div>
                </BigPicture>
              </div>
            </div>
          </div>
        );
      }
    } else if (item.type === "mcq") {
      return (
        <div
          className="card horizontal"
          style={{
            border:
              this.state.requiredCheck === true
                ? "1px solid #fff"
                : this.mcqValueCheck(item.options) === false
                ? "1px solid red"
                : "1px solid #fff",
          }}
        >
          <div className="card-stacked">
            <div className="card-content">
              <p>{item.value}</p>
              {item.options.map((data, indexMCQ) => {
                return (
                  <div
                    className="flex"
                    onClick={() => this.MCQAction(data, item, indexMCQ, index)}
                  >
                    <div>
                      {this.checkbox_icon(
                        item.is_multiselect,
                        data.imcaChecked
                      )}
                    </div>
                    <div>{data.name}</div>
                  </div>
                );
              })}
              {this.mcqValueCheck(item.options) === false ? null : (
                <div className="flex justify-content-center mt-2">
                  {item.insight_options.map((data, i) => {
                    return (
                      <div
                        style={{
                          borderRadius: 25,
                          backgroundColor:
                            data.checked === "1"
                              ? data.name === "guess"
                                ? "#005188"
                                : "#005188"
                              : "#e8e8e8",
                        }}
                        onClick={() => this.insightAction(data, item, i, index)}
                      >
                        <div
                          style={{
                            padding: "2px 10px",
                            fontSize: 14,
                            color: data.checked === "1" ? "#fff" : "#000",
                            fontWeight: data.checked === "1" ? "500" : "300",
                          }}
                        >
                          {" "}
                          {data.name === "sure"
                            ? " I am sure "
                            : " I am not sure "}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else if (item.type === "sign") {
      return (
        <div
          className="card horizontal"
          style={{
            border:
              this.state.requiredCheck === true
                ? "1px solid #fff"
                : item.required === "1"
                ? item.value === ""
                  ? "1px solid red"
                  : "1px solid #fff"
                : "1px solid #fff",
          }}
        >
          <div className="card-stacked">
            <div className="card-content">
              <p>{item.name}</p>
              <div
                className="flex justify-content-end"
                style={{ position: "relative" }}
              >
                {item.value === "" ? (
                  ""
                ) : step === 0 ? (
                  ""
                ) : (
                  <img
                    src={require("../images/clear.png")}
                    onClick={() => this.removeSign(item, index)}
                    className="img-responsive"
                    style={{
                      width: 12,
                      position: "absolute",
                      top: 4,
                      right: 4,
                    }}
                    alt="demo"
                  />
                )}
              </div>
              <div
                className="flex justify-content-center"
                style={{ border: "1px solid", padding: 10 }}
              >
                {item.value === "" ? (
                  <img
                    onClick={() => this.takeSign(item, index)}
                    src={require("../images/signature.png")}
                    className="img-responsive"
                    style={{ width: 70 }}
                    alt="camera"
                  />
                ) : step === 1 ? (
                  this.props.location.state.type === "assessor" ? (
                    <img
                      src={
                        "https://mpower-s3.s3.amazonaws.com/uploads/input_sign/" +
                        item.value
                      }
                      alt="camera"
                      className="img-responsive"
                    />
                  ) : (
                    <img
                      src={item.value}
                      alt="camera"
                      className="img-responsive"
                    />
                  )
                ) : (
                  <img
                    src={
                      "https://mpower-s3.s3.amazonaws.com/uploads/input_sign/" +
                      item.value
                    }
                    alt="camera"
                    className="img-responsive"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  _back(i) {
    this.setState({ currentStep: i - 1 });
    window.scrollTo({
      top: 0,
    });
  }
 async _next(i, total) {
    this.setState({
      requiredCheck: true,
    });
   
   let check = await this.dynamicCheckFunction(
      this.props.data[this.state.currentStep].microstep_data
    );
    this.props.data[3].microstep_data[11].value = "";
    this.props.data[3].microstep_data[9].value = "";
    this.props.data[2].microstep_data[11].value = "";
    this.props.data[2].microstep_data[9].value = "";
    this.props.data[2].microstep_data[10].allfiles = [];
    setTimeout(() => {
      if (check === true) {
        if (i !== total - 1) {
          this.setState({ currentStep: i + 1 });
        }
        if (i === total - 1) {
          this.alertAction();
        }
      } else {
        alert("Please fill all the required fields");
      }
    }, 100);
    window.scrollTo({
      top: 0,
    });
  }
  async dynamicCheckFunction(tt) {
     console.log("Dynamic check func", tt)
     let check =true
   
    
    let textboxData = tt.filter(
      (data) => data.type === "textbox" && data.required === "1"
    );
    if (textboxData.length === 0) {
      this.setState({
        requiredCheck: true,
      });
      check =true
    } else {
      
      if (textboxData.some((data) => data.value === "")) {
        this.setState({
          requiredCheck: false,
        });
        check =false
      } else {
        this.setState({
          requiredCheck: true,
        });
        check =true
      }
    }
    
    let signData = tt.filter(
      (data) => data.type === "sign" && data.required === "1"
    );
    if (signData.length === 0) {
      this.setState({
        requiredCheck: true,
      });
      check =true
    } else {
       console.log(signData)
      if (signData.some((data) => data.value === "")) {
        console.log('i')

        this.setState({
          requiredCheck: false,
        });
        check =false
      } else {
        this.setState({
          requiredCheck: true,
        });
        check =true
      }
    }

    console.log(check)

    return check

    // let pollData = tt.filter(
    //   (data) => data.type === "poll" && data.required === "1"
    // );
    // if (pollData.length === 0) {
    //   this.setState({
    //     requiredCheck: true,
    //   });
    // } else {
    //   console.log(pollData);
    //   if (pollData.some((data) => data.user_ans === "")) {
    //     this.setState({
    //       requiredCheck: false,
    //     });
    //   } else {
    //     this.setState({
    //       requiredCheck: true,
    //     });
    //   }
    // }

    // let imageData = tt.filter(
    //   (data) => data.type === "userimage" && data.required === "1"
    // );
    // if (imageData.length === 0) {
    //   this.setState({
    //     requiredCheck: true,
    //   });
    // } else {
    //   // console.log(imageData)
    //   if (imageData.some((data) => data.value === "")) {
    //     this.setState({
    //       requiredCheck: false,
    //     });
    //   } else {
    //     this.setState({
    //       requiredCheck: true,
    //     });
    //   }
    // }

    
    //   let location = tt.filter(data => data.type === "location")
    //   if (location.some(data => data.value === "")) {
    //     this.setState({
    //       requiredCheck: false
    //     })
    //   } else {
    //     this.setState({
    //       requiredCheck: true
    //     })
    //   }
    //   let project = tt.filter(data => data.type === "project")
    //   if (project.some(data => data.value === "")) {
    //     this.setState({
    //       requiredCheck: false
    //     })
    //   } else {
    //     this.setState({
    //       requiredCheck: true
    //     })
    //   }
    //   let date = tt.filter(data => data.type === "date")
    //   if (date.some(data => data.value === "")) {
    //     this.setState({
    //       requiredCheck: false
    //     })
    //   } else {
    //     this.setState({
    //       requiredCheck: true
    //     })
    //   }
    //   let start_time = tt.filter(data => data.type === "start_time")
    //   if (start_time.some(data => data.value === "")) {
    //     this.setState({
    //       requiredCheck: false
    //     })
    //   } else {
    //     this.setState({
    //       requiredCheck: true
    //     })
    //   }
    //   let end_time = tt.filter(data => data.type === "end_time")
    //   if (end_time.some(data => data.value === "")) {
    //     this.setState({
    //       requiredCheck: false
    //     })
    //   } else {
    //     this.setState({
    //       requiredCheck: true
    //     })
    //   }
    //   let job_site = tt.filter(data => data.type === "job_site")
    //   if (job_site.some(data => data.value === "")) {
    //     this.setState({
    //       requiredCheck: false
    //     })
    //   } else {
    //     this.setState({
    //       requiredCheck: true
    //     })
    //   }
    //   let workers = tt.filter(data => data.type === "worker")
    //   if (workers.some(data => data.value === "0")) {
    //     this.setState({
    //       requiredCheck: false
    //     })
    //   } else {
    //     this.setState({
    //       requiredCheck: true
    //     })
    //   }
    //   let description = tt.filter(data => data.type === "description")
    //   if (description.some(data => data.value === "")) {
    //     this.setState({
    //       requiredCheck: false
    //     })
    //   } else {
    //     this.setState({
    //       requiredCheck: true
    //     })
    //   }
  }
  acuizenSubmitAction() {
    this.setState({
      loading: true,
      submitStatus: 3,
    });
    let formData = new FormData();
    formData.append("intermediate_id", this.state.setApprover);
    formData.append("session_id", this.props.session_id);
    formData.append("permit_id", this.props.permit);
    formData.append("ams_dri", localStorage.getItem("firstname"));

    axios({
      method: "post",
      url: API.SetIntermediateAction,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
      mode: "no-cors",
    }).then((res) => {
      this.submitButtonApi(this.props.data, this.props.data);
    });

    //this.submitButtonApi(this.props.data, this.props.data)
  }

  getFilename = (dataUrl) => {
    let filename = Math.random()
      .toString(36)
      .replace("0.", "image_" || "");
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];

    return filename + "." + mime.split("/").pop();
  };
  dataURItoFile = (dataURI, filename) => {
    var byteString = atob(dataURI.split(",")[1]);
    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var dw = new DataView(ab);
    for (var i = 0; i < byteString.length; i++) {
      dw.setUint8(i, byteString.charCodeAt(i));
    }

    // write the ArrayBuffer to a blob, and you're done
    return new File([ab], filename, { type: mimeString });
  };
  submitButtonApi(response, element) {
    var req = [];
    var req1 = [];
    var req2 = [];
    var multi = [];
    var userImageIndex = 0;
    var userSignIndex = 0;
    var userAudioIndex = 0;
    var userVideoIndex = 0;
    // if (this.state.file.length > 0) {
    //     this.state.file.forEach((next, i) => {
    //         let obj2 = {
    //             [`step_id[${element[1].microstep_id}][multimedia][${i}]`]: next

    //         }
    //         multi.push(obj2)

    //         element[1].microstep_data.push({ 'type': 'multimedia', 'value': next[0].name, 'filetype': next[0].type })
    //     })
    // }
    element[1].microstep_data.forEach((ele, ind) => {
      if (ele.type === "userimage") {
        ele.allfiles = [];
        if (ele.files) {
          if (ele.files.length > 0) {
            ele.files.forEach((next, i) => {
              let obj2 = {
                [`step_id[${element[1].microstep_id}][multimedia][${userImageIndex}]`]:
                  next,
              };
              multi.push(obj2);
              if (next[0].name.match("xls") || next[0].name.match("doc")) {
                let lastIndex = next[0].name.lastIndexOf(".");

                let name =
                  next[0].name.slice(0, lastIndex) +
                  "_" +
                  this.state.unique_id +
                  "." +
                  next[0].name.slice(lastIndex + 1);
                console.log(name);
                ele.allfiles.push({
                  type: "multimedia",
                  value: name,
                  filetype: next[0].type,
                });
              } else {
                ele.allfiles.push({
                  type: "multimedia",
                  value: next[0].name,
                  filetype: next[0].type,
                });
              }
              // ele.allfiles.push({ 'type': 'multimedia', 'value': next[0].name, 'filetype': next[0].type })
              userImageIndex = userImageIndex + 1;
            });
          }
          delete ele.files;
        }
      } else if (ele.type === "sign") {
        if (ele.value === "") {
        } else {
          let filename = this.getFilename(ele.value);
          let obj = {
            [`step_id[${element[1].microstep_id}][sign][${userSignIndex}]`]:
              ele.value,
            filename: filename,
          };
          ele.value = filename;
          req.push(obj);
          userSignIndex = userSignIndex + 1;
        }
      } else if (ele.type === "useraudio") {
        if (ele.value === "") {
        } else {
          let filename =  new Date('dd-mm-yyyyhh-mm') + ".mp3";
          let obj1 = {
            [`step_id[${element[1].microstep_id}][input_audio][${userAudioIndex}]`]:
              ele.blob,
            filename: filename,
          };

          console.log(filename);
          ele.value = filename;
          req1.push(obj1);
          userAudioIndex = userAudioIndex + 1;
          delete ele.blob;
        }
      } else if (ele.type === "uservideo") {
        if (ele.value === "") {
        } else {
          let filename =  new Date('dd-mm-yyyyhh-mm') + ".mp4";
          let obj2 = {
            [`step_id[${element[1].microstep_id}][input_video][${userVideoIndex}]`]:
              ele.blob,
            filename: filename,
          };
          console.log(filename);
          ele.value = filename;
          req2.push(obj2);
          userVideoIndex = userVideoIndex + 1;
          delete ele.blob;
        }
      }
    });
    userImageIndex = 0;
    userSignIndex = 0;
    userAudioIndex = 0;
    userVideoIndex = 0;

    console.log(req);
    setTimeout(() => {
      this.uploadApiCallAction(req, req1, req2, multi, response, element);
    }, 1000);
  }
  async uploadApiCallAction(req, req1, req2, multi, data, page) {
    // console.log(page);
    // console.log(page[0].microstep_data[1].value)
    let formData = new FormData();
    if (multi.length >= 0) {
      multi.forEach((element, index) => {
        console.log(element);
        var key = Object.keys(element)[0];
        console.log(key);
        formData.append(`${key}`, element[key][0]);
      });
    }
    if (req.length >= 0) {
      req.forEach((element, index) => {
        console.log(element);
        var key = Object.keys(element)[0];
        var key1 = Object.keys(element)[1];
        var filename = element[key1];
        var value = this.dataURItoFile(element[key], filename);
        console.log(value);
        formData.append(`${key}`, value);
      });
    }
    if (req1.length >= 0) {
      req1.forEach((element, index) => {
        console.log(element);
        var key = Object.keys(element)[0];
        var key1 = Object.keys(element)[1];
        var filename = element[key1];
        var value = new File([element[key]], filename, {
          type: "audio/mp3",
          lastModified: Date.now(),
        });
        console.log(value);
        formData.append(`${key}`, value);
      });
    }
    if (req2.length >= 0) {
      req2.forEach((element, index) => {
        console.log(element);
        var key = Object.keys(element)[0];
        var key1 = Object.keys(element)[1];
        var filename = element[key1];
        var value = new File([element[key]], filename, {
          type: "video/mp4",
          lastModified: Date.now(),
        });
        console.log(value);
        formData.append(`${key}`, value);
      });
    }

    formData.append("user_id", localStorage.getItem("token"));

    formData.append("input_json", JSON.stringify(data));
    formData.append("session_id", this.props.session_id);
    // formData.append('lat', latitude)
    // formData.append('lon', longitude)
    formData.append("status", "4");
    formData.append("token_id", localStorage.getItem("token"));
    formData.append("step_id", page[0].microstep_id);
    formData.append("duration", this.state.seconds);
    formData.append("project", page[0].microstep_data[1].value);
    formData.append("location", page[0].microstep_data[0].value);
    formData.append("permit_date", page[0].microstep_data[4].value);
    formData.append("start_time", page[0].microstep_data[2].value);
    formData.append("end_time", page[0].microstep_data[3].value);
    formData.append("new_permit_id", this.props.permit);
    // var lon = page[0].microstep_data[5].value.longitude
    // var latLon = lat+","+lon
    formData.append("job_site", "");
    // formData.append('worker', page[0].microstep_data[6].value)
    // formData.append('supervisor', page[0].microstep_data[7].value)
    formData.append("description", "");
    formData.append("unique_id", this.state.unique_id);
    axios({
      method: "post",
      url: API.SubmitUnit,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
      mode: "no-cors",
    }).then((res) => {
      this.setState({ loading: false });
      this.callAssignedApi();
    });
  }
  userID() {
    const userID = localStorage.getItem("token");
    const ID = userID.split("@");
    console.log(ID[0]);
    return ID[0];
  }
  setApprover(e, item) {
    this.setState({
      setApprover: e.target.value,
      selectedApprover: item.name,
    });
  }
  alertAction() {
    console.log(this.state.approverList);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="row">
            <div
              className="col-12"
              style={{
                width: 300,
                background: "#fff",
                padding: 20,
                boxShadow: "0px 0px 12px 3px #dbdbdb",
                height: 400,
                overflowY: "scroll",
              }}
            >
              <h3 className="mb-2">Select EHS</h3>
              <div className="form-group">
                {this.state.approverList.map((item, i) => {
                  return item.id === this.userID() ? (
                    ""
                  ) : item.id === this.props.location.state.applicant ? (
                    ""
                  ) : (
                    <div className="flex align-items-center">
                      <input
                        type="radio"
                        name="assessor"
                        value={item.id}
                        onChange={(e) => this.setApprover(e, item)}
                        style={{ marginRight: 14 }}
                      />
                      {item.name}
                    </div>
                  );
                })}
              </div>
              <div className="flex justify-content-between">
                <span style={{ color: "red", fontSize: 12 }} onClick={onClose}>
                  CANCEL
                </span>
                <span
                  style={{ color: "#005284", fontSize: 14 }}
                  onClick={() => {
                    this.acuizenSubmitAction();
                    onClose();
                  }}
                >
                  {" "}
                  SUBMIT{" "}
                </span>
              </div>
            </div>
          </div>
        );
      },
    });
  }
  callAssignedApi() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="row">
            <div
              className="col-12"
              style={{
                width: 300,
                background: "#fff",
                padding: 20,
                boxShadow: "0px 0px 12px 3px #dbdbdb",
              }}
            >
              <h3 className="">AcuiZen</h3>
              <p>PTW #{this.props.permit} submitted to EHS for review.</p>
              <div className="flex justify-content-end">
                <span
                  style={{ color: "#005284", fontSize: 14 }}
                  onClick={() => {
                    this.props.history.push("/assessor");
                    onClose();
                  }}
                >
                  {" "}
                  OK{" "}
                </span>
              </div>
            </div>
          </div>
        );
      },
    });
  }
  callRerurnApi() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="row">
            <div
              className="col-12"
              style={{
                width: 300,
                background: "#fff",
                padding: 20,
                boxShadow: "0px 0px 12px 3px #dbdbdb",
              }}
            >
              <h3 className="">AcuiZen</h3>
              <p>
                Are you sure you want to return this PTW #{this.props.permit} to
                Contractor DRI: {this.props.location.state.name}?`
              </p>
              <textarea
                className="form-control"
                placeholder="Remarks"
                onChange={(event) =>
                  this.setState({ remarks: event.target.value })
                }
              >
                {this.state.remarks}
              </textarea>
              <div className="flex justify-content-between mt-3">
                <span style={{ color: "red", fontSize: 12 }} onClick={onClose}>
                  CANCEL
                </span>
                <span
                  style={{ color: "#005284", fontSize: 14 }}
                  onClick={() => {
                    this.returnToApplicant(onClose);
                  }}
                >
                  {" "}
                  SUBMIT{" "}
                </span>
              </div>
            </div>
          </div>
        );
      },
    });
  }
  projectSelected(text) {
    if (text !== " ") {
      if (text === "Other") {
        this.setState({ locationDropValue: text, location: "" });
      } else {
        this.setState({ locationDropValue: text, location: text });
        this.props.data[0].microstep_data[1].value = text;
      }
    }
  }
  locationSelected(e, data, index) {
    console.log(e.target.value);
    if (e.target.value !== " ") {
      let projectName = data[index - 1].label;
      console.log(projectName);

      if (projectName === "Other") {
        let locations = [];
        let obj = {
          value: "Other",
        };
        locations.push(obj);
        this.setState({
          projectDropValue: projectName,
          project: "",
          locationList: locations,
        });
      } else {
        let locations = [];

        this.state.locationData[e.target.value].forEach((data) => {
          let obj = {
            value: data,
          };
          locations.push(obj);
        });
        let obj = {
          value: "Other",
        };
        locations.push(obj);
        this.setState({
          projectDropValue: projectName,
          project: projectName,
          locationList: locations,
        });

        this.props.data[0].microstep_data[0].value = projectName;
      }
    }
  }
  returnToApplicant(onClose) {
    this.setState({ loading: true });
    if (this.state.remarks.length === 0) {
      alert("Please Enter Remarks");
    } else {
      onClose();

      let approveformData = new FormData();
      approveformData.append("session_id", this.props.session_id);
      approveformData.append("remarks", this.state.remarks);
      approveformData.append("user_type", "ams DRI");
      approveformData.append("name", localStorage.getItem("firstname"));
      axios({
        method: "post",
        url: API.AssessorRevoke,
        data: approveformData,
        headers: {
          "content-type": "multipart/form-data",
        },
        mode: "no-cors",
      }).then((res) => {
        this.setState({ loading: false });
        this.callRejectedAlert();
      });
    }
  }
  callRejectedAlert() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="row">
            <div
              className="col-12"
              style={{
                width: 300,
                background: "#fff",
                padding: 20,
                boxShadow: "0px 0px 12px 3px #dbdbdb",
              }}
            >
              <h3 className="">AcuiZen</h3>
              <p>
                PTW #{this.props.permit} has been returned to Contractor DRI:{" "}
                {this.props.location.state.name}.
              </p>
              <div className="flex justify-content-end">
                <span
                  style={{ color: "#005284", fontSize: 14 }}
                  onClick={() => {
                    this.props.history.goBack();
                    this.props.getMictoDataUnmount();
                    onClose();
                  }}
                >
                  {" "}
                  OK{" "}
                </span>
              </div>
            </div>
          </div>
        );
      },
    });
  }
  mapClick(e) {
    this.setState({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  }
  doneMap() {
    const obj = {
      latitude: this.state.lat,
      longitude: this.state.lng,
    };
    this.props.data[0].microstep_data[5].value = obj;
    this.setState({ mapvisible: false });
  }
  uploadMultipleFiles(e, item, index) {
    console.log(e.target.files);
    console.log(item);
    console.log(index);
    // this.fileObj.push(e.target.files)
    if (!item.files) {
      item.files = [];
      this.forceUpdate();
    }

    // this.setState({ file: [null] })
    // for (let i = 0; i < this.fileObj.length; i++) {
    //     this.fileArray.push(URL.createObjectURL(this.fileObj[i][0]))
    // }
    let randomString = Math.random().toString(36);

    this.setState({
      theInputKey: randomString,
    });
    item.files.push(e.target.files);
    // this.setState({ file: this.fileObj })
  }
  removeAttach(url, item) {
    console.log(url);
    const id = item.files.indexOf(url); // 2
    item.files.splice(id, 1);
    //  item.splice(i,1)
  }
  render() {
    console.log(this.props);
    const length = 2;
    const datalength = 100 / 2;
    return (
      <div className="">
        <nav
          class="navbar navbar-top navbar-expand navbar-dark  border-bottom"
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            zIndex: 1,
          }}
        >
          <div class="container pad_z">
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <div className="col-sm-3 pad_z ">
                {/* <i className="material-icons back-btn grey-text" onClick={() => this.props.history.goBack()}>arrow_back</i> */}
              </div>
              <div className="col-sm-6 text-center pad_z">
                {" "}
                <img
                  src={require("../images/logo_new.png")}
                  alt="az_logo"
                  className="responsive-img"
                  style={{ height: 50 }}
                />
              </div>
              <div className="col-sm-3 text-right  pad_z vertical-align justify-content-end">
                <i
                  class="material-icons"
                  onClick={() => {
                    this.props.history.goBack();
                    this.props.getMictoDataUnmount();
                  }}
                >
                  close
                </i>
              </div>
            </div>
          </div>
        </nav>
        <div style={{ height: 82 }}></div>

        <div className="container">
          <div className="row">
            <div className="col-12 mb-5">
              <h4 className="col-12 pad_z mt-1" id="unit_name">
                {" "}
                {this.props.title}
              </h4>
              <p className="col-12 pad_z mt-1">#{this.props.permit}</p>
              <div className="col-12 steps pad_z">
                {[1, 2].map((item, i) => {
                  if (this.state.currentStep >= i) {
                    return (
                      <span
                        key={i}
                        className={
                          this.state.currentStep === i
                            ? "active step"
                            : "done step"
                        }
                        style={{ width: datalength + "%" }}
                      ></span>
                    );
                  } else {
                    return (
                      <span
                        key={i}
                        className={
                          this.state.currentStep === i ? "active step" : "step"
                        }
                        style={{ width: datalength + "%" }}
                      ></span>
                    );
                  }
                })}
              </div>
              <div className="col-12 pad_z">
                {this.props.data.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="microstep col-12 pad_z"
                      id={"#" + i}
                      style={{
                        display:
                          this.state.currentStep === i ? "block" : "none",
                      }}
                    >
                      <h4>{item.microstep_title}</h4>

                      {this.applicantOption(this.props.data[0], i)}

                      {item.microstep_data.map((item1, j) => {
                        return (
                          <div key={j} className="col-12 pad_z">
                            {this.layout(item1, j, i)}
                          </div>
                        );
                      })}
                      {/* {i===1 ?
                                             this.props.location.state.type ==='assessor' ? "":
                                              <div className="card horizontal" style={{ border: this.state.requiredCheck === true ? '1px solid #fff' : item.required === "1" ? item.value === '' ? '1px solid red' : '1px solid #fff' : '1px solid #fff' }}>

<div className="card-stacked">
    <div className="card-content">
    <p>Click + to add multiple media files.</p>
    <div className="form-group multi-preview">
                    {this.state.file.map((url,i) => {
                        if(url[0].type ==='application/pdf'){
                    return(
                        <BigPicture
                        type="iframe"
                        src={URL.createObjectURL(url[0])}
                    >
                        <div className="flex justify-content-center" style={{ position: 'relative' }}>
                            
                             <img src={require('../images/pdf.png')} style={{ width: '70px' }} alt="test" />


                        </div>
                    </BigPicture>
                    )
                    }else if(url[0].type.match('image')){
                        return(
                            <BigPicture
                            type="image"
                            src={URL.createObjectURL(url[0])}
                        >
                            <div className="flex justify-content-center"><img src={URL.createObjectURL(url[0])} alt="step" className="img-responsive" /></div>
                        </BigPicture>
                        )
                    }else if(url[0].type.match('video')){
                        return(
                            
                            
                                        <BigPicture
                                        type="video"
                                        src={URL.createObjectURL(url[0])}
                                    >
                                        <div className="flex justify-content-center">
                                            <img src={require('../images/video.png')} alt="step" className="img-responsive" /></div>
                                    </BigPicture>
                                       
                        )
                    }
                     })}
                </div>

                <div className="form-group" style={{textAlign:'center'}}>
                    <label for="test" style={{border:'1px solid #0c0c0c',width:'100%',padding:10}}><i style={{fontSize:30}} class="material-icons">
add
</i></label>
                    <input type="file" style={{display:'none'}} className="form-control" id="test" key={this.state.theInputKey || '' } onChange={(e)=>this.uploadMultipleFiles(e)}  />
                </div>
                
        </div></div></div> : ''} */}
                      {this.props.location.state.type === "assessor" ? (
                        <div className="col-12 step-btn ">
                          <div className="container flex pad_z">
                            {this.state.currentStep === 0 ? (
                              ""
                            ) : (
                              <button
                                type="button"
                                onClick={() => this._back(i)}
                              >
                                <i
                                  className="material-icons left"
                                  style={{ width: 25 }}
                                >
                                  arrow_backward
                                </i>
                                Back
                              </button>
                            )}
                            {this.state.currentStep !== length - 1 ? (
                              <button
                                type="button"
                                onClick={() => this._next(i, length)}
                              >
                                Next
                                <i className="material-icons right">
                                  arrow_forward
                                </i>
                              </button>
                            ) : null}
                          </div>
                        </div>
                      ) : (
                        <div className="col-12 step-btn ">
                          <div className="container flex pad_z">
                            {this.state.currentStep === 0 ? (
                              ""
                            ) : (
                              <button
                                type="button"
                                onClick={() => this._back(i)}
                              >
                                <i
                                  className="material-icons left"
                                  style={{ width: 25 }}
                                >
                                  arrow_backward
                                </i>
                                Back
                              </button>
                            )}

                            {this.state.currentStep === length - 1 ? (
                              <button
                                type="button"
                                className="bg-red"
                                onClick={() => this.callRerurnApi()}
                              >
                                Return to Contractor
                              </button>
                            ) : null}
                            <button
                              type="button"
                              className={
                                this.state.currentStep === length - 1
                                  ? "bg-green"
                                  : ""
                              }
                              onClick={() => this._next(i, length)}
                            >
                              {this.state.currentStep === length - 1
                                ? "Submit to EHS for Verification"
                                : "Next"}
                              <i className="material-icons right">
                                arrow_forward
                              </i>
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.mapvisible}
          onHide={() => this.setState({ mapvisible: false })}
          backdrop="static"
          centered
          animation
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Job Location
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <LoadScript googleMapsApiKey="AIzaSyBGcf9i6vlY07uvfI79hqgz7F7jTdaqA_I">
              <GoogleMap
                mapContainerStyle={{
                  width: "100%",
                  height: "400px",
                }}
                center={this.state.center}
                zoom={10}
                options={{
                  streetViewControl: false,
                  scaleControl: true,
                  panControl: true,
                  rotateControl: true,
                  mapTypeControl: true,
                  draggableCursor: "default",
                  draggingCursor: "crosshair",
                  zoomControl: true,
                  maxZoom: 12,
                  minZoom: 2,
                  fullscreenControl: true,
                }}
                onClick={(e) => this.mapClick(e)}
              >
                <Marker
                  position={{
                    lat: this.state.lat,
                    lng: this.state.lng,
                  }}
                />
                {/* Child components, such as markers, info windows, etc. */}
                <></>
              </GoogleMap>
            </LoadScript>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex justify-content-between">
              <span
                onClick={() => this.setState({ mapvisible: false })}
                variant={"p-1"}
              >
                Cancel
              </span>
              <span onClick={() => this.doneMap()}>Done</span>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          size="sm"
          show={this.state.visible}
          onHide={() => this.closeModal()}
          backdrop="static"
          centered
          animation
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Signature Panel
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SignatureCanvas
              penColor="#005284"
              canvasProps={{ width: 300, height: 150, className: "sigCanvas" }}
              ref={(ref) => {
                this.sigPad = ref;
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <div className="flex justify-content-between">
              <span onClick={() => this.closeModal()} variant={"p-1"}>
                Cancel
              </span>
              <span onClick={() => this.sigPad.clear()} variant={"p-1"}>
                Re-Sign
              </span>
              <span onClick={() => this.signTrim()}>Done</span>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          size="sm"
          show={this.state.audioVisible}
          onHide={() => this.closeAudioModal()}
          backdrop="static"
          centered
          animation
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Audio Recording
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="flex flex-column align-item-center justify-content-between">
              <ReactMic
                record={this.state.record}
                className="sound-wave"
                onStop={this.onStop}
                onData={this.onData}
                strokeColor="#000000"
                backgroundColor="#adb5bd"
                mimeType="audio/mp3"
              />
              <div className="">
                {this.state.record ? (
                  <img
                    onClick={() => this.stopRecording()}
                    style={{ width: 60 }}
                    src={require("../images/stop.png")}
                    alt="stop"
                    className="img-responsive"
                  />
                ) : (
                  <img
                    onClick={() => this.startRecording()}
                    style={{ width: 60 }}
                    src={require("../images/recording.png")}
                    alt="start"
                    className="img-responsive"
                  />
                )}
              </div>{" "}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex justify-content-between">
              <span onClick={() => this.closeAudioModal()}>Cancel</span>
              {this.state.record ? (
                ""
              ) : (
                <span onClick={() => this.saveAudio()}>Done</span>
              )}
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          size="lg"
          show={this.state.videoVisible}
          onHide={() => this.closeVideoModal()}
          backdrop="static"
          centered
          animation
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Video Recording
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <video
              width="100%"
              style={{ maxHeight: "450px" }}
              ref={(v) => {
                this.video = v;
              }}
            >
              Video stream not available.
            </video>
          </Modal.Body>
          <Modal.Footer>
            <div class="flex justify-content-between">
              <select onChange={(e) => this.changeCamera(e)}>
                {this.state.mediaDevice.map((media, i) => (
                  <option value={media.deviceId}>{`Camera ${i}`}</option>
                ))}
              </select>
              {!this.state.videoRecord && (
                <img
                  style={{ width: 50 }}
                  src={require("../images/media-record.png")}
                  alt="test"
                  onClick={(e) => this.startVideoRecord(e)}
                />
              )}
              {this.state.videoRecord && (
                <img
                  style={{ width: 50 }}
                  src={require("../images/stop.png")}
                  alt="test"
                  onClick={(e) => this.stopVideoRecord(e)}
                />
              )}
            </div>
          </Modal.Footer>
        </Modal>
        {this.state.loading && <Loader />}
        {this.props.loader && <Loader />}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    data: state.data.data,
    session_id: state.data.session,
    title: state.data.title,
    permit: state.data.permit,
    loader: state.data.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getMictoData: (UnitId, session_id) =>
      dispatch(fetchAssessorData(UnitId, session_id)),
    getMictoDataUnmount: () => dispatch(dataUnmount()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AssessorFetchSteps);
