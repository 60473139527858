import React, { Component } from "react";
import "../css/App.css";
import API from "../config_api";
import axios from "axios";
import Icon from "@mdi/react";
import { mdiHomeCity } from "@mdi/js";
import DatePicker from "react-date-picker";
import Sidenav from "./Sidenav";



import { Tab,Tabs } from "react-bootstrap";
import moment from "moment";
import Loader from "../component/Loader";
class AchivedTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      startDate: null,
      tmp_data: [],
      approved:[],
      revoked:[],
      ongoing:[],
      expired:[],
      hotwork:[],
      loader:true
    };
  }

  componentWillMount() {
    if (!localStorage.getItem("token")) {
      this.props.history.push("/login");
    }
    let current_date = new Date().toLocaleDateString();

    const userID = localStorage.getItem("token");
    const ID = userID.split("@");

    let formData = new FormData();
    formData.append("user_id", ID[0]);
    formData.append("user_type", this.props.location.state.user);
    axios({
      method: "post",
      url: API.onPermitArchivedApi,
      data: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      mode: "no-cors",
    }).then((res) => {
      console.log(res.data.data);

      let approved = res.data.data.filter((item)=> item.status ==='3' && this.dateCheck(item.start_time,item.end_time,current_date))
      this.setState({approved:approved})
      let revoked = res.data.data.filter((item)=> (item.status ==='2' || item.status ==='15') && this.dateCheck(item.start_time,item.end_time,current_date))
      this.setState({revoked:revoked})
      let ongoing = res.data.data.filter((item)=> (item.status ==='4' || item.status ==='9' || item.status ==='1') && this.dateCheck(item.start_time,item.end_time,current_date) )
      this.setState({ongoing:ongoing})
      let expired = res.data.data.filter((item)=> this.dateCheck1(item.start_time,item.end_time,current_date) )
      this.setState({expired:expired})
      console.log(approved)
      this.setState({ data: res.data.data });
    });
    axios({
      method: "post",
      url: API.HotWork,
      data: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      mode: "no-cors",
    }).then((res) => {
      this.setState({
        hotwork:res.data.data,
        tmp_data:res.data.data,
        loader:false
      })

    })
    this.setStartDate(null);
  }
  dateCheck(from,to,check) {

    var fDate,lDate,cDate;
    fDate = Date.parse(from);
    lDate = Date.parse(to);
    cDate = Date.parse(check);

    if((cDate <= lDate && cDate >= fDate)) {
        return true;
    }
    return false;
}
dateCheck1(from,to,check) {

  var fDate,lDate,cDate;
  fDate = Date.parse(from);
  lDate = Date.parse(to);
  cDate = Date.parse(check);

  if((cDate <= lDate && cDate >= fDate)) {
      return false;
  }
  return true;
}
  getData = () => {
    const userID = localStorage.getItem("token");
    const ID = userID.split("@");

    let formData = new FormData();
    formData.append("user_id", ID[0]);
    formData.append("user_type", this.props.location.state.user);
    axios({
      method: "post",
      url: API.onPermitArchivedApi,
      data: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      mode: "no-cors",
    }).then((res) => {
      console.log(res.data.data);

      this.setState({ data: res.data.data});
    });
    axios({
      method: "post",
      url: API.HotWork,
      data: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      mode: "no-cors",
    }).then((res) => {
      this.setState({
        hotwork:res.data.data,
        tmp_data:res.data.data,
        loader:false
      })
    })
  };
  setStartDate = (e) => {
    console.log(e)
    const t_date =''
    // this.setState({
    //   startDate: date,
    // });
    let current_date = new Date().toLocaleDateString();
    if (e !== '') {
      const data = this.state.data;
      const filtered2 = data.filter((com) => {
      let date = com.created_at.split(' ');
      console.log(date)
      let year = date[0].split('/')
      console.log(year[2])
      console.log(e)
        return year[2] === e;
      });
      const filtered3 = this.state.hotwork.filter((com) => {
        let date = com.created_at.split(' ');
        console.log(date)
        let year = date[0].split('/')
        console.log(year[2])
        console.log(e)
          return year[2] === e;
        });
      this.setState({
        tmp_data: filtered3,
      });
      let approved = filtered2.filter((item)=> item.status ==='3' && this.dateCheck(item.start_time,item.end_time,current_date))
      this.setState({approved:approved})
      let revoked = filtered2.filter((item)=> (item.status ==='2' || item.status ==='15') && this.dateCheck(item.start_time,item.end_time,current_date))
      this.setState({revoked:revoked})
      let ongoing = filtered2.filter((item)=> (item.status ==='4' || item.status ==='9' || item.status ==='1') && this.dateCheck(item.start_time,item.end_time,current_date) )
      this.setState({ongoing:ongoing})
      let expired = filtered2.filter((item)=> this.dateCheck1(item.start_time,item.end_time,current_date) )
      this.setState({expired:expired})
    } else {
      this.getData();
    }
  };
  filterByContracter = (e) => {
    let current_date = new Date().toLocaleDateString();
    if (e.target.value !== "") {
      const data = this.state.data;
      const filtered2 = data.filter((com) => {
        return com.contractor_name
          .toLowerCase()
          .match(e.target.value.toLowerCase());
      });
      const filtered3 = this.state.hotwork.filter((com) => {
        return com.contractor_name
          .toLowerCase()
          .match(e.target.value.toLowerCase());
      });
      this.setState({
        tmp_data: filtered3,
      });
      
      let approved = filtered2.filter((item)=> item.status ==='3' && this.dateCheck(item.start_time,item.end_time,current_date))
      this.setState({approved:approved})
      let revoked = filtered2.filter((item)=> (item.status ==='2' || item.status ==='15') && this.dateCheck(item.start_time,item.end_time,current_date))
      this.setState({revoked:revoked})
      let ongoing = filtered2.filter((item)=> (item.status ==='4' || item.status ==='9' || item.status ==='1') && this.dateCheck(item.start_time,item.end_time,current_date) )
      this.setState({ongoing:ongoing})
      let expired = filtered2.filter((item)=> this.dateCheck1(item.start_time,item.end_time,current_date) )
      this.setState({expired:expired})
    } else {
      this.getData();
    }
  };
  btnAction(item) {
    this.props.history.push("/achivedsession/" + item.program_id, {
      session_id: item.session_id,
      user: this.props.location.state.user,
      slug: this.props.location.state.slug,
      contractorid: this.props.location.state.contractorid,
      contractor_name: this.props.location.state.contractor_name,
    });
  }
  changeDate(date) {
    const dateString = date;
    const dateObject = moment(dateString, 'MM/DD/YYYY');

    const formattedDate = dateObject.format('DD-MMM-YYYY');
    return formattedDate
}
  render() {
    console.log(this.props);
    return (
      <div className="">
        <Sidenav type={"second"} url={"back"} Push={this.props.history} />

        <div className="container">
          <div className="row">
            <div
              className="col-md-12"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="form-group text-center col-6">
                <label>Search by Contracter Name</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => this.filterByContracter(e)}
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="row mb-4 mt-4">
                <div className="col-md-12 flex justify-content-end align-items-center">
                  <div className="col-6 flex justify-content-evenly align-item-center ">
                    <p style={{ margin: 0 }}>YearFilter</p>
                    <select style={{width:150}} className="form-control" onChange={(e) => this.setStartDate(e.target.value)}>
                    <option value={''}></option>
                      <option value={'2021'}>2021</option>
                      <option value={'2022'}>2022</option>
                      <option value={'2023'}>2023</option>
                      <option value={'2024'}>2024</option>
                    </select>
                    {/* <DatePicker
                      value={this.state.startDate}
                     
                    /> */}
                  </div>
                </div>
              </div>
              <Tabs defaultActiveKey={1} id="uncontrolled-tab-example" className="archived">
                <Tab eventKey={1} title="Completed">
              
                <div className="trans">
                {this.state.approved.length === 0 ? (
                  <p>No Approved Permit</p>
                ) : (
                  this.state.approved.map((item, i) => (
                    <div
                      className="card  row list-group-horizontal align-items-center p-0"
                      style={{ margin: 10 }}
                      key={i}
                      onClick={() => this.btnAction(item)}
                    >
                      <div className="col-sm-11 col-xs-11 mb-2">
                        <p className="mb-2">{item.title}</p>
                        <div className="flex flex-column mb-2">
                          <span>#{item.permit_id}</span>
                          <span>{this.changeDate(item.created_at)} </span>
                          <span>
                            Contractor DRI : {item.name} {item.lastname}
                          </span>
                          <span>Contractor Name : {item.contractor_name}</span>
                          <span>ams OSRAM DRI : {item.assessor_name}</span>
                          <span>EHS : {item.intermediate_name}</span>
                          <span>DRI Manager : {item.approver_name}</span>
                          <span>Start Date : {this.changeDate(item.start_time)} </span>
                          <span>End Date : {this.changeDate(item.end_time)}</span>
                          <span
                            style={{ padding: "5px 0", fontWeight: "bold" }}
                          >
                             {item.description}
                          </span>
                          {item.description1 &&
                          <span
                            style={{ padding: "5px 0", fontWeight: "bold" }}
                          >
                             {item.description1}
                          </span>
                          }
                          {item.status && item.status === "1" ? (
                            <span
                              style={{
                                padding: "5px 0",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              Status : Contractor Submitted to ams DRI
                            </span>
                          ) : item.status === "3" ? (
                            <span
                              style={{
                                padding: "5px 0",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              Status : Approved by DRI Manager
                            </span>
                          ) : item.status === "4" ? (
                            <span
                              style={{
                                padding: "5px 0",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              Status : EHS Submitted to DRI Manager
                            </span>
                          ) : item.status === "9" ? (
                            <span
                              style={{
                                padding: "5px 0",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              Status : ams DRI Submitted to EHS
                            </span>
                          ) : item.status === "2" ? (
                            <span
                              style={{
                                padding: "5px 0",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                                Status : {item.ehs ==='1' ? 'Permit Rejected by EHS ':
                              'Permit Rejected by DRI Manager' }
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="flex mb-1">
                          <div className="col-6 p-0">
                            <span style={{ paddingRight: 14 }}>
                              <Icon
                                path={mdiHomeCity}
                                title="User Profile"
                                size={0.8}
                                color={"#be1f24"}
                              />
                            </span>
                            <span>{item.project}</span>
                          </div>
                         
                        </div>
                       
                      </div>

                      <div className="col-sm-1 col-xs-1 pad_z text-right">
                        <i className="material-icons">keyboard_arrow_right</i>
                      </div>
                      <div
                        className="flex justify-content-center"
                        style={{
                          background: "#be1f24",
                          color: "#fff",
                          borderBottomLeftRadius: 5,
                          borderBottomRightRadius: 5,
                        }}
                      >
                        {item.remarks === "Expired" ? "Closed" : item.remarks}
                      </div>
                    </div>
                  ))
                )}
              </div>
                </Tab>
                <Tab eventKey={2} title="Rejected">
                <div className="trans">
                {this.state.revoked.length === 0 ? (
                  <p>No Rejected Permit</p>
                ) : (
                  this.state.revoked.map((item, i) => (
                    <div
                      className="card  row list-group-horizontal align-items-center p-0"
                      style={{ margin: 10 }}
                      key={i}
                      onClick={() => this.btnAction(item)}
                    >
                      <div className="col-sm-11 col-xs-11 mb-2">
                        <p className="mb-2">{item.title}</p>
                        <div className="flex flex-column mb-2">
                          <span>#{item.permit_id}</span>
                          <span>{this.changeDate(item.created_at)} </span>
                          <span>
                            Contractor DRI : {item.name} {item.lastname}
                          </span>
                          <span>Contractor Name : {item.contractor_name}</span>
                          <span>ams OSRAM DRI : {item.assessor_name}</span>
                          <span>EHS : {item.intermediate_name}</span>
                          <span>DRI Manager : {item.approver_name}</span>
                          <span>Start Date : {this.changeDate(item.start_time)} </span>
                                                    <span>End Date : {this.changeDate(item.end_time)}</span>
                          <span
                            style={{ padding: "5px 0", fontWeight: "bold" }}
                          >
                             {item.description}
                          </span>
                          {item.description1 &&
                          <span
                            style={{ padding: "5px 0", fontWeight: "bold" }}
                          >
                             {item.description1}
                          </span>
                          }
                          {item.status && item.status === "1" ? (
                            <span
                              style={{
                                padding: "5px 0",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              Status : Contractor Submitted to ams DRI
                            </span>
                          ) : item.status === "3" ? (
                            <span
                              style={{
                                padding: "5px 0",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              Status : Approved by DRI Manager
                            </span>
                          ) : item.status === "4" ? (
                            <span
                              style={{
                                padding: "5px 0",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              Status : EHS Submitted to DRI Manager
                            </span>
                          ) : item.status === "9" ? (
                            <span
                              style={{
                                padding: "5px 0",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              Status : ams DRI Submitted to EHS
                            </span>
                          ) : item.status === "2" ? (
                            <span
                              style={{
                                padding: "5px 0",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                            Status : {item.ehs ==='1' ? 'Permit Rejected by EHS ':
                              'Permit Rejected by DRI Manager' }
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="flex mb-1">
                          <div className="col-6 p-0">
                            <span style={{ paddingRight: 14 }}>
                              <Icon
                                path={mdiHomeCity}
                                title="User Profile"
                                size={0.8}
                                color={"#be1f24"}
                              />
                            </span>
                            <span>{item.project}</span>
                          </div>
                         
                        </div>
                       
                      </div>

                      <div className="col-sm-1 col-xs-1 pad_z text-right">
                        <i className="material-icons">keyboard_arrow_right</i>
                      </div>
                      <div
                        className="flex justify-content-center"
                        style={{
                          background: "#be1f24",
                          color: "#fff",
                          borderBottomLeftRadius: 5,
                          borderBottomRightRadius: 5,
                        }}
                      >
                        {item.remarks === "Expired" ? "Closed" : item.remarks}
                      </div>
                    </div>
                  ))
                )}
              </div>
                </Tab>
                <Tab eventKey={3} title="Pending">
                <div className="trans">
                {this.state.ongoing.length === 0 ? (
                  <p>No Pending Permit</p>
                ) : (
                  this.state.ongoing.map((item, i) => (
                    <div
                      className="card  row list-group-horizontal align-items-center p-0"
                      style={{ margin: 10 }}
                      key={i}
                      onClick={() => this.btnAction(item)}
                    >
                      <div className="col-sm-11 col-xs-11 mb-2">
                        <p className="mb-2">{item.title}</p>
                        <div className="flex flex-column mb-2">
                          <span>#{item.permit_id}</span>
                          <span>{this.changeDate(item.created_at)} </span>
                          <span>
                            Contractor DRI : {item.name} {item.lastname}
                          </span>
                          <span>Contractor Name : {item.contractor_name}</span>
                          <span>ams OSRAM DRI : {item.assessor_name}</span>
                          <span>EHS : {item.intermediate_name}</span>
                          <span>DRI Manager : {item.approver_name}</span>
                          <span>Start Date : {this.changeDate(item.start_time)} </span>
                                                    <span>End Date : {this.changeDate(item.end_time)}</span>
                          <span
                            style={{ padding: "5px 0", fontWeight: "bold" }}
                          >
                             {item.description}
                          </span>
                          {item.description1 &&
                          <span
                            style={{ padding: "5px 0", fontWeight: "bold" }}
                          >
                             {item.description1}
                          </span>
                          }
                          {item.status && item.status === "1" ? (
                            <span
                              style={{
                                padding: "5px 0",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              Status : Contractor Submitted to ams DRI
                            </span>
                          ) : item.status === "3" ? (
                            <span
                              style={{
                                padding: "5px 0",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              Status : Approved by DRI Manager
                            </span>
                          ) : item.status === "4" ? (
                            <span
                              style={{
                                padding: "5px 0",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              Status : EHS Submitted to DRI Manager
                            </span>
                          ) : item.status === "9" ? (
                            <span
                              style={{
                                padding: "5px 0",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              Status : ams DRI Submitted to EHS
                            </span>
                          ) : item.status === "2" ? (
                            <span
                              style={{
                                padding: "5px 0",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                               Status : {item.ehs ==='1' ? 'Permit Rejected by EHS ':
                              'Permit Rejected by DRI Manager' }
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="flex mb-1">
                          <div className="col-6 p-0">
                            <span style={{ paddingRight: 14 }}>
                              <Icon
                                path={mdiHomeCity}
                                title="User Profile"
                                size={0.8}
                                color={"#be1f24"}
                              />
                            </span>
                            <span>{item.project}</span>
                          </div>
                         
                        </div>
                       
                      </div>

                      <div className="col-sm-1 col-xs-1 pad_z text-right">
                        <i className="material-icons">keyboard_arrow_right</i>
                      </div>
                      <div
                        className="flex justify-content-center"
                        style={{
                          background: "#be1f24",
                          color: "#fff",
                          borderBottomLeftRadius: 5,
                          borderBottomRightRadius: 5,
                        }}
                      >
                        {item.remarks === "Expired" ? "Closed" : item.remarks}
                      </div>
                    </div>
                  ))
                )}
              </div>
                </Tab>
                <Tab eventKey={4} title="Expired">
                <div className="trans">
                {this.state.expired.length === 0 ? (
                  <p>No Expired permit</p>
                ) : (
                  this.state.expired.map((item, i) => (
                    <div
                      className="card  row list-group-horizontal align-items-center p-0"
                      style={{ margin: 10 }}
                      key={i}
                      onClick={() => this.btnAction(item)}
                    >
                      <div className="col-sm-11 col-xs-11 mb-2">
                        <p className="mb-2">{item.title}</p>
                        <div className="flex flex-column mb-2">
                          <span>#{item.permit_id}</span>
                          <span>{this.changeDate(item.created_at)}</span>
                          <span>
                            Contractor DRI : {item.name} {item.lastname}
                          </span>
                          <span>Contractor Name : {item.contractor_name}</span>
                          <span>ams OSRAM DRI : {item.assessor_name}</span>
                          <span>EHS : {item.intermediate_name}</span>
                          <span>DRI Manager : {item.approver_name}</span>
                          <span>Start Date : {this.changeDate(item.start_time)} </span>
                                                    <span>End Date : {this.changeDate(item.end_time)}</span>
                          <span
                            style={{ padding: "5px 0", fontWeight: "bold" }}
                          >
                            {item.description}
                          </span>
                          {item.description1 &&
                          <span
                            style={{ padding: "5px 0", fontWeight: "bold" }}
                          >
                             {item.description1}
                          </span>
                          }
                          {item.status && item.status === "1" ? (
                            <span
                              style={{
                                padding: "5px 0",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              Status : Contractor Submitted to ams DRI
                            </span>
                          ) : item.status === "3" ? (
                            <span
                              style={{
                                padding: "5px 0",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              Status : Approved by DRI Manager
                            </span>
                          ) : item.status === "4" ? (
                            <span
                              style={{
                                padding: "5px 0",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              Status : EHS Submitted to DRI Manager
                            </span>
                          ) : item.status === "9" ? (
                            <span
                              style={{
                                padding: "5px 0",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              Status : ams DRI Submitted to EHS
                            </span>
                          ) : item.status === "2" ? (
                            <span
                              style={{
                                padding: "5px 0",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              Status : {item.ehs ==='1' ? 'Permit Rejected by EHS ':
                              'Permit Rejected by DRI Manager' }
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="flex mb-1">
                          <div className="col-6 p-0">
                            <span style={{ paddingRight: 14 }}>
                              <Icon
                                path={mdiHomeCity}
                                title="User Profile"
                                size={0.8}
                                color={"#be1f24"}
                              />
                            </span>
                            <span>{item.project}</span>
                          </div>
                         
                        </div>
                       
                      </div>

                      <div className="col-sm-1 col-xs-1 pad_z text-right">
                        <i className="material-icons">keyboard_arrow_right</i>
                      </div>
                      <div
                        className="flex justify-content-center"
                        style={{
                          background: "#be1f24",
                          color: "#fff",
                          borderBottomLeftRadius: 5,
                          borderBottomRightRadius: 5,
                        }}
                      >
                        {item.remarks === "Expired" ? "Closed" : item.remarks}
                      </div>
                    </div>
                  ))
                )}
              </div>
                  </Tab>

                  <Tab eventKey={5} title="High Risk">
                     <div className="trans">
                     {this.state.tmp_data.length === 0 ? (
                  <p>No HotWork permit</p>
                ) : (
                  this.state.tmp_data.map((item, i) => (
                    <div
                      className="card  row list-group-horizontal align-items-center p-0"
                      style={{ margin: 10 }}
                      key={i}
                      onClick={() => this.btnAction(item)}
                    >
                      <div className="col-sm-11 col-xs-11 mb-2">
                        <p className="mb-2">{item.title}</p>
                        <div className="flex flex-column mb-2">
                          <span>#{item.permit_id}</span>
                          <span>{this.changeDate(item.created_at)}</span>
                          <span>
                            Contractor DRI : {item.name} {item.lastname}
                          </span>
                          <span>Contractor Name : {item.contractor_name}</span>
                          <span>ams OSRAM DRI : {item.assessor_name}</span>
                          <span>EHS : {item.intermediate_name}</span>
                          <span>DRI Manager : {item.approver_name}</span>
                          <span>Start Date : {this.changeDate(item.start_time)} </span>
                                                    <span>End Date : {this.changeDate(item.end_time)}</span>
                          <span
                            style={{ padding: "5px 0", fontWeight: "bold" }}
                          >
                            {item.description}
                          </span>
                          {item.description1 &&
                          <span
                            style={{ padding: "5px 0", fontWeight: "bold" }}
                          >
                             {item.description1}
                          </span>
                          }
                          {item.status && item.status === "1" ? (
                            <span
                              style={{
                                padding: "5px 0",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              Status : Contractor Submitted to ams DRI
                            </span>
                          ) : item.status === "3" ? (
                            <span
                              style={{
                                padding: "5px 0",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              Status : Approved by DRI Manager
                            </span>
                          ) : item.status === "4" ? (
                            <span
                              style={{
                                padding: "5px 0",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              Status : EHS Submitted to DRI Manager
                            </span>
                          ) : item.status === "9" ? (
                            <span
                              style={{
                                padding: "5px 0",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              Status : ams DRI Submitted to EHS
                            </span>
                          ) : item.status === "2" ? (
                            <span
                              style={{
                                padding: "5px 0",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              Status : {item.ehs ==='1' ? 'Permit Rejected by EHS ':
                              'Permit Rejected by DRI Manager' }
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="flex mb-1">
                          <div className="col-6 p-0">
                            <span style={{ paddingRight: 14 }}>
                              <Icon
                                path={mdiHomeCity}
                                title="User Profile"
                                size={0.8}
                                color={"#be1f24"}
                              />
                            </span>
                            <span>{item.project}</span>
                          </div>
                         
                        </div>
                       
                      </div>

                      <div className="col-sm-1 col-xs-1 pad_z text-right">
                        <i className="material-icons">keyboard_arrow_right</i>
                      </div>
                      <div
                        className="flex justify-content-center"
                        style={{
                          background: "#be1f24",
                          color: "#fff",
                          borderBottomLeftRadius: 5,
                          borderBottomRightRadius: 5,
                        }}
                      >
                        {item.remarks === "Expired" ? "Closed" : item.remarks}
                      </div>
                    </div>
                  ))
                )}
                     </div>
                  </Tab>
              </Tabs>
           
              {/* <div className="trans">
                {this.state.tmp_data.length === 0 ? (
                  <p>No Achived Permit</p>
                ) : (
                  this.state.tmp_data.map((item, i) => (
                    <div
                      className="card  row list-group-horizontal align-items-center p-0"
                      style={{ margin: 10 }}
                      key={i}
                      onClick={() => this.btnAction(item)}
                    >
                      <div className="col-sm-11 col-xs-11 mb-2">
                        <p className="mb-2">{item.title}</p>
                        <div className="flex flex-column mb-2">
                          <span>#{item.permit_id}</span>
                          <span>{item.created_at}</span>
                          <span>
                            Contractor DRI : {item.name} {item.lastname}
                          </span>
                          <span>Contractor Name : {item.contractor_name}</span>
                          <span>ams OSRAM DRI : {item.assessor_name}</span>
                          <span>EHS : {item.intermediate_name}</span>
                          <span>DRI Manager : {item.approver_name}</span>
                          <span>Start Date : {item.start_time} </span>
                          <span>End Date : {item.end_time}</span>
                          <span
                            style={{ padding: "5px 0", fontWeight: "bold" }}
                          >
                            Title : {item.description}
                          </span>
                          {item.status && item.status === "1" ? (
                            <span
                              style={{
                                padding: "5px 0",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              Status : Contractor Submitted to ams DRI
                            </span>
                          ) : item.status === "3" ? (
                            <span
                              style={{
                                padding: "5px 0",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              Status : Approved by DRI Manager
                            </span>
                          ) : item.status === "4" ? (
                            <span
                              style={{
                                padding: "5px 0",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              Status : EHS Submitted to DRI Manager
                            </span>
                          ) : item.status === "9" ? (
                            <span
                              style={{
                                padding: "5px 0",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              Status : ams DRI Submitted to EHS
                            </span>
                          ) : item.status === "2" ? (
                            <span
                              style={{
                                padding: "5px 0",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              Status : Permit Revoked by DRI Manager
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="flex mb-1">
                          <div className="col-6 p-0">
                            <span style={{ paddingRight: 14 }}>
                              <Icon
                                path={mdiHomeCity}
                                title="User Profile"
                                size={0.8}
                                color={"#be1f24"}
                              />
                            </span>
                            <span>{item.project}</span>
                          </div>
                         
                        </div>
                       
                      </div>

                      <div className="col-sm-1 col-xs-1 pad_z text-right">
                        <i className="material-icons">keyboard_arrow_right</i>
                      </div>
                      <div
                        className="flex justify-content-center"
                        style={{
                          background: "#be1f24",
                          color: "#fff",
                          borderBottomLeftRadius: 5,
                          borderBottomRightRadius: 5,
                        }}
                      >
                        {item.remarks === "Expired" ? "Closed" : item.remarks}
                      </div>
                    </div>
                  ))
                )}
              </div> */}
            </div>
          </div>
        </div>
        {this.state.loader ?
        <Loader/>:''}
      </div>
    );
  }
}
export default AchivedTab;
