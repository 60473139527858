import React, { Component } from 'react';
import axios from 'axios';
import API from '../config_api'
import '../css/App.css';
import Sidenav from './Sidenav';

class Notification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            start: 6,
            end: 15
        };

    }

    componentWillMount() {
        if (!localStorage.getItem('token')) {
            this.props.history.push('/login')
        }
        let formData = new FormData();
        formData.append('user_id', localStorage.getItem('token'))
        axios({
            method: 'post',
            url: API.Notification,
            data: formData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            mode: 'no-cors'
        })
            .then(res => {
                if (res.data.status === 'success') {
                    this.setState({
                        data: res.data.data
                    })
                }
                else {
                    alert(res.data.message)
                }
            });

    }
    delete(NotificationData, id) {
        let formData = new FormData();
        formData.append('user_id', localStorage.getItem('token'))
        formData.append('msg_id', NotificationData.msg_id)
        formData.append('type', "2")
        axios({
            method: 'post',
            url: API.NotificationDelete,
            data: formData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            mode: 'no-cors',
        })
            .then(res => {
                if (res.data.status === 'success') {
                    console.log(res)
                }
                else {
                    alert(res.data.message)
                }
            });
        this.deleteitem(id)
    }

    deleteitem(id) {

        const { data } = this.state
        data.splice(id, 1);
        this.forceUpdate()

    }
    loadmore() {
        this.setState({
            start: this.state.start + 10,
            end: this.state.end + 10
        })
        let formData = new FormData();
        formData.append('user_id', localStorage.getItem('token'))
        formData.append('limit[start]', this.state.start)
        formData.append('limit[end]', this.state.end)
        axios({
            method: 'post',
            url: API.Notification,
            data: formData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            mode: 'no-cors',
        })
            .then(res => {
                if (res.data.status === 'success') {
                    this.setState({
                        data: res.data.data
                    })
                }
                else {
                    alert(res.data.message)
                }
            });
    }
    readStatus(Notification, i) {
        Notification.expanded = Notification.expanded === "1" ? "0" : "1"
        if (Notification.read_status === '0') {
            Notification.read_status = '1'
            this.forceUpdate()
            let formData = new FormData();
            formData.append('user_id', localStorage.getItem('token'))
            formData.append('msg_id', Notification.msg_id)
            formData.append('type', '1')
            axios({
                method: 'post',
                url: API.NotificationUpdate,
                data: formData,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                mode: 'no-cors',
            })
                .then(res => {
                    if (res.data.status === 'success') {

                    }
                    else {
                        alert(res.data.message)
                    }
                });
        }
        else {
            this.forceUpdate()
        }
    }
    videoCall(room){
        this.props.history.push('/video/'+room);
    }
    render() {
        console.log(this.state.data)
        return (
            <div className="">

                <Sidenav type={'second'} url={'/dashboard'} />

                <div className="container">
                    <div>
                        <div className="container">
                            <div className="row">

                                <div className="col-md-12 text-center" style={{ padding: 15 }}>
                                    <h5 className="size-text">Notifications</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-sm-12 pad_z">
                            {this.state.data.length === 0 ? <p className="text-center">No Data Found </p> :
                                this.state.data.map((item, i) => {
                                    return (

                                        <div className={item.read_status === '0' ? "card unread" : "card read"} style={{ padding: 10, margin: 10 }} key={i} onClick={() => this.readStatus(item, i)}>
                                            <div className="row" style={{ margin: 0 }}>
                                                <div className="col-sm-9 col-xs-9 padZero">
                                                    <h4>{item.title}</h4>
                                                    <p className="mZero">{item.message}</p>
                                                    <p className="m-0" style={{ color: 'orange', fontSize: 12, fontWeight: 500 }}>{item.daysLeft !== 0 ? item.daysLeft + ' days ' : item.hoursLeft !== 0 ? item.hoursLeft + ' hours ' : item.minutesLeft !== 0 ? item.minutesLeft + ' minutes ' : item.daysLeft !== '0' && item.hoursLeft !== '0' && item.minutesLeft !== '0' ? item.daysLeft + ' days ' : '0 minutes'}</p>
                                                </div>
                                                <div className="col-sm-2 col-xs-2 pad_z flex align-item-center justify-content-center">
                                                {item.video_chat_rooms && 
                                                <span className="material-icons" style={{fontSize:50}} onClick={() => this.videoCall(item.video_chat_rooms)}>
                                        videocam
</span>
                                                }</div>
                                                <div className="col-sm-1 col-xs-1 pad_z text-right"><span class="material-icons" onClick={() => this.delete(item, i)}>
                                                    delete_forever
</span></div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center">
                            <p onClick={() => this.loadmore()}>Load More</p>
                        </div>
                    </div>

                </div>

            </div>
        );
    }
}

export default Notification;