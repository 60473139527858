import React, { Component } from 'react';
import '../css/App.css';
import AssignedModulesParentComponent from './AssignedModulesParentComponent';
import AssignedModulesChild1 from './AssignedModulesChild1';
import Collapse from "@kunukn/react-collapse";
import Sidenav from './Sidenav';

class UnitAssign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            showList: true,
            highlightedHobby: false
        };
    }

    componentWillMount() {
        if (!localStorage.getItem('token')) {
            this.props.history.push('/')
        }
        if (this.props.location.state) {
            this.setState({ data: this.props.location.state.data })
            localStorage.setItem("models", JSON.stringify(this.props.location.state.data))
        }
        else {
            this.setState({ data: JSON.parse(localStorage.getItem("models")) })

        }

    }
    listSwitch = () => {
        this.setState(state => ({
          highlightedHobby: !state.highlightedHobby
        }));
      };
    mainView(data, i, params) {
        let models;
        console.log(data);
        models = params.models
        models.forEach((val, index) => {
            if (i === index) {
                data.module_ui_status = !data.module_ui_status
                // console.log(val)
            } else {
                val.module_ui_status = false
            }
        })
        this.setState(prevState => ({
            showList: !prevState.showList
          }));
        this.forceUpdate();

    }
    starModule(data) {
        console.log(data);
        this.props.history.push('/overview', {data})
        localStorage.setItem('unit_name', data.program_title)
    }

    render() {
console.log(this.props)
        return (

            <div className="">
                <Sidenav type={'second'} url={'back'} Push={this.props.history} />

                <div className="container">

                    <div className="row">
                        <div className="col-sm-12 pad_z">

                            {this.state.data.models.map((item, i) => {
                                return (
                                    <div className="row" style={{ margin: 10 }} key={i}>
                                        <AssignedModulesParentComponent
                                            ParentComponentTitle={item.model_name}
                                            UnitsCount={item.programs.length}
                                            index={i}
                                            ui_open={item.module_ui_status}
                                            completed={item.completed}
                                            Onpress={() => this.mainView(item, i, this.state.data)}
                                        />
                                        
                                         <Collapse isOpen={item.module_ui_status}>
                                            {item.module_ui_status === true ?
                                                item.programs.map((data, index) =>
                                                    <div className="trans" key={index}>
                                                        <AssignedModulesChild1
                                                            greenDot={data.completed}
                                                            isOffline={data.is_offline}
                                                            program_id={data.program_id}
                                                            SingleAlphabet={data.program_title.charAt(0)}
                                                            ChildComponent1content={data.program_title}
                                                            onPress={() => this.btnAction(data, data.model_name)}
                                                            starred={data.starred}
                                                            starUnit={() => this.starModule(data, data.program_id)}
                                                        /></div>
                                                ) : ''}</Collapse>
                                    </div>
                                )
                            })
                            }


                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default UnitAssign;