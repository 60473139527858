import React, { Component } from 'react';
import axios from 'axios';
import API from '../config_api'
import '../css/App.css';


class Logout extends Component {

  
  componentWillMount(){
    if (!localStorage.getItem('token')) {
      this.props.history.push('/login')
  }
    let formData = new FormData();
    formData.append('token_id', localStorage.getItem('token'))
  axios({
    method: 'post',
    url: API.LogOut,
    data:formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    mode: 'no-cors',
})
    .then(res => {
        
    });
    localStorage.removeItem('models');
    localStorage.removeItem('token');
    localStorage.removeItem('screen');
    localStorage.removeItem('email');
    localStorage.removeItem('firstname');
    localStorage.removeItem('lastname');
    localStorage.removeItem('emp_id');
    localStorage.removeItem('notification-token');
    window.location.reload();
 
  }
   
    render() {
        return (
            <div className="valign-wrapper h-h">
               
            </div>
        );
    }
}

export default Logout;