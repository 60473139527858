import React, { Component } from 'react';
import '../css/App.css';
import API from '../config_api'
import axios from 'axios'
import Sidenav from './Sidenav';
import Icon from '@mdi/react'
import { Modal, Tabs, Tab, Button } from 'react-bootstrap';
import BigPicture from "react-bigpicture";
import { mdiHomeCity } from '@mdi/js'
import moment from 'moment';

import Loader from '../component/Loader';
class ActiveTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            files: '',
            temp_data: [],
            visible: false,
            extra_file: ''

        };
        this.arr = []

    }

    componentWillMount() {
        if (!localStorage.getItem('token')) {
            this.props.history.push('/login')
        }
        const userID = localStorage.getItem('token');
        const ID = userID.split('@');

        let formData = new FormData();
        formData.append('user_id', ID[0])
        formData.append('user_type', this.props.location.state.user)
        axios({
            method: 'post',
            url: API.onPermitActiveApi,
            data: formData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            mode: 'no-cors',
        })
            .then(res => {
                console.log(res.data.data)

                this.setState({ data: res.data.data })
                this.setState({ temp_data: res.data.data })

            });
    }
    getData = () => {
        const userID = localStorage.getItem('token');
        const ID = userID.split('@');
        let formData = new FormData();
        formData.append('user_id', ID[0])
        formData.append('user_type', this.props.location.state.user)
        axios({
            method: 'post',
            url: API.onPermitActiveApi,
            data: formData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            mode: 'no-cors',
        })
            .then(res => {
                console.log(res.data.data)

                this.setState({ data: res.data.data })

            });
    }
    btnAction(item) {
        //    if(this.props.location.state.user==='applicant'){
        //     this.props.history.push('/inprogressresponse/' +item.program_id,{session_id:item.session_id})
        //     }else if(this.props.location.state.user==='assessor'){
        //         this.props.history.push('/assessorfetch/' +item.program_id,{session_id:item.session_id,type:'assessor'})
        //     }else{
        this.props.history.push('/upcomingresponse/' + item.program_id, { session_id: item.session_id, user: this.props.location.state.user })
        // }
    }
    getRemarks1(e, item, type) {
        e.stopPropagation()
        if (item.extra_file === '1') {
            this.setState({ visible: true, id: item.session_id, tye: type, ev: e })
        } else {
            this.getRemarks2(item.session_id, type)
        }
    }
    getRemarks2(item, type) {

        let formdata = new FormData();
        formdata.append('session_id', item)
        formdata.append('status', type)
        axios({
            method: 'post',
            url: API.ForeClose,
            data: formdata,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            mode: 'no-cors',
        })
            .then(res => {
                this.setState({
                    loading: false
                })
                window.location.reload(true)
            })
    }
    getRemarks(e, item, type) {
        e.stopPropagation()
        let formdata = new FormData();
        formdata.append('session_id', item.session_id)
        formdata.append('status', type)
        axios({
            method: 'post',
            url: API.ForeClose,
            data: formdata,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            mode: 'no-cors',
        })
            .then(res => {
                window.location.reload(true)
            })
    }
    uploadFiles(e) {
        console.log(e.target.files)

        let randomString = Math.random().toString(36);

        this.setState({
            loading: true,
            theInputKey: randomString
        });
        let formData = new FormData()
        formData.append('input_file', e.target.files[0])
        axios({
            method: 'post',
            url: API.UploadFile,
            data: formData,
            headers: {
                'content-type': 'multipart/form-data'

            },
            mode: 'no-cors',
        })
            .then(res => {

                // console.log(this.arr)
                // this.arr.push("https://mpower-s3.s3.ap-southeast-1.amazonaws.com/" + res.data)
                this.setState({ files: "https://mpower-s3.s3.ap-southeast-1.amazonaws.com/" + res.data, loading: false })
                this.setState({ extra_file: res.data })


            })




    }
    closeModal = () => {
        this.setState({
            visible: false
        })
    }
    filterByContracter = (e) => {
        if (e.target.value !== '') {
            const data = this.state.data
            const filtered2 = data.filter(com => {
                console.log(e.target.value.toLowerCase())
                return com.contractor_name.toLowerCase().match(e.target.value.toLowerCase());
            });
            this.setState({
                temp_data: filtered2
            })
        } else {
            this.getData();
        }

    }
    onSubmitPermit = () => {
        this.setState({
            loading: true
        })
        let formData1 = new FormData()
        formData1.append('id', this.state.id)
        formData1.append('extra_file', this.state.extra_file)
        axios({
            method: 'post',
            url: API.ExtraFile,
            data: formData1,
            headers: {
                'content-type': 'multipart/form-data'

            },
            mode: 'no-cors',
        })
            .then(res => {
                this.getRemarks2(this.state.id, this.state.tye)

            })

    }
    changeDate(date) {
        const dateString = date;
        const dateObject = moment(dateString, 'MM/DD/YYYY');

        const formattedDate = dateObject.format('DD-MMM-YYYY');
        return formattedDate
    }
    render() {

        return (
            <div className="">
                <Sidenav type={'second'} url={'back'} Push={this.props.history} />


                <div className="container">

                    <div className="row">
                        <div className='col-md-12' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                            <div className='form-group text-center col-6' >
                                <label>Search by Contracter Name</label>
                                <input type='text' className='form-control' onChange={(e) => this.filterByContracter(e)} />
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <div className="trans">
                                {this.state.temp_data.length === 0 ? <p>No live Permit</p>
                                    :
                                    this.state.temp_data.map((item, i) =>
                                        <div className="card  row list-group-horizontal align-items-center p-0" style={{ margin: 10, borderLeft: '5px solid #10705d' }} key={i} onClick={() => this.btnAction(item)}>
                                            <div className="col-sm-11 col-xs-11 mb-2">
                                                <p className="mb-2">{item.title}</p>
                                                <div className="flex flex-column mb-2">
                                                    <span>#{item.permit_id}</span>
                                                    <span>{this.changeDate(item.created_at)} </span>
                                                    <span>Contractor DRI : {item.name} {item.lastname}</span>
                                                    <span>Contractor Name : {item.contractor_name}</span>
                                                    <span>ams OSRAM DRI : {item.assessor_name}</span>
                                                    <span>EHS : {item.intermediate_name}</span>
                                                    <span>DRI Manager : {item.approver_name}</span>
                                                    <span>Start Date : {this.changeDate(item.start_time)} </span>
                                                    <span>End Date : {this.changeDate(item.end_time)}</span>
                                                    <span style={{ padding: '5px 0', fontWeight: 'bold' }}> {item.description}</span>
                                                    {item.description1 &&
                                                        <span
                                                            style={{ padding: "5px 0", fontWeight: "bold" }}
                                                        >
                                                            {item.description1}
                                                        </span>
                                                    }
                                                    {item.status &&
                                                        item.status === '1' ? <span style={{ padding: '5px 0', color: 'green', fontWeight: 'bold' }}>Status : Contractor Submitted to ams DRI</span>
                                                        : item.status === '3' ? <span style={{ padding: '5px 0', color: 'green', fontWeight: 'bold' }}>Status : Approved by DRI Manager</span> :
                                                            item.status === '4' ? <span style={{ padding: '5px 0', color: 'green', fontWeight: 'bold' }}>Status : EHS Submitted to DRI Manager</span>
                                                                : item.status === '9' ? <span style={{ padding: '5px 0', color: 'green', fontWeight: 'bold' }}>Status : ams DRI Submitted to EHS</span>
                                                                    : item.status === '2' ? <span style={{ padding: '5px 0', color: 'green', fontWeight: 'bold' }}>Status : Permit Rejected by DRI Manager</span>
                                                                        : ''}
                                                </div>
                                                <div className="flex mb-1">
                                                    <div className="col-6 p-0">
                                                        <span style={{ paddingRight: 14 }}>
                                                            <Icon path={mdiHomeCity}
                                                                title="User Profile"
                                                                size={0.8}
                                                                color={'#be1f24'}
                                                            />
                                                        </span>
                                                        <span>{item.project}</span>
                                                    </div>
                                                    {/* <div className="col-6 p-0">
                                                <span style={{ paddingRight: 14 }}>
                                                    <IosPin size={30} color={'#be1f24'} />
                                                </span>
                                                <span>{item.location}</span>
                                            </div> */}

                                                </div>
                                                {/* <div className="flex">
                                            <div className="col-6 p-0">
                                                <span style={{ paddingRight: 14 }}>
                                                    
                                                </span>
                                                <span>{item.permit_date}</span>
                                            </div>
                                            <div className="col-6 p-0">
                                                <span style={{ paddingRight: 14 }}>
                                                    
                                                </span>
                                                <span>{item.start_time} {item.end_time}</span>
                                            </div>


                                        </div> */}
                                                <span>Progress  : {item.progress}</span>
                                                {this.props.location.state.user === 'applicant' ?
                                                    item.progress === 'On Going' ?
                                                        <div className="flex m-3 ">
                                                            <span style={{ color: 'green', marginRight: 30, cursor: 'pointer' }} onClick={(e) => this.getRemarks1(e, item, 'Job Completed')}>Finish </span>
                                                            <span style={{ color: 'red', cursor: 'pointer' }} onClick={(e) => this.getRemarks(e, item, 'Foreclosed')}>Foreclose </span>
                                                        </div> : '' : ''}
                                                {this.props.location.state.user === 'assessor' ?
                                                    item.progress === 'Foreclosed' || item.progress === 'Job Completed' ?
                                                        <div className="flex m-3 ">
                                                            <span style={{ color: 'green', marginRight: 30, cursor: 'pointer' }} onClick={(e) => this.getRemarks(e, item, item.progress + '. Acknowledged by ' + localStorage.getItem('firstname'))}>Acknowledge </span>

                                                        </div>
                                                        : '' : ''}
                                            </div>

                                            <div className="col-sm-1 col-xs-1 pad_z text-right"><i className="material-icons">
                                                keyboard_arrow_right
                                            </i></div>
                                            <div className="flex justify-content-center" style={{ background: '#be1f24', color: '#fff', borderBottomLeftRadius: 5, borderBottomRightRadius: 5 }}>{item.remarks}</div>
                                        </div>)}
                            </div></div>
                    </div></div>
                {this.state.loading &&
                    <Loader />
                }
                <Modal
                    size="sm"
                    show={this.state.visible}
                    onHide={() => this.closeModal()}
                    backdrop='static'
                    centered
                    animation
                    aria-labelledby="example-modal-sizes-title-sm"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            Please Upload
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group multi-preview row">
                            {this.state.files !== '' ?
                                <div className="col-3">
                                    <i className="material-icons" onClick={() => this.setState({ files: '' })} >close</i>
                                    <BigPicture
                                        type="iframe"
                                        src={this.state.files}
                                    >
                                        <div className="flex  justify-content-center align-items-center" style={{ position: 'relative', flexDirection: 'column' }}>

                                            View File


                                        </div>
                                    </BigPicture>
                                </div>
                                : ''}

                            {/* {this.state.files.map((url, i) => {
                                return (
                                    <div className="col-3">
                                        <i className="material-icons" onClick={() => this.removeURL(url, i)} >close</i>
                                        <BigPicture
                                            type="iframe"
                                            src={url}
                                        >
                                            <div className="flex  justify-content-center align-items-center" style={{ position: 'relative', flexDirection: 'column' }}>

                                                View File


                                            </div>
                                        </BigPicture>
                                    </div>
                                )
                            })} */}


                        </div>
                        <div className="form-group" style={{ textAlign: 'center' }}>
                            <label for={'test_'} style={{ border: '1px solid #0c0c0c', width: '100%', padding: 10 }}><i style={{ fontSize: 30 }} class="material-icons">
                                add
                            </i></label>
                            <input type="file" style={{ display: 'none' }} className="form-control" id={'test_'} key={this.state.theInputKey || ''} onChange={(e) => this.uploadFiles(e)} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="flex justify-content-between">
                            <Button variant="secondary" onClick={() => this.closeModal()}>Close</Button>
                            {this.state.files !== '' ?
                                <Button variant="primary" onClick={() => this.onSubmitPermit()}>Submit</Button>
                                : <Button variant="primary" disabled>Submit</Button>}
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
export default ActiveTab;