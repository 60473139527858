import { FETCH_MICRO_DATA_REQUEST, FETCH_MICRO_DATA_SUCCESS, FETCH_MICRO_DATA_FAILURE, FETCH_MICRO_DATA_UNMOUNT } from './MicroTypes'
const initialState = {
    loading: false,
    data: [],
    session: '',
    title: '',
    error: ''
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MICRO_DATA_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_MICRO_DATA_SUCCESS:
            return {
                loading: false,
                data: action.payload.data,
                session: action.payload.session_id,
                permit: action.payload.permit_id,
                title: action.payload.program_title,
                assessor: action.payload.assessor_id
            }
        case FETCH_MICRO_DATA_UNMOUNT:
            return {
                loading: false,
                data: [],
                session: '',
                permit: '',
                title: ''
            }
        case FETCH_MICRO_DATA_FAILURE:
            return {
                loading: false,
                error: action.payload
            }

        default:
            return state;
    }
}
export default reducer;