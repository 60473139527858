import React, { Component } from 'react';
import axios from 'axios';
import Sidenav from '../pages/Sidenav';
import API from '../config_api'
import '../css/App.css';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Icon from '@mdi/react'
import { Button } from 'react-bootstrap';
import { mdiProgressClock, mdiProgressWrench, mdiProgressAlert, mdiProgressCheck,mdiProgressClose,mdiProgressUpload,mdiProgressQuestion,mdiProgressDownload } from '@mdi/js'

import Loader from '../component/Loader';
import { mdiHomeCity } from '@mdi/js'
import { fetchData } from '../redux/assignedData/AssignAction'
import { connect } from 'react-redux'


class DashBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            insights: '',
            percentage: '',
            loading: false,
            upComing: '',
            contractor: ''

        };

    }
    componentWillMount() {
        if (!localStorage.getItem('token')) {
            this.props.history.push('/login')
        }
        else {
            let formData = new FormData();
            formData.append('user_id', localStorage.getItem('token'))
            formData.append('user_type', 'applicant')

            axios({
                method: 'post',
                url: API.Dashboard,
                data: formData,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                mode: 'no-cors',
                async: false,
            })
                .then(res => {
                    this.setState({
                        loading: true
                    })
                    if (res.data.status === 'success') {
                        this.setState({ data: res.data.data })
                        console.log(res.data.data);
                    }
                    else {
                        alert(res.data.message)
                    }
                });
            axios({
                method: 'post',
                url: API.onPermitUpcomingApi,
                data: formData,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                async: false,
                mode: 'no-cors',
            })
                .then(res => {

                    this.setState({ upComing: res.data.data })
                    console.log(res.data.data);

                });
            const userID = localStorage.getItem('token');
            const ID = userID.split('@');

            let formData1 = new FormData();
            formData1.append('user_id', ID[0])


            axios({
                method: 'post',
                url: API.GetContractorName,
                data: formData1,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                async: false,
                mode: 'no-cors',
            })
                .then(res => {
console.log(res);
                    this.setState({
                        contractor: res.data.data[0]
                    })


                });

            this.props.getAreaData();
        }
    }
    btnAction(item) {
        this.props.history.push('/units/' + item.program_id, { pStatus: 1,slug:this.state.contractor.slug,contractorid:this.state.contractor.contractor_id,contractor_name:this.state.contractor.name })
    }
    render() {

        return (

            <div className=" ">
                <Sidenav type={"main"} push={this.props.history} />
                <ReactCSSTransitionGroup
                    transitionAppear={true}
                    transitionAppearTimeout={600}
                    transitionEnterTimeout={600}
                    transitionLeaveTimeout={200}
                    transitionName={this.props.match.path === '/dashboard' ? 'SlideIn' : 'SlideOut'}
                >
                    <div className="container">
                        <div className="row marLeft-5 marRight-5">
                            <div className="col">
                                <div className="pt-2 pb-2">Hello<br /> <span style={{ fontSize: 20, fontWeight: 500 }}>{localStorage.getItem('firstname')}  {localStorage.getItem('lastname')} !</span> <br />
                                    <i> -- {this.state.contractor.name}</i>
                                </div>
                            </div>

                        </div>
                        <div className="row marLeft-5 marRight-5">
                            <div className="col"> <div className="pt-2 pb-2 text-center"><span style={{ fontSize: 20, fontWeight: 500 }}>Contractor DRI</span>
                                {localStorage.getItem('type').indexOf('4') !== -1 || localStorage.getItem('type').indexOf('6') !== -1 || localStorage.getItem('type').indexOf('50') !== -1 ?
                                    <div className="dropdown">
                                        <a href="true" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                                            <i class="fa fa-chevron-circle-down fa-2x" aria-hidden="true"></i>
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                            {localStorage.getItem('type').indexOf('4') !== -1 ?
                                                <button className="dropdown-item" onClick={() => this.props.history.push('/assessor')}>ams OSRAM DRI</button>
                                                : null}
                                            {localStorage.getItem('type').indexOf('7') !== -1 ?
                                                <button className="dropdown-item" onClick={() => this.props.history.push('/ehs')}>EHS</button>
                                                : null}
                                            {localStorage.getItem('type').indexOf('6') !== -1 ?
                                                <button className="dropdown-item" onClick={() => this.props.history.push('/approver')}>Dri Manager</button>
                                                : null}
                                                
                                            {localStorage.getItem('type').indexOf('50') !== -1 ?
                                                <button className="dropdown-item" onClick={() => this.props.history.push('/security')}>Viewer</button>
                                                : null}
                                        </div>
                                    </div>
                                    : null
                                }
                            </div></div>
                        </div>
                        <div className="row marLeft-5 marRight-5">
                            <div className="col-12  pad_z">
                                <div className="flex">
                                    <div className={`card flex justify-content-center mb-0 `} onClick={() => this.props.history.push('/live', { user: 'applicant' })}>
                                        <div className=" flex justify-content-center align-item-center">
                                            <Icon path={mdiProgressClock}
                                                title="User Profile"
                                                size={2}
                                                color={'#10705d'}
                                            />

                                        </div>
                                        <div className=" flex justify-content-center align-item-center">
                                            <h5 className="m-0 mt-1">{this.state.data.active}</h5>

                                        </div>
                                        <div className=" flex justify-content-center align-item-center">
                                            <h5 className="m-0 mt-1">Ongoing project</h5>

                                        </div>

                                    </div>
                                    <div className={`card flex justify-content-center mb-0 `} onClick={() => this.props.history.push('/upcoming', { user: 'applicant' })} >
                                        <div className=" flex justify-content-center align-item-center">
                                            <Icon path={mdiProgressCheck}
                                                title="User Profile"
                                                size={2}
                                                color={'#d9b007'}
                                            />

                                        </div>
                                        <div className=" flex justify-content-center align-item-center">
                                            <h5 className="m-0 mt-1">{this.state.data.upcoming}</h5>

                                        </div>
                                        <div className=" flex justify-content-center align-item-center">
                                            <h5 className="m-0 mt-1">Upcoming project</h5>

                                        </div>

                                    </div>
                                    <div className={`card flex justify-content-center mb-0 `} onClick={() => this.props.history.push('/inprogress', { user: 'applicant' })} >
                                        <div className=" flex justify-content-center align-item-center">
                                            <Icon path={mdiProgressAlert}
                                                title="User Profile"
                                                size={2}
                                                color={'#d9b007'}
                                            />

                                        </div>
                                        <div className=" flex justify-content-center align-item-center">
                                            <h5 className="m-0 mt-1">{this.state.data.pending}</h5>

                                        </div>
                                        <div className=" flex justify-content-center align-item-center">
                                            <h5 className="m-0 mt-1">under review by ams OSRAM DRI</h5>

                                        </div>

                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="row marLeft-5 marRight-5 mt-2">
                            <div className="col-12  pad_z">
                                <div className="flex">
                                <div className={`card flex justify-content-center mb-0 `} onClick={() => this.props.history.push('/draft', { user: 'applicant' , pStatus: 1,slug:this.state.contractor.slug,contractorid:this.state.contractor.contractor_id,contractor_name:this.state.contractor.name })} >
                                        <div className=" flex justify-content-center align-item-center">
                                            <Icon path={mdiProgressDownload}
                                                title="User Profile"
                                                size={2}
                                                color={'#be1f24'}
                                            />

                                        </div>
                                        <div className=" flex justify-content-center align-item-center">
                                            <h5 className="m-0 mt-1">{this.state.data.draft}</h5>

                                        </div>
                                        <div className=" flex justify-content-center align-item-center">
                                            <h5 className="m-0 mt-1">Draft</h5>

                                        </div>

                                    </div>
                                    <div className={`card flex justify-content-center mb-0 `} onClick={() => this.props.history.push('/achived', { user: 'applicant',slug:this.state.contractor.slug,contractorid:this.state.contractor.contractor_id,contractor_name:this.state.contractor.name  })} >
                                        <div className=" flex justify-content-center align-item-center">
                                            <Icon path={mdiProgressClose}
                                                title="User Profile"
                                                size={2}
                                                color={'#be1f24'}
                                            />

                                        </div>
                                        <div className=" flex justify-content-center align-item-center">
                                            <h5 className="m-0 mt-1">{this.state.data.archive}</h5>

                                        </div>
                                        <div className=" flex justify-content-center align-item-center">
                                            <h5 className="m-0 mt-1">Completed project</h5>

                                        </div>

                                    </div>
                                    <div className={`card flex justify-content-center mb-0 `}   onClick={() => this.props.history.push('/reject', { user: 'applicant',slug:this.state.contractor.slug,contractorid:this.state.contractor.contractor_id,contractor_name:this.state.contractor.name  })}>
                                        <div className=" flex justify-content-center align-item-center">
                                            <Icon path={mdiProgressUpload}
                                                title="User Profile"
                                                size={2}
                                                color={'red'}
                                            />

                                        </div>
                                        <div className=" flex justify-content-center align-item-center">
                                            <h5 className="m-0 mt-1">{this.state.data.reject}</h5>

                                        </div>
                                        <div className=" flex justify-content-center align-item-center">
                                            <h5 className="m-0 mt-1">Returned by ams OSRAM DRI</h5>

                                        </div>

                                    </div>
                                </div>
                                </div>
                                </div>
                        <div className="col-sm-12 pad_z marLeft-5 marRight-5" style={{marginTop:20}}>
                        <div className="card flex justify-content-center row align-items-center" >
                            <h4> IMPORTANT NOTES TO PERSON APPLYING FOR THE PERMIT</h4>
                            <p>Ensure all workers have attended the Safety Induction Course by EHS and comply with the following:	</p>
                            <ul className='notes_dash'>

                                <li>All Safety, Security, and Environmental rules and regulations.</li>

                                    <li>	The legal requirements (i.e. WSH Act, EPMA, Employment Act, etc.), e.g. Lockout procedure, working at height, entry into confined space, etc.</li>

                                        <li>	Provide all necessary safety equipment to their workers for the work.	</li>

                                        <li>	Declare to security all equipment and tools brought into the premises.	</li>

                                      

                                        <li>	
Provide the SDS for any chemicals brought into the premises.
</li>

                                        <li>Lock all equipment and tools when not in use. ams OSRAM will not be held responsible for any missing tools or equipment.</li>

                                        <li>If Hot Work is carried out or flammable materials are used, approval must be obtained from the Facilities & Maintenance Engineer.	</li>

                                        <li>	Safety signs and warning notices must be displayed prominently at the work area.
	</li>


                                        <li>	All debris or waste materials arising from work must be cleared immediately at the end of the day or when the work is completed, whichever is earlier.
</li>


                                    </ul>

                                </div>
                                </div>
                        <div className="row justify-content-center mt-3 mb-2 marLeft-5 marRight-5">
                            {/* <div className="col-12 mt-2 mb-2 text-center">Start Reporting</div> */}
                            {this.props.data.map((item, i) => {

                                return (
                                    <>
                                        {item.program_id === '5604' ?
                                          <Button color={"btn-primary"}  variant={"my-4"} style={{color:'#fff',background:'#000',borderRadius:50}} size={"small"} onClick={() => this.btnAction(item)}>Apply for New Permit</Button>
                                          
                                            : ''}
                                    </>
                                )

                            })}
                        </div>

                        


                                {!this.state.loading && <Loader />}

                        </div>
                </ReactCSSTransitionGroup>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state.assign.data,
        loader: state.assign.loading,
        error: state.assign.error
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getAreaData: () => dispatch(fetchData())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DashBoard);