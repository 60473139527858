import React, { Component } from 'react';
import '../css/App.css';
import API from '../config_api'
import axios from 'axios'
import Sidenav from './Sidenav';
import Icon from '@mdi/react'
import { mdiHomeCity } from '@mdi/js'
import moment from 'moment';

class InProgressTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            temp_data:[]

        };

    }

    componentWillMount() {
        if (!localStorage.getItem('token')) {
            this.props.history.push('/login')
        }
        const userID = localStorage.getItem('token');
        const ID = userID.split('@');
        
        let formData = new FormData();
        formData.append('user_id', ID[0])
        formData.append('user_type', this.props.location.state.user)
        if(this.props.location.state.user==='applicant'){
            axios({
                method: 'post',
                url: API.OnpermitPending,
                data: formData,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                mode: 'no-cors',
            })
                .then(res => {
                    console.log(res.data.data)
    
                    this.setState({ data: res.data.data })
                    this.setState({ temp_data: res.data.data })
                });
        }else{
        axios({
            method: 'post',
            url: API.onPermitInprocessApi,
            data: formData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            mode: 'no-cors',
        })
            .then(res => {
                console.log(res.data.data)

                this.setState({ data: res.data.data })
                this.setState({ temp_data: res.data.data })
            });
        }
    }
    getData=()=>{
        const userID = localStorage.getItem('token');
        const ID = userID.split('@');
        
        let formData = new FormData();
        formData.append('user_id', ID[0])
        formData.append('user_type', this.props.location.state.user)
        if(this.props.location.state.user==='applicant'){
            axios({
                method: 'post',
                url: API.OnpermitPending,
                data: formData,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                mode: 'no-cors',
            })
                .then(res => {
                    console.log(res.data.data)
    
                    this.setState({ data: res.data.data })
                    this.setState({ temp_data: res.data.data })
                });
        }else{
        axios({
            method: 'post',
            url: API.onPermitInprocessApi,
            data: formData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            mode: 'no-cors',
        })
            .then(res => {
                console.log(res.data.data)

                this.setState({ data: res.data.data })
                this.setState({ temp_data: res.data.data })
            });
        }
    }
    filterByContracter = (e) => {
        if (e.target.value !== '') {
            const data = this.state.data
            const filtered2 = data.filter(com => {
                console.log(e.target.value.toLowerCase())
                return com.contractor_name.toLowerCase().match(e.target.value.toLowerCase());
            });
            this.setState({
                temp_data: filtered2
            })
        } else {
            this.getData();
        }

    }
    startUnit(item) {
        if(this.props.location.state.user==='applicant'){
        this.props.history.push('/inprogressresponse/' +item.program_id,{session_id:item.session_id})
        }else if(this.props.location.state.user==='intermediate'){
            this.props.history.push('/ehsfetch/' +item.program_id,{session_id:item.session_id,type:'assessor'})
        }else{
            this.props.history.push('/assessorfetch/' +item.program_id,{session_id:item.session_id,type:'assessor'})
        }
    }
    changeDate(date) {
        const dateString = date;
        const dateObject = moment(dateString, 'MM/DD/YYYY');

        const formattedDate = dateObject.format('DD-MMM-YYYY');
        return formattedDate
    }
    render() {
        
        return (
            <div className="">
                <Sidenav type={'second'} url={'back'} Push={this.props.history}/>


                <div className="container">
                    <div className='row'>
                <div className='col-md-12' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

<div className='form-group text-center col-6' >
    <label>Search by Contracter Name</label>
    <input type='text' className='form-control' onChange={(e) => this.filterByContracter(e)} />
</div>
</div></div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="trans">
                                {this.state.temp_data.length===0 ?<p>No In Progress Permit</p>
                                :
                                this.state.temp_data.map((item, i) =>
                                <div className="card  row list-group-horizontal align-items-center p-0" style={{ margin: 10,borderLeft:'5px solid #ffa500' }} key={i} onClick={() => this.startUnit(item)}>
                                <div className="col-sm-11 col-xs-11 mb-2">
                                    <p className="mb-2">{item.title}</p>
                                    <div className="flex flex-column mb-2">
                                        <span>#{item.permit_id}</span>
                                        <span>{this.changeDate(item.created_at)} </span>
                                        <span>Contractor DRI : {item.name} {item.lastname}</span>
                                        <span>Contractor Name : {item.contractor_name}</span>
                                        <span>ams OSRAM DRI : {item.assessor_name}</span>
                                        <span>Start Date : {this.changeDate(item.start_time)} </span>
                                                    <span>End Date : {this.changeDate(item.end_time)}</span>
                                                    <span style={{padding:'5px 0',fontWeight:'bold'}}> {item.description}</span>
                                                    {item.description1 &&
                          <span
                            style={{ padding: "5px 0", fontWeight: "bold" }}
                          >
                             {item.description1}
                          </span>
                          }
                                                    {item.status &&
                                                   item.status ==='1' ? <span style={{padding:'5px 0', color:'green',fontWeight:'bold'}}>Status : Contractor Submitted to ams DRI</span>
                                                    :item.status ==='3'? <span style={{padding:'5px 0', color:'green',fontWeight:'bold'}}>Status : Approved by DRI Manager</span> :
                                                    item.status ==='4'? <span style={{padding:'5px 0', color:'green',fontWeight:'bold'}}>Status : EHS Submitted to DRI Manager</span> 
                                                    :item.status ==='9'? <span style={{padding:'5px 0', color:'green',fontWeight:'bold'}}>Status : ams DRI Submitted to EHS</span>
                                                    :item.status ==='2'? <span style={{padding:'5px 0', color:'green',fontWeight:'bold'}}>Status : Permit Rejected by DRI Manager</span>
                                                    :''}
                                    </div>
                                    <div className="flex mb-1">
                                        <div className="col-6 p-0">
                                            <span style={{ paddingRight: 14 }}>
                                                <Icon path={mdiHomeCity}
                                                    title="User Profile"
                                                    size={0.8}
                                                    color={'#be1f24'}
                                                />
                                            </span>
                                            <span>{item.project}</span>
                                        </div>
                                        {/* <div className="col-6 p-0">
                                            <span style={{ paddingRight: 14 }}>
                                                <IosPin size={30} color={'#be1f24'} />
                                            </span>
                                            <span>{item.location}</span>
                                        </div> */}
                        
                                    </div>
                                    {/* <div className="flex">
                                        <div className="col-6 p-0">
                                            <span style={{ paddingRight: 14 }}>
                                                
                                            </span>
                                            <span>{item.permit_date}</span>
                                        </div>
                                        <div className="col-6 p-0">
                                            <span style={{ paddingRight: 14 }}>
                                                
                                            </span>
                                            <span>{item.start_time} {item.end_time}</span>
                                        </div>
                        
                        
                                    </div> */}
                                </div>
                        
                                <div className="col-sm-1 col-xs-1 pad_z text-right"><i className="material-icons">
                                    keyboard_arrow_right
                        </i></div>
                                <div className="flex justify-content-center" style={{ background: '#be1f24', color: '#fff', borderBottomLeftRadius: 5, borderBottomRightRadius: 5 }}>{item.remarks}</div>
                            </div>)}
                            </div></div>
                    </div></div></div>
        )
    }
}
export default InProgressTab;